/* eslint-disable no-unused-vars */
import 'react-datepicker/dist/react-datepicker.css';

import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import addNotification from '../../../components/notification';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../components/panel/panel';

import ROUTES from '../../../config/routes';

import {
  getPermissionTree,
  updatePermissionTree,
} from '../../../app/store/actions/permissions';
import CheckBoxTree from '../../../components/checkboxtree';

class PermissionsManager extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      permissions: [],
      checked: [],
      expanded: [],
      title: '',
    };
  }

  async componentDidMount() {
    await this.getPermissionTree();
  }

  mountIcons = (icon, expanded, checked) => {
    expanded.push(icon.value);
    if (icon.checked) {
      checked.push(icon.value);
    }

    if (icon.icon) {
      icon.icon = (<span style={{ color: '#f79c20' }} className={icon.icon} />);
    } else {
      icon.icon = null;
    }

    if (icon.children) {
      for (let index = 0; index < icon.children.length; index += 1) {
        const iconChild = icon.children[index];

        this.mountIcons(iconChild, expanded, checked);
      }
    }
  };

  getPermissionTree = async () => {
    if (this.props.location.state.accessProfile) {
      const { accessProfile } = this.props.location.state;

      const permissions = await this.props.getPermissionTree(accessProfile.code);

      if (permissions && permissions instanceof Array) {
        const expanded = [];
        const checked = [];
        permissions.forEach((it) => {
          this.mountIcons(it, expanded, checked);
        });
        this.setState({
          permissions,
          title: `Permissões - ${accessProfile.name}`,
          expanded,
          checked,
        });
      }
    }
  };

  getFatherCode = (permissions, code, father) => {
    for (let index = 0; index < permissions.length; index += 1) {
      if (permissions[index].children) {
        for (let indexB = 0; indexB < permissions[index].children.length; indexB += 1) {
          if (permissions[index].children[indexB].value === code) return { code: permissions[index].code };
          if (permissions[index].children[indexB].children) {
            const valueCode = this.getFatherCode(
              permissions[index].children[indexB].children,
              code,
              permissions[index].children[indexB].code,
            );

            if (valueCode) return { code: valueCode.code, isSubChildren: true };
          }
        }
      } else if (permissions[index].code === code && father) return { code: father };
    }

    return 0;
  };

  fillChildren = (checkeds, code) => {
    const fatherObjB = this.getFatherCode(this.state.permissions, code);

    if (fatherObjB && fatherObjB.code && !checkeds.includes(fatherObjB.code)) checkeds.push(fatherObjB.code);
    if (fatherObjB && fatherObjB.isSubChildren) return this.fillChildren(checkeds, fatherObjB.code);
  };

  fillCodes = (checkeds) => {
    for (let index = 0; index < this.state.checked.length; index += 1) {
      const code = this.state.checked[index];

      const fatherObj = this.getFatherCode(this.state.permissions, code);

      if (fatherObj && fatherObj.isSubChildren) this.fillChildren(checkeds, fatherObj.code);
      if (fatherObj && fatherObj.code && !checkeds.includes(fatherObj.code)) checkeds.push(fatherObj.code);

      checkeds.push(code);
    }
  };

  proccessBlockPermissions = (nodeIds, node, isNodeOwner) => {
    const nodeOwner = (isNodeOwner || !node.isChild)
      ? node : _.find(node.parent.children, (item) => (node.value === item.value));
    const hasNodeAndParent = nodeOwner && nodeOwner.children && nodeOwner.children.length;

    if (hasNodeAndParent && nodeOwner.hasBlockPermission) {
      if (node.checked && node.checkState === 2) {
        const nodesToRemove = nodeOwner.children.map((value) => value.value);
        nodesToRemove.push(node.value);

        for (let index = 0; index < nodesToRemove.length; index += 1) {
          const indexElement = nodeIds.indexOf(`${nodesToRemove[index]}`);
          if (indexElement !== -1) {
            nodeIds.splice(indexElement, 1);
          }
        }
      } else if (node.checked) {
        const nodesToRemove = nodeOwner.children.reduce((acum, value) => {
          if (!value.isLabelBlock && !this.state.checked.includes(value.value)) acum.push(value.value);
          return acum;
        }, []);

        for (let index = 0; index < nodesToRemove.length; index += 1) {
          const indexElement = nodeIds.indexOf(`${nodesToRemove[index]}`);
          if (indexElement !== -1) {
            nodeIds.splice(indexElement, 1);
          }
        }
      }
    } else if (hasNodeAndParent) {
      for (let index = 0; index < nodeOwner.children.length; index += 1) {
        const element = nodeOwner.children[index];

        if (element.children && element.children.length) {
          nodeIds = this.proccessBlockPermissions(nodeIds, element, true);
        }
      }
    }

    return nodeIds;
  };

  onCheck = (nodeIds, node) => {
    // Verificacao para nos de bloqueio
    if (node.isParent && [0, 1].includes(node.checkState)) {
      nodeIds = this.proccessBlockPermissions(nodeIds, node);
    }

    this.setState({ checked: nodeIds });
  };

  onSubmitUpdate = async () => {
    if (this.props.location.state.accessProfile) {
      const { accessProfile } = this.props.location.state;

      try {
        const checkeds = [];

        this.fillCodes(checkeds);

        const result = await this.props.updatePermissionTree(accessProfile.code, checkeds);

        if (result && result instanceof Array && result.length) {
          addNotification(
            'success',
            'Atualizar Permissão',
            `Atualização da Permissão ${accessProfile.code} efetuada com sucesso!`,
            'top-right',
          );
          this.props.history.push(ROUTES.DEVELOP_ACCESS_PROFILE_LIST);
        } else {
          addNotification(
            'danger',
            'Atualizar Permissão',
            `Erro ao atualizar a Permissão ${accessProfile.code}!`,
            'top-right',
          );
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
          const { error } = err.response.data;

          if (error.details || error.message) {
            addNotification(
              'danger',
              'Atualizar Permissão',
              `${error.code} - ${error.details || error.message}`,
              'top-right',
            );
          } else {
            addNotification(
              'danger',
              'Atualizar Permissão',
              `Erro ao atualizar a Permissão ${accessProfile.code}!`,
              'top-right',
            );
          }
        } else {
          addNotification(
            'danger',
            'Atualizar Permissão',
            `Erro ao atualizar a Permissão ${accessProfile.code}!`,
            'top-right',
          );
        }
        addNotification(
          'danger',
          'Atualizar Permissão',
          `Erro ao atualizar a Permissão ${accessProfile.code}!`,
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      permissions, checked, expanded,
    } = this.state;

    return (
      <div className={this.props.animationType || 'slideUpTransition'}>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {this.state.title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader noButton />
                <PanelBody>
                  <CheckBoxTree
                    checkModel="all"
                    nodes={permissions}
                    checked={checked}
                    expanded={expanded}
                    onCheck={this.onCheck}
                    onExpand={(value) => this.setState({ expanded: value })}
                  />
                </PanelBody>
                <PanelFooter
                  linkBack={ROUTES.DEVELOP_ACCESS_PROFILE_LIST}
                  showCancel
                  showUpdate
                  onSubmitUpdate={this.onSubmitUpdate}
                />
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getPermissionTree: (code) => dispatch(getPermissionTree(code)),
  updatePermissionTree: (accProfileCode, permissions) => dispatch(updatePermissionTree(accProfileCode, permissions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PermissionsManager));
