/* eslint-disable no-empty */
const keys = {
  APP_AUTH_OBJECT: 'APP_AUTH_OBJECT',
};

const save = (key, value) => {
  try {
    localStorage.setItem(`@${key}`, JSON.stringify(value));
    return true;
  } catch (e) {}
};

const get = (key) => {
  try {
    const value = localStorage.getItem(`@${key}`);
    if (value !== null) {
      if (typeof value !== 'string') return;
      if (value === 'object') return;
      return JSON.parse(value);
    }
  } catch (e) {}
};

const remove = (key) => {
  try {
    localStorage.removeItem(`@${key}`);
  } catch (e) {}
};

export default {
  keys, save, get, remove,
};
