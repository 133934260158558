/* eslint-disable import/prefer-default-export */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { CardTasks } from '../cardTasks';

import styles from './styles.module.scss';

export function ListAllTasks({ listTasks }) {
  return (
    <section className={styles.listTasks}>
      {listTasks.map((singleTask) => (
        <CardTasks
          key={singleTask.id}
          taskName={singleTask.taskName}
          performanceAverage={singleTask.performanceAverage}
          lastRun={singleTask.lastRun}
          runtime={singleTask.runtime}
          runtimePrev={singleTask.runtimePrev}
          currentRun={singleTask.currentRun}
          nextRun={singleTask.nextRun}
          status={singleTask.status}
          messagePrev={singleTask.messagePrev}
          message={singleTask.message}
          messageNext={singleTask.messageNext}
        />
      ))}
    </section>
  );
}
