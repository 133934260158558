/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unused-class-component-methods */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import { addRemoveFilters } from '../../../app/store/actions/monitorFilters';
import addNotification from '../../../components/notification';
import OneClickButton from '../../../components/form/button';
import Table from '../../../components/table/Table';

class ProgramsGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultSorted: [{ id: 'codServidExec', desc: false }],
    };

    this.tableColumns = [{
      Header: 'Código',
      accessor: 'codServidExec',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 80,
      Filter: this.filterColumn,
    }, {
      Header: 'Alias',
      accessor: 'aliasServidExec',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 120,
      Filter: this.filterColumn,
    }, {
      Header: 'Sistema',
      accessor: 'indTipFilaExec',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 70,
      Filter: this.filterColumn,
    }];

    this.tableColumnsAdd = [{
      Header: 'Selecionar',
      accessor: 'action',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 70,
      filterable: false,
      sortable: false,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          <input
            type="checkbox"
            checked={this.props.checkedAdd[row.original.codServidExec] || false}
            onChange={() => this.singleSelection('checkedAdd', row.original)}
          />
        </div>
      ),
    }, ...this.tableColumns];

    this.tableColumnsRemove = [{
      Header: 'Selecionar',
      accessor: 'action',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
      filterable: false,
      sortable: false,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          <input
            type="checkbox"
            checked={this.props.checkedRemove[row.original.codServidExec] || false}
            onChange={() => this.singleSelection('checkedRemove', row.original)}
          />
        </div>
      ),
    }, ...this.tableColumns];
  }

  singleSelection = (variableName, selection) => {
    const checked = this.props[variableName];
    checked[selection.codServidExec] = !checked[selection.codServidExec];
    this.props.setState({ [variableName]: { ...checked } });
  };

  multipleSelection = (variableName, reactTable, selectionOption) => {
    const checked = this.props[variableName];
    const data = this[reactTable].getResolvedState().sortedData;
    if (data && data.length > 0) {
      for (let index = 0; index < data.length; index += 1) {
        const element = data[index]._original;
        if (variableName === 'checkedRemove') {
          checked[element.codServidExec] = selectionOption;
        } else if (variableName === 'checkedAdd') checked[element.codServidExec] = selectionOption;
      }
      this.props.setState({ [variableName]: { ...checked } });
    }
  };

  addRemoveFilters = async (variableName, checkedServersIds) => {
    this.props.setState({ [variableName]: {} });
    try {
      const result = await this.props.addRemoveFilters(
        this.props.monitorFilterId,
        checkedServersIds,
        (variableName === 'checkedAdd'),
      );

      if (result && result.success === false) {
        addNotification(
          'danger',
          'Grupo de RPW',
          'Erro ao gravar os dados',
          'top-right',
        );
      } else {
        addNotification(
          'success',
          'Grupo de RPW',
          'Servidores foram alteradas com sucesso',
          'top-right',
        );
        await this.props.getFilterSelected();
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', 'Grupo de RPW', messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          'Grupo de RPW',
          'Erro ao gravar os dados',
          'top-right',
        );
      }
    }
  };

  validChecked = (variableName) => {
    const checked = this.props[variableName];

    if (!Object.keys(checked).length) {
      addNotification(
        'danger',
        'Grupo de RPW',
        'Nenhum servidor selecionado',
        'top-right',
      );
    } else {
      const checkedServersIds = [];
      for (const index in checked) {
        if (checked[index]) checkedServersIds.push(index);
      }

      if (checkedServersIds.length) {
        this.addRemoveFilters(variableName, checkedServersIds);
      } else {
        addNotification(
          'danger',
          'Grupo de RPW',
          'Nenhum servidor selecionado',
          'top-right',
        );
      }
    }
  };

  render() {
    const { defaultSorted } = this.state;

    const { listServers = [], listServersFilter = [] } = this.props;

    return (
      <div className="row">
        <div className="col-md-6">
          <Table
            expander
            filterable
            data={listServers}
            defaultSorted={defaultSorted}
            columns={this.tableColumnsAdd}
            showPagination={false}
            defaultPageSize={99}
            style={{
              height: '500px',
            }}
            defaultFilterMethod={(filter, row) => {
              const input = _.lowerCase(filter.value);
              const value = _.lowerCase(row[filter.id]);
              if (_.includes(value, input)) {
                return true;
              }
            }}
            ref={(r) => {
              this.reactTableAdd = r && r.reactTable;
            }}
            panelHeaderProps={{
              children: 'Lista de Servidores'.toUpperCase(),
              noButton: true,
            }}
            actionButtons={(
              <div className="ml-auto">
                <button
                  type="button"
                  className="btn btn-120 btn-secondary p-5 m-5"
                  onClick={() => this.multipleSelection('checkedAdd', 'reactTableAdd', true)}
                >
                  Marcar Todos
                </button>
                <button
                  type="button"
                  className="btn btn-120 btn-secondary p-5 m-5"
                  onClick={() => this.multipleSelection('checkedAdd', 'reactTableAdd', false)}
                >
                  Desmarcar Todos
                </button>
                <OneClickButton
                  type="button"
                  className="btn btn-120 btn-primary p-5 m-5"
                  onClick={() => this.validChecked('checkedAdd')}
                >
                  Adicionar
                </OneClickButton>
              </div>
            )}
          />
        </div>
        <div className="col-md-6">
          <Table
            expander
            filterable
            data={listServersFilter}
            defaultSorted={defaultSorted}
            columns={this.tableColumnsRemove}
            showPagination={false}
            defaultPageSize={99}
            style={{
              height: '500px',
            }}
            defaultFilterMethod={(filter, row) => {
              const input = _.lowerCase(filter.value);
              const value = _.lowerCase(row[filter.id]);
              if (_.includes(value, input)) {
                return true;
              }
            }}
            ref={(r) => {
              this.reactTableRemove = r && r.reactTable;
            }}
            panelHeaderProps={{
              children: 'Servidores do Grupo'.toUpperCase(),
              noButton: true,
            }}
            actionButtons={(
              <div className="ml-auto">
                <button
                  type="button"
                  className="btn btn-120 btn-secondary p-5 m-5"
                  onClick={() => this.multipleSelection('checkedRemove', 'reactTableRemove', true)}
                >
                  Marcar Todos
                </button>
                <button
                  type="button"
                  className="btn btn-120 btn-secondary p-5 m-5"
                  onClick={() => this.multipleSelection('checkedRemove', 'reactTableRemove', false)}
                >
                  Desmarcar Todos
                </button>
                <OneClickButton
                  type="button"
                  className="btn btn-120 btn-danger p-5 m-5"
                  onClick={() => this.validChecked('checkedRemove')}
                >
                  Remover
                </OneClickButton>
              </div>
            )}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  addRemoveFilters: (
    monitorFilterId,
    checkedServersIds,
    type,
  ) => dispatch(addRemoveFilters(monitorFilterId, checkedServersIds, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProgramsGroup));
