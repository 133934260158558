import RestService from '../services/rest';

async function authenticate(username, password) {
  return RestService.postRest('auth/login', {
    username,
    password,
    grant_type: 'password',
  });
}

async function refreshToken(token) {
  return RestService.postRest('auth/login', {
    refresh_token: token,
    grant_type: 'refresh_token',
  });
}

async function logout() {
  return RestService.postAuthenticated('auth/revoke', {});
}

async function getUserLogged() {
  return RestService.getAuthenticated('auth/getUser');
}

export default {
  authenticate,
  refreshToken,
  logout,
  getUserLogged,
};
