/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/no-useless-path-segments */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { time } from '../../config/timeSetTimeOut';
import Header from '../../components/headerPanel';
import { FooterPanel } from '../../components/footerPanel';
import { PageSettings } from './../../config/page-settings';
import addNotification from '../../components/notification';
import { getListServersExecQueue } from '../../app/store/actions/serversExec';

import '../../scss/monitor/monitor.module.scss';
import { ListAllTasks } from '../../components/listTasks';

class Tasks extends React.Component {
  // eslint-disable-next-line react/sort-comp
  constructor(props) {
    super(props);
    this.state = {
      listTasks: [],
      title: '',
      systemName: '',
    };
    this.setInterval = null;
  }

  static contextType = PageSettings;

  async componentDidMount() {
    this.context.handleSetPageSidebar(false);
    this.context.handleSetPageHeader(false);
    this.context.handleSetPageContentFullWidth(true);

    const { id } = this.props.match.params;
    if (!this.props.listTasks || !this.props.listTasks.length) {
      await this.getListServersExecQueue();
    }
    this.refreshTask(id, true);
    this.setInterval = setInterval(async () => {
      await this.getListServersExecQueue();
      this.refreshTask(id);
    }, time);
  }

  componentWillUnmount() {
    this.context.handleSetPageSidebar(true);
    this.context.handleSetPageHeader(true);
    this.context.handleSetPageContentFullWidth(false);
    clearInterval(this.setInterval);
  }

  refreshTask = (id, firstRequest = false) => {
    const { listServices } = this.props;
    if (listServices.length) {
      const { tasks, title, systemName } = listServices.find(
        // eslint-disable-next-line eqeqeq
        (element) => element.id == id,
      );
      const allData = { listTasks: tasks };
      if (firstRequest) {
        allData.title = title;
        allData.systemName = systemName;
      }
      this.setState({ ...allData });
    }
  };

  refreshTasks = async () => {
    const { id } = this.props.match.params;
    this.refreshTask(id, true);
  };

  getListServersExecQueue = () => {
    try {
      this.props.getListServersExecQueue();
    } catch (e) {
      addNotification(
        'danger',
        'Erro no Servidor',
        'Não foi possível buscar os dados no servidor',
        'top-right',
      );
    }
  };

  render() {
    const { title, listTasks = [], systemName } = this.state;
    const { listServices, getNumberStatus } = this.props;
    return (
      <>
        {listTasks && (
          <>
            <Header title={title} systemName={systemName} />
            <ListAllTasks listTasks={listTasks} />
            <FooterPanel
              refreshTasks={this.refreshTasks}
              listServices={listServices}
              getNumberStatus={getNumberStatus}
            />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  listServices: state.serversExec.serversExecList,
  getNumberStatus: state.serversExec.serversExecList,
});

const mapDispatchToProps = (dispatch) => ({
  getListServersExecQueue: () => dispatch(getListServersExecQueue()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Tasks));
