import RestService from '../services/rest';

// POST
async function createUserFavorite(programCode) {
  return RestService.postAuthenticated('userFavorites/create', { programCode });
}

async function deleteUserFavorite(userFavoriteId) {
  return RestService.postAuthenticated('userFavorites/delete', { userFavoriteId });
}

export default {
  createUserFavorite,
  deleteUserFavorite,
};
