/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import moment from 'moment/moment';

import { ReactComponent as IconCritical } from '../../assets/images/icons/critical.svg';
import { ReactComponent as IconSuccess } from '../../assets/images/icons/success.svg';
import { ReactComponent as IconWarning } from '../../assets/images/icons/warning.svg';
import { ReactComponent as IconInactive } from '../../assets/images/icons/inactive.svg';

import styles from './styles.module.scss';

export function CardTasks({
  status,
  taskName,
  performanceAverage,
  lastRun,
  runtime,
  runtimePrev,
  currentRun,
  nextRun,
  message,
  messagePrev,
  messageNext
}) {
  return (
    <div
      className={`${styles.card} ${status === 'critical' && styles.critical} ${
        status === 'success' && styles.success
      }
          ${status === 'warning' && styles.warning}
          ${status === 'inactive' && styles.inactive}`}
    >
      <h2>{taskName}</h2>
      <div>
        <p>Média de execução:</p>
        <time>{performanceAverage}</time>
      </div>
      <div>
        <p>Última execução:</p>
        <time>
          {lastRun === '' ? (
            <>Não há</>
          ) : (
            <>{moment(lastRun).format('DD[/]MM | HH:mm')}</>
          )}
        </time>

        <p>{messagePrev}</p>
        <time>{runtimePrev === '' ? <></> : <>{runtimePrev}</>}</time>
      </div>
      <div>
        <p>Atual execução:</p>
        <time>
          {currentRun === '' ? (
            <>Não há</>
          ) : (
            <>{moment(currentRun).format('DD[/]MM | HH:mm')}</>
          )}
        </time>
        <p>{message}</p>
        <time>{runtime === '' ? <></> : <>{runtime}</>}</time>
      </div>
      <div>
        <p>Próxima execução:</p>
        <time>
          {nextRun === '' ? (
            <>Não há</>
          ) : (
            <>{moment(nextRun).format('DD[/]MM [às] HH:mm')}</>
          )}
        </time>
        <p>{messageNext}</p>
      </div>
      {status === 'success' && <IconSuccess className={styles.icon} />}
      {status === 'critical' && <IconCritical className={styles.icon} />}
      {status === 'warning' && <IconWarning className={styles.icon} />}
      {status === 'inactive' && <IconInactive className={styles.icon} />}
    </div>
  );
}
