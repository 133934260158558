function messageType(code) {
  if (code || code === '') {
    switch (code) {
      case '1':
        return 'Sucesso';
      case '2':
        return 'Erro Médio';
      case '3':
        return 'Erro Grave';
      default:
        return code;
    }
  } else if (!code) {
    return [
      { value: '1', label: 'Sucesso' },
      { value: '2', label: 'Erro Médio' },
      { value: '3', label: 'Erro Grave' },
    ];
  }
}

export default messageType;
