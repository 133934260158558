/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-deprecated */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import routes from '../../config/page-route';
import { PageSettings } from '../../config/page-settings';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import AnimatedTransition from './AnimatedTransition';

export const history = createBrowserHistory();

history.listen((location) => {});

function setTitle(path, routeArray) {
  let pageTitle;
  for (let i = 0; i < routeArray.length; i += 1) {
    if (routeArray[i].path === path) {
      pageTitle = `Monitor RPW - ${routeArray[i].title}`;
    }
  }
  document.title = (pageTitle) || 'Monitor RPW';
}

class Content extends React.PureComponent {
  componentWillMount() {
    this.props.history.listen(() => {
      setTitle(this.props.history.location.pathname, routes);
    });
  }

  componentDidMount() {
    setTitle(this.props.history.location.pathname, routes);
  }

  renderRouteBasedAuth = (route, routeProps) => {
    if (route.privated === false) return <PublicRoute route={route} routeProps={routeProps} />;
    return <PrivateRoute route={route} routeProps={routeProps} />;
  };

  render() {
    return (
      <PageSettings.Consumer>
        {({ pageContentFullWidth, pageContentClass, pageContentInverseMode }) => (
          <div style={{ paddingTop: '6rem' }} className={`content ${pageContentFullWidth ? 'content-full-width ' : ''}${pageContentInverseMode ? 'content-inverse-mode ' : ''}${pageContentClass}`}>
            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact || true}
                  history={history}
                  render={(routeProps) => (
                    <AnimatedTransition route={route} routeProps={routeProps}>
                      {this.renderRouteBasedAuth(route, routeProps)}
                    </AnimatedTransition>
                  )}
                />
              ))}
            </Switch>
          </div>
        )}
      </PageSettings.Consumer>
    );
  }
}

export default withRouter(Content);
