import MonitorFilterRequests from '../../server/monitorFilter';
import { addLoading, removeLoading } from './loading';

export const setMonitorFilters = (monitorFilters) => ({
  type: 'SET_MONITOR_FILTERS',
  monitorFilters,
});

export const createMonitorFilter = (monitorFilter) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newMonitorFilter = await MonitorFilterRequests.createMonitorFilter(monitorFilter);
    return newMonitorFilter;
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteMonitorFilter = (monitorFilterId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await MonitorFilterRequests.deleteMonitorFilter(monitorFilterId);
  } finally {
    dispatch(removeLoading());
  }
};

export const createServerFilter = (serverFilter) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newServerFilter = await MonitorFilterRequests.createServerFilter(serverFilter);
    return newServerFilter;
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteServerFilter = (serverFilterId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await MonitorFilterRequests.deleteServerFilter(serverFilterId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getMonitorFilterList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const monitorFilters = await MonitorFilterRequests.getMonitorFilters();
    dispatch(setMonitorFilters(monitorFilters));
  } finally {
    dispatch(removeLoading());
  }
};

export const getMonitorFilter = (filterId) => async (dispatch) => {
  dispatch(addLoading());

  try {
    return await MonitorFilterRequests.getMonitorFilter(filterId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getServersList = () => async (dispatch) => {
  dispatch(addLoading());

  try {
    return await MonitorFilterRequests.getServersList();
  } finally {
    dispatch(removeLoading());
  }
};

export const addRemoveFilters = (monitorFilterId, checkedServersIds, type) => async (dispatch) => {
  dispatch(addLoading());

  try {
    return await MonitorFilterRequests.addRemoveFilters(monitorFilterId, checkedServersIds, type);
  } finally {
    dispatch(removeLoading());
  }
};
