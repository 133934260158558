const INITIAL_STATE = {
  accessProfile: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_DEVELOP_ACCESS_PROFILE_GRID': {
      return { ...state, accessProfile: [...action.payload] };
    }
    default:
      return state;
  }
};
