import _ from 'lodash';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';

import addNotification from '../../../components/notification';
import Table from '../../../components/table/Table';

import ROUTES from '../../../config/routes';

import { deleteAccessProfile, getAllAccessProfiles } from '../../../app/store/actions/accessProfile';

class DevelopPaList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      rowToUpdate: null,
      showModalDelete: false,
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
      {
        id: 'name',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: 'Ações',
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        minWidth: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button
              type="button"
              className="btn btn-default btn-xs"
              style={{
                marginRight: '0.5rem',
                fontSize: '0.9rem',
                fontWeight: '600',
              }}
            >
              Ações
            </button>
            <UncontrolledButtonDropdown>
              <DropdownToggle
                color="default"
                caret
                className="btn-xs"
                style={{ fontSize: '0.9rem' }}
              />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showManager(rows.row)}>Permissões</DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => this.showEdit(rows.row)}>Editar</DropdownItem>
                {/* <DropdownItem onClick={() => this.showDelete(rows.row)}>Eliminar</DropdownItem> */}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: 'Código',
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: 'Nome',
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
      await this.getGrid();
    } else {
      await this.getGrid();
    }
  };

  setInitState = () => {
    this.setState({
      rowToUpdate: null,
      showModalDelete: false,
    });
  };

  getGrid = async () => {
    await this.props.getGrid();
  };

  deleteAccessProfile = async (row) => {
    const result = await this.props.deleteAccessProfile(row._original.code);

    this.setInitState();
    if (result) {
      addNotification(
        'danger',
        'Eliminar Perfil de Acesso',
        `Perfil de Acesso ${row.code} eliminado com sucesso!`,
        'top-right',
      );
    }
    await this.getGrid();
  };

  // showDelete = (row) => {
  //   this.setState({ showModalDelete: true, rowToUpdate: row });
  // };

  showEdit = (row) => {
    this.props.history.push({
      pathname: ROUTES.DEVELOP_ACCESS_PROFILE_EDIT,
      state: {
        accessProfile: row._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showManager = (row) => {
    this.props.history.push({
      pathname: ROUTES.DEVELOP_ACCESS_PROFILE_MANAGER,
      state: {
        accessProfile: row._original,
      },
    });
  };

  render() {
    const { rowToUpdate, showModalDelete, page } = this.state;
    const { accessProfile = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              Início
            </li>
            <li className="breadcrumb-item">Administração</li>
            <li className="breadcrumb-item active">Perfil de Acesso</li>
          </ol>
        </div>
        <Table
          headerTitle="Perfil de Acesso"
          // actionButtons={(
          //   <div className="ml-auto">
          //   <Link to={ROUTES.DEVELOP_ACCESS_PROFILE_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
          //       <i className="fa fa-plus mr-1" />
          //       {' '}
          //       Incluir Perfil de Acesso
          //     </Link>
          //   </div>
          // )}
          panelHeaderProps={{
            onClickReload: this.getGrid,
          }}
          filterable
          data={accessProfile}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) || ((!this.state.firstRun
            && this.props.location.state
            && this.props.location.state.dice
            && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(pg) => ((pg >= 0) ? this.setState({ page: pg }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText="Cancelar"
            confirmBtnText="Confirmar"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title="Você tem certeza?"
            onConfirm={() => this.deleteAccessProfile(rowToUpdate)}
            onCancel={() => this.setInitState()}
          >
            {`O perfil de acesso ${rowToUpdate.code} será eliminado!`}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accessProfile: state.accessProfile && state.accessProfile.accessProfile,
});

const mapDispatchToProps = (dispatch) => ({
  getGrid: () => dispatch(getAllAccessProfiles()),
  deleteAccessProfile: (key) => dispatch(deleteAccessProfile(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DevelopPaList));
