import DownloadsRequests from '../../server/downloads';
import { addLoading, removeLoading } from './loading';

export const setDownloads = (downloads) => ({
  type: 'SET_DOWNLOADS',
  downloads,
});

export const getDownloadsList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const downloads = await DownloadsRequests.getDownloads();
    dispatch(setDownloads(downloads));
  } finally {
    dispatch(removeLoading());
  }
};

export const createDownload = (download) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newDownload = await DownloadsRequests.createDownload(download);
    return newDownload;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateDownload = (download) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DownloadsRequests.updateDownload(download);
  } finally {
    dispatch(removeLoading());
  }
};

export const getDownload = (downloadId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DownloadsRequests.getDownload(downloadId);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteDownload = (downloadId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await DownloadsRequests.deleteDownload(downloadId);
  } finally {
    dispatch(removeLoading());
  }
};
