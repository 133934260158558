import React from 'react';
import { withRouter } from 'react-router-dom';
import { PageSettings } from '../config/page-settings';

class PageBlanc extends React.PureComponent {
  static contextType = PageSettings;

  componentDidMount() {
    this.context.handleSetPageSidebar(false);
    this.context.handleSetPageHeader(false);
    this.context.handleSetBodyWhiteBg(true);
  }

  componentWillUnmount() {
    this.context.handleSetPageSidebar(true);
    this.context.handleSetPageHeader(true);
    this.context.handleSetBodyWhiteBg(false);
  }

  render() {
    return (
      <>
      </>
    );
  }
}

export default withRouter(PageBlanc);
