import UserFavoritesRequests from '../../server/userFavorite';
import { addLoading, removeLoading } from './loading';

export const createUserFavorites = (programCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UserFavoritesRequests.createUserFavorite(programCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteUserFavorite = (userFavoriteId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await UserFavoritesRequests.deleteUserFavorite(userFavoriteId);
  } finally {
    dispatch(removeLoading());
  }
};
