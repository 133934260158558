import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';

import { getAllPermissions } from '../../app/store/actions/app';
import { createUserFavorites, deleteUserFavorite } from '../../app/store/actions/userFavorites';
import addNotification from '../../components/notification';
import Table from '../../components/table/Table';

const createCardFavorite = (showProgramList) => (
  <div className="col-xl-2" style={{ opacity: 0.9 }}>
    <Link
      to="/"
      onClick={() => showProgramList()}
      className="widget-card widget-card-rounded m-b-20 "
    >
      <div
        className="widget-card-cover bg-gradient-orange text-white"
        style={{ height: '150px' }}
      />
      <div
        className="widget-card-content text-center text-white"
        style={{
          display: 'flex', flexDirection: 'column', height: '150px', justifyContent: 'center',
        }}
      >
        <i className="fa fa-plus fa-3x" />
        <h5 className="m-t-10 text-white">
          <b
            style={{
              display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical', overflow: 'hidden',
            }}
          >
            Adicionar Favorito
          </b>
        </h5>
      </div>
    </Link>
  </div>
);

const createCardUser = (route, deleteFavorite) => (
  <div className="col-xl-2" key={`fav-${route.id}`}>
    <Link
      to={route.path}
      className="widget-card widget-card-rounded m-b-20 "
    >
      <div
        className="widget-card-cover bg-gradient-orange"
        style={{ height: '150px' }}
      />
      <div
        className="widget-card-content text-center text-orange bg-white d-flex flew-row"
        style={{
          borderRadius: '4px 4px 0px 0px', justifyContent: 'space-between', padding: 'unset',
        }}
      >
        <div className="flex-fill p-t-15 p-b-15 p-l-15">
          <i className={`${route.icon} fa-3x`} />
        </div>
        <div className="p-5">
          <button
            type="button"
            className="btn btn-default btn-icon btn-circle btn-xs"
            onClick={(e) => deleteFavorite(e, route)}
            style={{ padding: 'unset', color: '#f1f1f1', backgroundColor: '#FF5E00' }}
          >
            <i className="fa fa-times" />
          </button>
        </div>
      </div>
      <div
        className="widget-card-content text-center"
      >
        <h5 className="text-white">
          <b
            style={{
              display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical', overflow: 'hidden',
            }}
          >
            {route.title}
          </b>
        </h5>
      </div>
    </Link>
  </div>
);
class Home extends React.PureComponent {
  constructor(props) {
    super(props);

    this.programListColumns = [
      {
        Header: 'Ações',
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => {
          const program = rows.original;

          if (program.isFavorite) {
            return (
              <div style={{ textAlign: 'center' }}>
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={(e) => this.deleteUserFavorite(e, { id: program.favoriteId })}
                >
                  <i className="fas fa-star text-orange" />
                </button>
              </div>
            );
          }

          return (
            <div style={{ textAlign: 'center' }}>
              <button
                type="button"
                className="btn btn-default"
                onClick={() => this.createUserFavorite(program)}
              >
                <i className="far fa-star" />
              </button>
            </div>
          );
        },
      }, {
        Header: 'Tipo',
        accessor: 'type',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: 'Programa',
        accessor: 'title',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      },
    ];

    this.state = {
      showModalPrograms: false,
    };
  }

  showProgramList = () => {
    this.setState({
      showModalPrograms: true,
    });
  };

  closeProgramList = () => {
    this.setState({
      showModalPrograms: false,
    });
  };

  deleteUserFavorite = async (event, userFavorite) => {
    event.preventDefault();
    try {
      const result = await this.props.deleteUserFavorite(userFavorite.id);

      if (result) {
        await this.props.getAllPermissions();
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            'Favoritos',
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            'Favoritos',
            `Erro ao incluir o Programa Favorito ${userFavorite.title}!`,
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          'Favoritos',
          `Erro ao incluir o Programa Favorito ${userFavorite.title}!`,
          'top-right',
        );
      }
    }
  };

  createUserFavorite = async (program) => {
    const { favoriteList } = this.props;

    try {
      const result = await this.props.createUserFavorites(program.code);

      if (result) {
        await this.props.getAllPermissions();

        if (favoriteList && favoriteList.length > 10) {
          this.setState({
            showModalPrograms: false,
          });
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            'Favoritos',
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            'Favoritos',
            `Erro ao incluir o Programa Favorito ${program.title}!`,
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          'Favoritos',
          `Erro ao incluir o Programa Favorito ${program.title}!`,
          'top-right',
        );
      }
    }
  };

  render() {
    const { userLogged, favoriteList, programList } = this.props;

    const { showModalPrograms } = this.state;

    return (
      <div>
        <h1 className="mb-3 text-white">
          {`Olá, ${userLogged ? userLogged.name : ''}!`}
        </h1>
        <br />
        <h4 className="mb-3 text-white">
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          Sua Área de Trabalho
        </h4>
        <div className="row row-space-30">
          <div className="col-sm-6 col-lg-12 m-t-5">
            <div className="row row-space-30">
              {favoriteList && favoriteList.map((fav, index) => {
                if (index >= 0 && index <= 5) {
                  return createCardUser(fav, this.deleteUserFavorite);
                }
                return null;
              })}
              {(!favoriteList || favoriteList.length < 6) && createCardFavorite(this.showProgramList)}
            </div>
          </div>
          <div className="col-sm-6 col-lg-12 m-t-40">
            <div className="row row-space-30">
              {favoriteList && favoriteList.map((fav, index) => {
                if (index >= 6 && index <= 11) {
                  return createCardUser(fav, this.deleteUserFavorite);
                }
                return null;
              })}
              {(favoriteList && favoriteList.length > 5 && favoriteList.length < 12)
              && createCardFavorite(this.showProgramList)}
            </div>
          </div>
        </div>
        <Modal size="lg" isOpen={showModalPrograms} toggle={() => this.closeProgramList()}>
          <ModalHeader toggle={() => this.closeProgramList()}>Lista de Programas</ModalHeader>
          <ModalBody>
            <Table
              filterable
              data={programList}
              panelHeaderProps={{
                noButton: true,
              }}
              columns={this.programListColumns}
              defaultPageSize={5}
              defaultFilterMethod={(filter, row) => {
                const input = _.lowerCase(filter.value);
                const value = _.lowerCase(row[filter.id]);
                if (_.includes(value, input)) {
                  return true;
                }
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              className="btn btn-120 btn-white p-5 m-5"
              onClick={() => this.closeProgramList()}
            >
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app.userLogged,
  programList: state.app && state.app.programList,
  favoriteList: state.app && state.app.favoriteList,
});

const mapDispatchToProps = (dispatch) => ({
  createUserFavorites: (programCode) => dispatch(createUserFavorites(programCode)),
  deleteUserFavorite: (userFavoriteId) => dispatch(deleteUserFavorite(userFavoriteId)),
  getAllPermissions: () => dispatch(getAllPermissions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
