/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
  Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import { setProgramExecTime } from '../../../app/store/actions/servers';
import {
  createCompany, updateCompany,
} from '../../../app/store/actions/companies';
import OneClickButton from '../../../components/form/button';
import addNotification from '../../../components/notification';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../components/panel/panel';
import ROUTES from '../../../config/routes';

class ProgramForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createdAt: '',
      serverId: '',
      programId: '',
      updatedAt: '',
      codProgDtsul: '',
      desProgDtsul: '',
      tempoMedioExec: '',
      averageExecTimeDays: '',
      averageExecTimeHours: '',
      averageExecTimeMinutes: '',
      averageExecTimeSeconds: '',
      disableIdServidExec: true,
      disableCodProgDtsul: true,
      disableDesProgDtsul: true,
      disableProgramCreatedAt: true,
      disableProgramUpdatedAt: true,
      activeTab: '1',
      mode: 'edit',
      showCreate: false,
      showUpdate: false,
      showCancel: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getCompanySelected();
    }
  }

  getCompanySelected = async () => {
    if (this.props.location.state.information) {
      const { information } = this.props.location.state;

      if (information) {
        this.setState({
          codProgDtsul: information.codProgDtsul,
          createdAt: information.createdAt,
          desProgDtsul: information.desProgDtsul,
          tempoMedioExec: information.tempoMedioExec,
          programId: information.id,
          serverId: information.idServidExec,
          updatedAt: information.updatedAt,
          averageExecTimeDays: information.eachTimeFormat.day > 0 && information.eachTimeFormat.day,
          averageExecTimeHours: information.eachTimeFormat.hour > 0 && information.eachTimeFormat.hour,
          averageExecTimeMinutes: information.eachTimeFormat.minutes > 0 && information.eachTimeFormat.minutes,
          averageExecTimeSeconds: information.eachTimeFormat.seconds > 0 && information.eachTimeFormat.seconds,
        });
      }
    }
  };

  setMode = () => {
    let mode = '';

    if (this.props.isCreate) {
      mode = 'create';
    } else if (this.props.isCopy) {
      mode = 'copy';
    } else if (this.props.isEdit) {
      mode = 'edit';
    } else if (this.props.isDetail) {
      mode = 'detail';
    }

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableIdServidExec: true,
        disableCodProgDtsul: true,
        disableDesProgDtsul: true,
        disableProgramCreatedAt: true,
        disableProgramUpdatedAt: true,
        showUpdate: true,
        showCancel: true,
      });
    }
  };

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={`id${attr}`} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          id={`id${attr}`}
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createNumberInput = (title, max, id, value, attr) => (
    <div className="row flex align-items-center" style={{ flex: 'auto' }}>
      <label className="ml-4 mr-1 h-100">{title}</label>
      <input
        max={max}
        min={0}
        id={`id${id}`}
        type="number"
        className="form-control m-b-5 p-0 m-0"
        style={{ width: '3.2rem' }}
        pattern="[0-9]"
        value={value || ''}
        onChange={(e) => {
          if (attr === 'averageExecTimeHours') {
            this.handleOnChangeHours(
              attr,
              e.target.value,
            );
            return;
          }
          if (attr === 'averageExecTimeMinutes' || attr === 'averageExecTimeSeconds') {
            this.handleOnChangeMinutesNSeconds(
              attr,
              e.target.value,
            );
            return;
          }
          this.setValue(attr, e.target.value);
        }}
        onInput={(e) => e.target.value = e.target.value.slice(0, 2)}
        onKeyPress={(e) => {
          if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
          }
        }}
      />
    </div>
  );

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  handleOnChangeMinutesNSeconds = (attr, value) => {
    if (value <= 59) {
      this.setValue(attr, value);
      return;
    }
    this.setValue(attr, 59);
  };

  handleOnChangeHours = (attr, value) => {
    if (value <= 23) {
      this.setValue(attr, value);
      return;
    }
    this.setValue(attr, 23);
  };

  handleUpdateServerSubmit = async () => {
    const {
      averageExecTimeDays, programId, codProgDtsul,
      averageExecTimeHours, averageExecTimeMinutes, averageExecTimeSeconds,
    } = this.state;

    const tempoMedioExec = {
      averageExecTimeDays,
      averageExecTimeHours,
      averageExecTimeMinutes,
      averageExecTimeSeconds,
    };

    try {
      const updProgram = await this.props.setProgramExecTime(tempoMedioExec, programId);

      if (updProgram) {
        if (updProgram.success && updProgram.success === false) {
          addNotification(
            'danger',
            'Atualizar Programa',
            `Erro ao atualizar o Programa ${codProgDtsul}!`,
            'top-right',
          );
        } else {
          addNotification(
            'success',
            'Atualizar Programa',
            `Atualização do Programa ${codProgDtsul} efetuada com sucesso!`,
            'top-right',
          );

          this.props.history.push({
            pathname: ROUTES.SERVER_CONFIGS,
            state: {
              returnInformation: this.props.location.state.information,
            },
          });
        }
      }
    } catch (err) {
      addNotification(
        'danger',
        'Atualizar Programa',
        `Erro ao atualizar o Programa ${codProgDtsul}!`,
        'top-right',
      );
    }
  };

  render() {
    const {
      createdAt,
      serverId, updatedAt, codProgDtsul,
      desProgDtsul, tempoMedioExec, averageExecTimeDays,
      averageExecTimeHours, averageExecTimeMinutes,
      averageExecTimeSeconds,
    } = this.state;

    const {
      mode, disableIdServidExec, disableCodProgDtsul, disableDesProgDtsul,
      disableProgramCreatedAt, disableProgramUpdatedAt,
      showCreate, showUpdate, showCancel,
    } = this.state;

    let title = '';

    if (mode === 'create' || mode === 'copy') {
      title = 'Novo Programa RPW';
    } else if (mode === 'edit') {
      title = 'Editar Programa RPW';
    } else if (mode === 'detail') {
      title = 'Detalhes Programa RPW';
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              Início
            </li>
            <li className="breadcrumb-item">Cadastros</li>
            <li className="breadcrumb-item">Gerais</li>
            <li className="breadcrumb-item">Servidores RPW</li>
            <li className="breadcrumb-item active">Editar Servidores</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <Panel>
              <PanelHeader noButton />
              <PanelBody>
                <Nav tabs justified fill style={{ backgroundColor: 'lightgray' }}>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => {
                        this.toggleTab('1');
                      }}
                    >
                      <span className="d-sm-block d-none">Programas</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    {this.createInput(
                      serverId,
                      'serverId',
                      'Servidor:',
                      '',
                      'text',
                      true,
                      disableIdServidExec,
                    )}
                    {this.createInput(
                      codProgDtsul,
                      'codProgDtsul',
                      'Código:',
                      '',
                      'text',
                      true,
                      disableCodProgDtsul,
                    )}
                    {this.createInput(
                      desProgDtsul,
                      'desProgDtsul',
                      'Descrição do Programa:',
                      '',
                      'text',
                      true,
                      disableDesProgDtsul,
                    )}
                    <div className="form-group row m-b-15" style={{ height: 'auto' }}>
                      <label
                        htmlFor={`id${tempoMedioExec}`}
                        className="col-form-label col-md-4"
                      >
                        Tempo Médio de Execução:
                      </label>
                      <div className="col-md-5 flex row align-items-center justify-content-center">
                        {this.createNumberInput(
                          'Dias:',
                          9999,
                          averageExecTimeDays,
                          averageExecTimeDays,
                          'averageExecTimeDays',
                        )}
                        {this.createNumberInput(
                          'Horas:',
                          23,
                          averageExecTimeHours,
                          averageExecTimeHours,
                          'averageExecTimeHours',
                        )}
                        {this.createNumberInput(
                          'Minutos:',
                          59,
                          averageExecTimeMinutes,
                          averageExecTimeMinutes,
                          'averageExecTimeMinutes',
                        )}
                        {this.createNumberInput(
                          'Segundos:',
                          59,
                          averageExecTimeSeconds,
                          averageExecTimeSeconds,
                          'averageExecTimeSeconds',
                        )}
                      </div>
                    </div>
                    {this.createInput(
                      createdAt,
                      'createdAt',
                      'Data de Criação:',
                      '',
                      'text',
                      true,
                      disableProgramCreatedAt,
                    )}
                    {this.createInput(
                      updatedAt,
                      'updatedAt',
                      'Data de Atualização:',
                      '',
                      'text',
                      true,
                      disableProgramUpdatedAt,
                    )}
                  </TabPane>
                </TabContent>
              </PanelBody>
              <PanelFooter>
                <Link to={{ pathname: ROUTES.SERVER_CONFIGS, state: { dice: this.props.location.dice } }}>
                  {(showCancel
                    ? (
                      <OneClickButton
                        type="submit"
                        className="btn btn-120 btn-white p-5 m-5"
                      >
                        Cancelar
                      </OneClickButton>
                    )
                    : (
                      <OneClickButton
                        type="submit"
                        className="btn btn-120 btn-white p-5 m-5"
                      >
                        Voltar
                      </OneClickButton>
                    ))}
                </Link>
                {(showCreate
                  && (
                  <OneClickButton
                    type="button"
                    className="btn btn-120 btn-primary p-5 m-5"
                    onClick={() => this.createCompanySubmit()}
                  >
                    Criar
                  </OneClickButton>
                  )
                )}
                {(showUpdate
                  && (
                  <OneClickButton
                    type="button"
                    className="btn btn-120 btn-primary p-5 m-5"
                    onClick={() => this.handleUpdateServerSubmit()}
                  >
                    Atualizar
                  </OneClickButton>
                  )
                )}
              </PanelFooter>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  setProgramExecTime: (time, id) => dispatch(setProgramExecTime(time, id)),
  createCompany: (company) => dispatch(createCompany(company)),
  updateCompany: (company) => dispatch(updateCompany(company)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProgramForm));
