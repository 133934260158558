import companiesOccurences from '../../server/companiesOccurences';
import { addLoading, removeLoading } from './loading';

export const getMessagesList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const getOcurrencesListInfo = await companiesOccurences.getMessagesList();
    return getOcurrencesListInfo;
  } finally {
    dispatch(removeLoading());
  }
};

export const setMessageType = (editRowInfo, selectedMessageTypeCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const returnSetMessage = await companiesOccurences.setMessageType(editRowInfo, selectedMessageTypeCode);
    return returnSetMessage;
  } finally {
    dispatch(removeLoading());
  }
};
