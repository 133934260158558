import RestService from '../services/rest';

// GET
async function getMonitorFilter(filterId) {
  return RestService.getAuthenticated(`monitorFilter/detail?monitorFilterId=${filterId}`);
}
async function getMonitorFilters() {
  return RestService.getAuthenticated('monitorFilter/filter/list');
}

async function getServersList() {
  return RestService.getAuthenticated('monitorFilter/servers/list');
}

// POST
async function createMonitorFilter(monitorFilter) {
  return RestService.postAuthenticated('monitorFilter/filter/create', monitorFilter);
}

async function deleteMonitorFilter(monitorFilterId) {
  return RestService.postAuthenticated('monitorFilter/filter/delete', { monitorFilterId });
}

async function createServerFilter(server) {
  return RestService.postAuthenticated('monitorFilter/server/create', { server });
}

async function deleteServerFilter(serverId) {
  return RestService.postAuthenticated('monitorFilter/server/delete', { serverId });
}

// PUT
async function updateMonitorFilter(monitorFilter) {
  return RestService.putAuthenticated('monitorFilter/filter/update', monitorFilter);
}

async function addRemoveFilters(monitorFilterId, checkedServersIds, type) {
  return RestService.putAuthenticated('monitorFilter/addRemove', { monitorFilterId, checkedServersIds, type });
}

export default {
  getMonitorFilter,
  createMonitorFilter,
  deleteMonitorFilter,
  createServerFilter,
  deleteServerFilter,
  getMonitorFilters,
  updateMonitorFilter,
  getServersList,
  addRemoveFilters,
};
