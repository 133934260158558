/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-access-state-in-setstate */
import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import OneClickButton from '../../../components/form/button';
import addNotification from '../../../components/notification';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../components/panel/panel';
import { getCompaniesOptions } from '../../../app/store/actions/companies';

import UserHelpers from '../../../helpers/users';

import ROUTES from '../../../config/routes';

import {
  createUser, getUser, updateUser,
} from '../../../app/store/actions/users';

import Select from '../../../components/form/select';

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, {
    data, isDisabled, isFocused, isSelected,
  }) => ({
    ...styles,
    backgroundColor: isDisabled ? 'grey' : 'white',
    color: isDisabled ? 'grey' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'default',
    textAlign: 'left',

    ':hover': {
      ...styles[':hover'],
      backgroundColor: isSelected ? 'grey' : '#eb900a',
      color: 'white',
    },
  }),
};

class UserForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);

    this.handleChange = (date) => {
      this.setState({
        validPass: date,
      });
    };

    const date = new Date(moment().add(90, 'days').format('MM/DD/YYYY'));

    this.state = {
      activeTab: '1',
      validPass: date,
      status: 1,
      type: 3,
      mode: 'detail',
      name: '',
      login: '',
      password: '',
      confirmPass: '',
      code: '',
      phone: '',
      branchLine: '',
      cellPhone: '',
      company: '',
      companyName: '',
      disableCompany: true,
      disableLogin: true,
      disableName: true,
      disableType: true,
      disableStatus: true,
      disablePassword: true,
      disableConfirmPass: true,
      disableValidPass: true,
      disablePhone: true,
      disableBranchLine: true,
      disableCellPhone: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      listUserTyp: [],
      listCompany: [],
    };
  }

  async componentDidMount() {
    const { userLogged } = this.props;

    if (!userLogged) {
      this.props.history.push(ROUTES.USER_LIST);
    } else {
      await this.setMode();
      if (this.state.mode !== 'create') {
        await this.getUserSelected();
      }

      const listCompany = await this.props.getCompaniesOptions();

      const listUserTyp = (this.state.mode !== 'detail' && this.props.userLogged && this.props.userLogged.type === 2)
        ? [UserHelpers.listUserType()[1], UserHelpers.listUserType()[2], UserHelpers.listUserType()[3]]
        : UserHelpers.listUserType();

      this.setState({
        listUserTyp,
        listCompany,
      });
    }
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  getUserSelected = async () => {
    if (this.props.location.state.user) {
      const { user } = this.props.location.state;
      const { userLogged } = this.props;

      const userSelected = await this.props.getUser(user.id);

      if (userSelected) {
        const date = new Date(moment(userSelected.passValid).format('MM/DD/YYYY'));

        this.setState({
          userSelected,
          userId: userSelected.id,
          name: userSelected.name,
          login: userSelected.login,
          company: user.companyCode,
          companyName: user.companyName,
          type: (userLogged.type <= 2) ? userSelected.type : userLogged.type,
          status: userSelected.status,
          validPass: date,
          code: userSelected.code,
          phone: userSelected.phone,
          branchLine: userSelected.branchLine,
          cellPhone: userSelected.cellPhone,
        });
      }
    }
  };

  setMode = () => {
    const { userLogged } = this.props;

    let mode = '';

    if (this.props.isCreate) {
      mode = 'create';
    } else if (this.props.isCopy) {
      mode = 'copy';
    } else if (this.props.isEdit) {
      mode = 'edit';
    } else if (this.props.isDetail) {
      mode = 'detail';
    }

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        showCreate: true,
        showCancel: true,
        disableCompany: false,
        disableLogin: false,
        disableName: false,
        disableType: !((userLogged.type <= 2)),
        disableStatus: false,
        disablePassword: false,
        disableConfirmPass: false,
        disableValidPass: false,
        disablePhone: false,
        disableBranchLine: false,
        disableCellPhone: false,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        showUpdate: true,
        showCancel: true,
        disableCompany: true,
        disableLogin: true,
        disableName: false,
        disableType: !((userLogged.type <= 2)),
        disableStatus: true,
        disablePassword: true,
        disableConfirmPass: true,
        disableValidPass: true,
        disablePhone: false,
        disableBranchLine: false,
        disableCellPhone: false,
      });
    }
  };

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={`id-${attr}`} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          id={`id-${attr}`}
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createSelect = (value, attr, label, items) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={`id-${attr}`} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <select
          id={`id-${attr}`}
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
        >
          {items.map((item) => (
            <option key={item.value} value={item.value}>{item.label}</option>
          ))}
        </select>
      </div>
    </div>
  );

  createDateInput = (value, label) => {
    const customDateStyle = ({ className, children }) => (
      <div>
        <div className="bg-primary rounded p-2">
          <h5 className="text-light text-center pt-2">
            Monitor
            <span className="text-dark">RPW</span>
          </h5>
        </div>
        <div className={className}>
          <div>{children}</div>
        </div>
      </div>
    );

    return (
      <div className="form-group row m-b-15" style={{ height: 40 }}>
        <label className="col-form-label col-md-4">{label}</label>
        <div className="col-md-0">
          <DatePicker
            selected={value}
            className="form-control"
            dateFormat="dd/MM/yyyy"
            value={value || ''}
            onChange={(e) => this.handleChange(e)}
            calendarContainer={customDateStyle}
            customInput={(
              <InputMask
                className="form-control"
                type="text"
                mask="99/99/9999"
              />
            )}
          />
        </div>
      </div>
    );
  };

  createSelectDropDown = (value, attr, label, items) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <Select
          value={value || ''}
          onChange={(e) => this.setValueDrop(attr, e)}
          options={items}
          isClearable
          styles={colourStyles}
          placeholder="Selecione..."
        />
      </div>
    </div>
  );

  createInputMask = (value, attr, label, placeholder, mask, type = 'text', required) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <InputMask
          className="form-control"
          placeholder={placeholder}
          type={type}
          mask={mask}
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          required={required}
        />
      </div>
    </div>
  );

  setValue = (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  setValueDrop = async (attr, value) => {
    this.setState({
      [`${attr}`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
  };

  createUserSubmit = async () => {
    const {
      name, login, type, status, password, confirmPass, validPass, phone, branchLine, cellPhone, company,
    } = this.state;

    if (password === confirmPass) {
      try {
        const newUser = await this.props.createUser({
          companyCode: type === 1 ? '*' : company,
          name,
          login,
          type,
          status,
          password,
          passValid: validPass,
          phone: phone ? phone.replace(/\D/g, '') : '',
          branchLine,
          cellPhone: cellPhone ? cellPhone.replace(/\D/g, '') : '',
        });

        if (newUser) {
          if (newUser.success === false) {
            addNotification(
              'danger',
              'Novo Usuário',
              `Erro ao incluir o usuário ${login} !`,
              'top-right',
            );
          } else {
            addNotification(
              'success',
              'Novo Usuário',
              `Novo Usuário ${login} incluído com sucesso!`,
              'top-right',
            );

            this.props.history.push(ROUTES.USER_LIST);
          }
        } else {
          addNotification(
            'danger',
            'Novo Usuário',
            `Erro ao incluir o usuário ${login} !`,
            'top-right',
          );
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
          const { error } = err.response.data;

          if (error.details || error.message) {
            addNotification(
              'danger',
              'Novo Usuário',
              `${error.code} - ${error.details || error.message}`,
              'top-right',
            );
          } else {
            addNotification(
              'danger',
              'Novo Usuário',
              `Erro ao incluir o usuário ${login} !`,
              'top-right',
            );
          }
        } else {
          addNotification(
            'danger',
            'Novo Usuário',
            `Erro ao incluir o usuário ${login} !`,
            'top-right',
          );
        }
      }
    } else {
      addNotification(
        'danger',
        'Novo Usuário',
        'Verificar as senhas informadas !',
        'top-right',
      );
    }
  };

  updateUserSubmit = async () => {
    const {
      name, userId, login, type, code, phone, branchLine, cellPhone,
    } = this.state;

    try {
      const updUser = await this.props.updateUser({
        name,
        userId,
        type,
        code,
        phone: phone ? phone.replace(/\D/g, '') : '',
        branchLine,
        cellPhone: cellPhone ? cellPhone.replace(/\D/g, '') : '',
      });

      if (updUser) {
        if (updUser.success && updUser.success === false) {
          addNotification(
            'danger',
            'Atualizar usuário',
            `Erro ao atualizar o usuário ${login}`,
            'top-right',
          );
        } else {
          addNotification(
            'success',
            'Atualizar usuário',
            `Atualização do usuário ${login} efetuada com sucesso!`,
            'top-right',
          );

          this.props.history.push(ROUTES.USER_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            'Atualizar usuário',
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            'Atualizar usuário',
            `Erro ao atualizar o usuário ${login} !`,
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          'Atualizar usuário',
          `Erro ao atualizar o usuário ${login} !`,
          'top-right',
        );
      }
    }
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { listUserTyp } = this.state;

    const { userLogged } = this.props;

    const {
      name, login, type, status, password, confirmPass, validPass,
      phone, branchLine, cellPhone, company, companyName, listCompany,
    } = this.state;

    const {
      mode, showCreate, showUpdate, showCancel, disableLogin, disableName, disableType, disableStatus,
      disablePassword, disableConfirmPass, disableValidPass, disablePhone, disableBranchLine,
      disableCellPhone, disableCompany,
    } = this.state;

    let title = '';

    if (mode === 'create' || mode === 'copy') {
      title = 'Novo Usuário';
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              Início
            </li>
            <li className="breadcrumb-item">Administração</li>
            <li className="breadcrumb-item">Usuários</li>
            <li className="breadcrumb-item active">Usuários</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form onSubmit={(e) => e.preventDefault()}>
              <Panel className="panel-with-tabs">
                <PanelHeader noButton />
                <PanelBody>
                  <div className="row">
                    <div className="col-xl-12">
                      {type > 1 && userLogged.type === 1
                        && (disableCompany
                          ? this.createInput(
                            company,
                            'company',
                            'Empresa:',
                            '',
                            'text',
                            false,
                            disableCompany,
                          )
                          : this.createSelectDropDown(
                            { value: company, label: companyName },
                            'company',
                            'Empresa:',
                            listCompany,
                          )
                        )}
                      {this.createInput(
                        login,
                        'login',
                        'Login:',
                        'Informe o email para login (obrigatório)',
                        'text',
                        true,
                        disableLogin,
                      )}
                      {this.createInput(
                        name,
                        'name',
                        'Nome:',
                        'Informe o nome completo (obrigatório)',
                        'text',
                        true,
                        disableName,
                      )}
                      {(disableType
                        ? this.createInput(
                          UserHelpers.userType(type),
                          'type',
                          'Tipo:',
                          '',
                          'text',
                          false,
                          disableType,
                        )
                        : this.createSelect(type, 'type', 'Tipo:', listUserTyp))}
                      {(!disableStatus
                        && this.createSelect(
                          status,
                          'status',
                          'Status:',
                          UserHelpers.listUserStatus(),
                        ))}
                      {(!disablePassword
                        && this.createInputMask(
                          password,
                          'password',
                          'Senha:',
                          'Informe a senha (obrigatório)',
                          '',
                          'password',
                          true,
                        ))}
                      {(!disableConfirmPass
                        && this.createInputMask(
                          confirmPass,
                          'confirmPass',
                          'Confirme a senha:',
                          'Confirme a senha (obrigatório)',
                          '',
                          'password',
                          true,
                        ))}
                      {(!disableValidPass
                        && this.createDateInput(
                          validPass,
                          'Validade Senha:',
                        )
                      )}
                      {(disablePhone
                        ? this.createInput(
                          phone,
                          'phone',
                          'Telefone:',
                          '',
                          'text',
                          false,
                          disablePhone,
                        )
                        : this.createInputMask(
                          phone,
                          'phone',
                          'Telefone:',
                          'Informe o telefone',
                          phone && phone.length < 11 ? '(99) 9999-9999' : '(99) 99999-9999',
                          'tel',
                          false,
                        )
                      )}
                      {(disableBranchLine
                        ? this.createInput(
                          branchLine,
                          'branchLine',
                          'Ramal:',
                          'Informe o ramal',
                          'text',
                          false,
                          disableBranchLine,
                        )
                        : this.createInputMask(
                          branchLine,
                          'branchLine',
                          'Ramal:',
                          'Informe o ramal',
                          '9999',
                          'tel',
                          false,
                        )
                      )}
                      {(disableCellPhone
                        ? this.createInput(
                          cellPhone,
                          'cellPhone',
                          'Celular:',
                          '',
                          'text',
                          false,
                          disableCellPhone,
                        )
                        : this.createInputMask(
                          cellPhone,
                          'cellPhone',
                          'Celular:',
                          'Informe o celular',
                          cellPhone && cellPhone.length < 13 ? '+99 (99) 9999-9999' : '+99 (99) 99999-9999',
                          'tel',
                          false,
                        )
                      )}
                    </div>
                  </div>
                </PanelBody>
                <PanelFooter>
                  <Link to={{ pathname: ROUTES.USER_LIST, state: { dice: this.props.location.dice } }}>
                    {(showCancel
                      ? (
                        <OneClickButton
                          type="submit"
                          className="btn btn-120 btn-white p-5 m-5"
                        >
                          Cancelar
                        </OneClickButton>
                      )
                      : (
                        <OneClickButton
                          type="submit"
                          className="btn btn-120 btn-white p-5 m-5"
                        >
                          Voltar
                        </OneClickButton>
                      )
                    )}
                  </Link>
                  {(showCreate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createUserSubmit()}
                    >
                      Criar
                    </OneClickButton>
                    )
                  )}
                  {(showUpdate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateUserSubmit()}
                    >
                      Atualizar
                    </OneClickButton>
                    )
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app && state.app.userLogged,
});

const mapDispatchToProps = (dispatch) => ({
  getUser: (userId) => dispatch(getUser(userId)),
  createUser: (user) => dispatch(createUser(user)),
  updateUser: (user) => dispatch(updateUser(user)),
  getCompaniesOptions: () => dispatch(getCompaniesOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserForm));
