/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import addNotification from '../../../components/notification';
import { SimpleForm } from '../../../components/panel/panel';

import ROUTES from '../../../config/routes';

import {
  createAccessProfile, getAccessProfile, updateAccessProfile,
} from '../../../app/store/actions/accessProfile';

class DevelopAccessProfileForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'detail',
      code: '',
      name: '',
      disableCode: true,
      disableName: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode === 'create') {
      // do something
    } else {
      await this.getAccessProfileSelected();
    }
  }

  getAccessProfileSelected = async () => {
    if (this.props.location.state.accessProfile) {
      const { accessProfile } = this.props.location.state;

      const accessProfileSelected = await this.props.getAccessProfile(accessProfile.code);

      if (accessProfileSelected) {
        this.setState({
          accessProfileSelected,
          code: accessProfileSelected.code,
          name: accessProfileSelected.name,
        });
      }
    }
  };

  setMode = () => {
    let mode = '';

    if (this.props.isCreate) {
      mode = 'create';
    } else if (this.props.isCopy) {
      mode = 'copy';
    } else if (this.props.isEdit) {
      mode = 'edit';
    } else if (this.props.isDetail) {
      mode = 'detail';
    }

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableName: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableName: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  };

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div key={attr} className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={`id-${attr}`} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          id={`id-${attr}`}
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  createAccessProfileSubmit = async () => {
    const {
      code, name,
    } = this.state;

    try {
      const newAccessProfile = await this.props.createAccessProfile({
        code, name,
      });

      if (newAccessProfile && typeof newAccessProfile !== 'object') {
        addNotification(
          'success',
          'Novo Perfil de Acesso',
          `Novo Perfil ${code} incluído com sucesso!`,
          'top-right',
        );
        this.props.history.push(ROUTES.DEVELOP_ACCESS_PROFILE_LIST);
      } else {
        addNotification(
          'danger',
          'Novo Perfil de Acesso',
          `Erro ao incluir o Perfil de Acesso ${code}!`,
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            'Novo Perfil de Acesso',
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            'Novo Perfil de Acesso',
            `Erro ao incluir o Perfil de Acesso ${code}!`,
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          'Novo Perfil de Acesso',
          `Erro ao incluir o Perfil de Acesso ${code}!`,
          'top-right',
        );
      }
    }
  };

  updateAccessProfile = async () => {
    const {
      code, name,
    } = this.state;

    try {
      const updAccessProfile = await this.props.updateAccessProfile({
        code, name,
      });

      if (updAccessProfile && updAccessProfile instanceof Array && updAccessProfile.length) {
        addNotification(
          'success',
          'Atualizar Perfil de Acesso',
          `Atualização do Perfil de Acesso ${code} efetuado com sucesso!`,
          'top-right',
        );
        this.props.history.push(ROUTES.DEVELOP_ACCESS_PROFILE_LIST);
      } else {
        addNotification(
          'danger',
          'Atualizar Perfil de Acesso',
          `Erro ao atualizar o Perfil de Acesso ${code}!`,
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            'Atualizar Perfil de Acesso',
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            'Atualizar Perfil de Acesso',
            `Erro ao atualizar o Perfil de Acesso ${code}!`,
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          'Atualizar Perfil de Acesso',
          `Erro ao atualizar o Perfil de Acesso ${code}!`,
          'top-right',
        );
      }
      addNotification(
        'danger',
        'Atualizar Perfil de Acesso',
        `Erro ao atualizar o Perfil de Acesso ${code}!`,
        'top-right',
      );
    }
  };

  render() {
    const {
      mode, code, name, disableCode, disableName,
      showCreate, showUpdate, showCancel,
    } = this.state;

    let title = '';

    if (mode === 'create' || mode === 'copy') {
      title = 'Novo Perfil de Acesso';
    } else if (mode === 'edit') {
      title = 'Editar Perfil de Acesso';
    } else if (mode === 'detail') {
      title = 'Detalhes Perfil de Acesso';
    }

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              Início
            </li>
            <li className="breadcrumb-item">Administração</li>
            <li className="breadcrumb-item">Usuários</li>
            <li className="breadcrumb-item active">Perfil de Acesso</li>
          </ol>
        </div>
        <SimpleForm
          title={title}
          noButton
          linkBack={{ pathname: ROUTES.DEVELOP_ACCESS_PROFILE_LIST, state: { dice: this.props.location.dice } }}
          showCancel={showCancel}
          showCreate={showCreate}
          onSubmitCreate={() => this.createAccessProfileSubmit()}
          showUpdate={showUpdate}
          onSubmitUpdate={() => this.updateAccessProfile()}
          inputs={[
            this.createInput(
              code,
              'code',
              'Código:',
              'Informe o perfil de acesso (obrigatório)',
              'text',
              true,
              disableCode,
            ),
            this.createInput(
              name,
              'name',
              'Nome:',
              'Informe o nome do perfil de acesso (obrigatório)',
              'text',
              true,
              disableName,
            ),
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getAccessProfile: (code) => dispatch(getAccessProfile(code)),
  createAccessProfile: (accessProfile) => dispatch(createAccessProfile(accessProfile)),
  updateAccessProfile: (accessProfile) => dispatch(updateAccessProfile(accessProfile)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DevelopAccessProfileForm));
