import React from 'react';
import Select, { createFilter } from 'react-select';
import { FixedSizeList as List } from 'react-window';

const height = 35;

class MenuList extends React.PureComponent {
  render() {
    const {
      options, children, maxHeight, getValue,
    } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <Select
    {...props}
    components={{ MenuList }}
    ref={ref}
    noOptionsMessage="Sem opções"
    menuPortalTarget={document.body}
    filterOption={createFilter({ ignoreAccents: false })}
    styles={{
      ...(props.styles || {}),
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    }}
  />
));
