/* eslint-disable class-methods-use-this */
/* eslint-disable jsx-a11y/label-has-associated-control */
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledButtonDropdown,
} from 'reactstrap';

import OneClickButton from '../../../components/form/button';
import addNotification from '../../../components/notification';
import Table from '../../../components/table/Table';

import UserHelpers from '../../../helpers/users';

import ROUTES from '../../../config/routes';

import {
  deleteUser, getUsersList, updateUserPassword, updateUserStatus, updateUserValid,
} from '../../../app/store/actions/users';

class UserList extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = (date) => {
      this.setState({
        newValid: date,
      });
    };

    this.state = {
      showAlertActivate: false,
      showAlertInactivate: false,
      showModalNewPass: false,
      showModalNewValid: false,
      showModalDelete: false,
      newPass: '',
      newPassConfirm: '',
      newValid: '',
      userUpdate: null,
    };

    this.defaultSorted = [
      {
        id: 'login',
        desc: false,
      },
    ];
    this.tableColumns = [
      {
        Header: 'Ações',
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        minWidth: 100,
        Cell: (rows) => {
          const { status } = rows.row;
          return (
            <div style={{ textAlign: 'center' }}>
              <button
                type="button"
                className="btn btn-default btn-xs"
                style={{
                  marginRight: '0.5rem',
                  fontSize: '0.9rem',
                  fontWeight: '600',
                }}
              >
                Ações
              </button>
              <UncontrolledButtonDropdown>
                <DropdownToggle
                  color="default"
                  caret
                  className="btn-xs"
                  style={{ fontSize: '0.9rem' }}
                />
                <DropdownMenu>
                  {((this.props.userLogged && this.props.userLogged.type <= 2)
                    || (this.props.userLogged && this.props.userLogged.type > 2
                      && this.props.userLogged.id === rows.original.id)) && (
                      <DropdownItem onClick={() => this.showUserDetail(rows.row)}>Detalhes</DropdownItem>
                  )}
                  {(this.props.userLogged.type <= 2 && this.validation(rows.original.type)) && (
                    <>
                      <DropdownItem onClick={() => this.showEditUser(rows.row)}>Editar</DropdownItem>
                      <DropdownItem onClick={() => this.showCopyUser(rows.row)}>Copiar</DropdownItem>
                      {/* {(rows.original.type !== 4 && this.props.userLogged.type === 1) && (
                        <DropdownItem onClick={() => this.showDeleteUser(rows.row)}>Eliminar</DropdownItem>
                      )} */}
                      <DropdownItem divider />
                      {status ? (
                        this.props.userLogged.id !== rows.original.id && (
                          <DropdownItem onClick={() => this.showAlert(rows.row, 'inactivate')}>Bloquear</DropdownItem>
                        )
                      ) : (
                        <DropdownItem onClick={() => this.showAlert(rows.row, 'activate')}>Ativar</DropdownItem>
                      )}
                      <DropdownItem onClick={() => this.showModal(rows.row, 'pass')}>Alterar Senha</DropdownItem>
                      <DropdownItem onClick={() => this.showModal(rows.row, 'valid')}>Alterar validade</DropdownItem>
                    </>
                  )}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          );
        },
      }, {
        Header: 'Login',
        accessor: 'login',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: 'Nome',
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: this.filterColumn,
      }, {
        Header: 'Tipo',
        accessor: 'type',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          const type = UserHelpers.userType(id);

          if (_.includes(_.lowerCase(type), input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {UserHelpers.userType(row.value)}
          </span>
        ),
        Filter: this.filterColumn,
      }, {
        Header: 'Empresa',
        accessor: 'company',
        style: { alignSelf: 'center', textAlign: 'center' },
        show: this.props.userLogged.type === 1,
        minWidth: 230,
        Cell: (row) => (
          <span>
            {row.value ? row.value.companyName : '*'}
          </span>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const value = _.lowerCase(row._original.company
            ? row._original.company.companyName
            : '');
          if (_.includes(value, input)) {
            return true;
          }
        },
      }, {
        Header: 'Validade Senha',
        accessor: 'passValid',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {moment(row.value).format('DD/MM/YYYY')}
          </span>
        ),
        Filter: this.filterColumn,
      }, {
        Header: 'Status',
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes(_.lowerCase(UserHelpers.userStatus(false)), input)) {
            return id === false;
          }
          if (_.includes(_.lowerCase(UserHelpers.userStatus(true)), input)) {
            return id === true;
          }
        },
        Cell: (row) => (
          <span>
            <i
              className="fa fa-circle f-s-7 fa-fw m-r-10 m-t-5"
              style={{
                color: row.value === false ? '#ff5b57'
                  : '#00acac',
                transition: 'all .3s ease',
              }}
            />
            {' '}
            {UserHelpers.userStatus(row.value)}
          </span>
        ),
        Filter: this.filterColumn,
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  validation = (type) => {
    if (type !== 1) {
      return true;
    } if (this.props.userLogged.type === 1) {
      return true;
    }
    return false;
  };

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
      await this.getUsers();
    } else {
      await this.getUsers();
    }
  };

  setInitState = () => {
    this.setState({
      showAlertActivate: false,
      showAlertInactivate: false,
      showModalNewPass: false,
      showModalNewValid: false,
      showModalDelete: false,
      newPass: '',
      newPassConfirm: '',
      newValid: '',
      userUpdate: null,
    });
  };

  filterColumn = ({ filter, onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      value={(filter && filter.value) || ''}
      onChange={(e) => onChange(e.target.value)}
      autoComplete="nope"
    />
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  getUsers = async () => {
    await this.props.getUsersList();
  };

  updatePassword = async (user, newPass, newPassConfirm) => {
    if (newPass !== newPassConfirm) {
      addNotification(
        'danger',
        'Senha',
        'Verificar as senhas informadas !',
        'top-right',
      );
    } else {
      const result = await this.props.updateUserPassword(user._original, newPass);
      this.setInitState();
      if (result) {
        addNotification(
          'success',
          'Senha Usuário',
          `Senha do usuário ${user.login} alterada com sucesso !`,
          'top-right',
        );
      }
    }
  };

  updateValidate = async (user, newValid) => {
    const result = await this.props.updateUserValid(user._original, newValid);
    this.setInitState();
    if (result) {
      addNotification(
        'success',
        'Validade Usuário',
        `Validade da senha do usuário ${user.login} alterada com sucesso !`,
        'top-right',
      );
    }

    await this.getUsers();
  };

  updateStatus = async (user, type) => {
    const result = await this.props.updateUserStatus(user._original);

    if (type === 'inactivate') {
      this.setInitState();
      if (result) {
        addNotification(
          'danger',
          'Status Usuário',
          `Usuário ${user.login} bloqueado com sucesso !`,
          'top-right',
        );
      }
    } else if (type === 'activate') {
      this.setInitState();
      if (result) {
        addNotification(
          'success',
          'Status Usuário',
          `Usuário ${user.login} ativado com sucesso !`,
          'top-right',
        );
      }
    }

    await this.getUsers();
  };

  deleteUser = async (user) => {
    const result = await this.props.deleteUser(user._original.id);

    this.setInitState();
    if (result) {
      addNotification(
        'danger',
        'Eliminar Usuário',
        `Usuário ${user.login} eliminado com sucesso !`,
        'top-right',
      );
    }
    await this.getUsers();
  };

  showUserDetail = (user) => {
    this.props.history.push({
      pathname: ROUTES.USER_DETAIL,
      state: {
        user: user._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditUser = (user) => {
    this.props.history.push({
      pathname: ROUTES.USER_EDIT,
      state: {
        user: user._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyUser = (user) => {
    this.props.history.push({
      pathname: ROUTES.USER_COPY,
      state: {
        user: user._original,
      },
    });
  };

  // showDeleteUser = (user) => {
  //   this.setState({ showModalDelete: true, userUpdate: user });
  // };

  showAlert = (user, type) => {
    if (type === 'inactivate') {
      this.setState({ showAlertInactivate: true, userUpdate: user });
    } else if (type === 'activate') {
      this.setState({ showAlertActivate: true, userUpdate: user });
    }
  };

  showModal = (user, type) => {
    if (type === 'pass') {
      this.setState({ showModalNewPass: true, userUpdate: user });
    } else if (type === 'valid') {
      this.setState({ showModalNewValid: true, userUpdate: user, newValid: new Date(moment(user.passValid)) });
    }
  };

  createDateInput = (value, label) => {
    const customDateStyle = ({ className, children }) => (
      <div>
        <div className="bg-primary rounded p-2">
          <h5 className="text-light text-center pt-2">
            Monitor
            <span className="text-dark">RPW</span>
          </h5>
        </div>
        <div className={className}>
          <div>{children}</div>
        </div>
      </div>
    );

    return (
      <div className="form-group row m-b-15" style={{ height: 40 }}>
        <label className="col-form-label col-md-4">{label}</label>
        <div className="col-md-0">
          <DatePicker
            selected={value}
            className="form-control"
            dateFormat="dd/MM/yyyy"
            value={value || ''}
            onChange={(e) => this.handleChange(e)}
            calendarContainer={customDateStyle}
            customInput={(
              <InputMask
                className="form-control"
                type="text"
                mask="99/99/9999"
              />
            )}
          />
        </div>
      </div>
    );
  };

  createInputMask = (value, attr, label, placeholder, mask, type = 'text', required) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <InputMask
          className="form-control"
          placeholder={placeholder}
          type={type}
          mask={mask}
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          required={required}
        />
      </div>
    </div>
  );

  render() {
    const { usersList = [] } = this.props;

    const {
      userUpdate, showAlertActivate, showAlertInactivate,
      showModalNewPass, newPass, newPassConfirm,
      showModalNewValid, newValid, showModalDelete, page,
    } = this.state;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              Início
            </li>
            <li className="breadcrumb-item">Administração</li>
            <li className="breadcrumb-item active">Usuários</li>
          </ol>
        </div>
        <Table
          downloadCSV
          wikiHelp={ROUTES.USER_HELP}
          headerTitle="Usuários"
          actionButtons={(
            (this.props.userLogged && this.props.userLogged.type) <= 2 && (
              <div className="ml-auto">
                <Link to={ROUTES.USER_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3 text-white">
                  <i className="fa fa-plus mr-1" />
                  {' '}
                  Incluir Usuário
                </Link>
              </div>
            )
          )}
          panelHeaderProps={{
            onClickReload: this.getUsers,
          }}
          filterable
          data={usersList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) || ((!this.state.firstRun
            && this.props.location.state && this.props.location.state.dice
            && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(pg) => ((pg >= 0) ? this.setState({ page: pg }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalNewPass
          && (
          <Modal isOpen={showModalNewPass} toggle={() => this.setInitState()}>
            <ModalHeader toggle={() => this.setInitState()}>Alterar Senha</ModalHeader>
            <ModalBody>
              {this.createInputMask(
                newPass,
                'newPass',
                'Senha :',
                'Informe a senha (obrigatório)',
                '',
                'password',
                true,
              )}
              {this.createInputMask(
                newPassConfirm,
                'newPassConfirm',
                'Confirme a senha:',
                'Confirme a senha (obrigatório)',
                '',
                'password',
                true,
              )}
            </ModalBody>
            <ModalFooter>
              <OneClickButton
                className="btn btn-120 btn-white p-5 m-5"
                onClick={() => this.setInitState()}
              >
                Cancelar
              </OneClickButton>
              <OneClickButton
                className="btn btn-120 btn-primary p-5 m-5"
                onClick={() => this.updatePassword(userUpdate, newPass, newPassConfirm)}
              >
                Salvar
              </OneClickButton>
            </ModalFooter>
          </Modal>
          )
        )}
        {(showModalNewValid
          && (
          <Modal isOpen={showModalNewValid} toggle={() => this.setInitState()}>
            <ModalHeader toggle={() => this.setInitState()}>Alterar validade</ModalHeader>
            <ModalBody>
              {this.createDateInput(newValid, 'Validade Senha:')}
            </ModalBody>
            <ModalFooter>
              <OneClickButton
                className="btn btn-120 btn-white p-5 m-5"
                onClick={() => this.setInitState()}
              >
                Cancelar
              </OneClickButton>
              <OneClickButton
                className="btn btn-120 btn-primary p-5 m-5"
                onClick={() => this.updateValidate(userUpdate, newValid)}
              >
                Salvar
              </OneClickButton>
            </ModalFooter>
          </Modal>
          )
        )}
        {(showAlertActivate
          && (
          <SweetAlert
            success
            showCancel
            cancelBtnText="Cancelar"
            confirmBtnText="Confirmar"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="default"
            title="Você tem certeza?"
            onConfirm={() => this.updateStatus(userUpdate, 'activate')}
            onCancel={() => this.setInitState()}
          >
            {`O usuário ${userUpdate.login} será ativado !`}
          </SweetAlert>
          )
        )}

        {(showAlertInactivate
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText="Cancelar"
            confirmBtnText="Confirmar"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title="Você tem certeza?"
            onConfirm={() => this.updateStatus(userUpdate, 'inactivate')}
            onCancel={() => this.setInitState()}
          >
            {`O usuário ${userUpdate.login} será bloqueado !`}
          </SweetAlert>
          )
        )}

        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText="Cancelar"
            confirmBtnText="Confirmar"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title="Você tem certeza?"
            onConfirm={() => this.deleteUser(userUpdate)}
            onCancel={() => this.setInitState()}
          >
            {`O usuário ${userUpdate.login} será eliminado !`}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUsersList: () => dispatch(getUsersList()),
  updateUserStatus: (user) => dispatch(updateUserStatus(user)),
  updateUserPassword: (user, newPass) => dispatch(updateUserPassword(user, newPass)),
  updateUserValid: (user, newValid) => dispatch(updateUserValid(user, newValid)),
  deleteUser: (userId) => dispatch(deleteUser(userId)),
});

const mapStateToProps = (state) => ({
  usersList: state.users && state.users.usersList,
  userLogged: state.app && state.app.userLogged,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserList));
