/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable class-methods-use-this */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import AuthService from '../../app/services/auth';
import ROUTES from '../../config/routes';

import Permissions from '../../app/services/permissions';
import { getUserLogged } from '../../app/store/actions/app';
import PageBlanc from '../../pages/PageBlanc';
import PageNotFound from '../../pages/PageNotFound';

class PrivateRoute extends React.PureComponent {
  renderPageNotFound = (route) =>
    // Array contendo condicoes falsas para renderizar a pagina nao encontrada
    // como exemplo nao possuir a permissao de rota
    [
      Permissions.checkRoutePermission(route.path),
    ].includes(false);

  render() {
    const { route, routeProps, userLogged } = this.props;
    if (AuthService.isAuthenticated()) {
      if (!userLogged) {
        this.props.getUserLogged();
      }

      if (userLogged && userLogged.type === 3) {
        if (route.path === ROUTES.HOME) {
          return (
            <Redirect
              to={{
                pathname: ROUTES.MONITOR,
                state: { from: this.props.location },
              }}
            />
          );
        }
      }

      if (this.renderPageNotFound(route)) return <PageNotFound />;

      if (!userLogged) return <PageBlanc />;

      return <route.component {...routeProps} />;
    }
    return (
      <Redirect
        to={{
          pathname: ROUTES.LOGIN,
          state: { from: this.props.location },
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app.userLogged,
});

const mapActionsToProps = (dispatch) => ({
  getUserLogged: () => dispatch(getUserLogged()),
});

export default connect(mapStateToProps, mapActionsToProps)(PrivateRoute);
