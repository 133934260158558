import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
  Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import {
  createCompany, updateCompany
} from '../../../app/store/actions/companies';
import { setServerAlias } from '../../../app/store/actions/servers';
import OneClickButton from '../../../components/form/button';
import addNotification from '../../../components/notification';
import {
  Panel, PanelBody, PanelFooter, PanelHeader
} from '../../../components/panel/panel';
import ROUTES from '../../../config/routes';

class ServerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      codServidExec: '',
      aliasServidExec: '',
      companyCode: '',
      createdAt: '',
      desServidExec: '',
      serverId: '',
      indTipFilaExec: '',
      updatedAt: '',
      disableCodServidExec: true,
      disableAliasServidExec: false,
      disableCompanyCode: true,
      disableCreatedAt: true,
      disableDesServidExec: true,
      disableId: true,
      disableIndTipFilaExec: true,
      activeTab: '1',
      mode: 'edit',
      showUpdate: false,
      showCancel: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getServerSelected();
    }
  }

  getServerSelected = async () => {
    if (this.props.location.state.information) {
      const { information } = this.props.location.state;

      if (information) {
        this.setState({
          codServidExec: information.codServidExec,
          aliasServidExec: information.aliasServidExec,
          companyCode: information.companyCode,
          createdAt: information.createdAt,
          desServidExec: information.desServidExec,
          serverId: information.id,
          indTipFilaExec: information.indTipFilaExec,
          updatedAt: information.updatedAt,
        });
      }
    }
  };

  setMode = () => {
    let mode = '';

    if (this.props.isCreate) {
      mode = 'create';
    } else if (this.props.isCopy) {
      mode = 'copy';
    } else if (this.props.isEdit) {
      mode = 'edit';
    } else if (this.props.isDetail) {
      mode = 'detail';
    }

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableAliasServidExec: false,
        disableCodServidExec: true,
        disableCompanyCode: true,
        disableCreatedAt: true,
        disableDesServidExec: true,
        disableId: true,
        disableIndTipFilaExec: true,
        showUpdatedAt: true,
        showUpdate: true,
        showCancel: true,
      });
    }
  };

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled, maxLength) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={`id${attr}`} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          id={`id${attr}`}
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          maxLength={maxLength || ''}
        />
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  handleUpdateServerSubmit = async () => {
    const { aliasServidExec, serverId } = this.state;

    try {
      const updCompany = await this.props.setServerAlias(aliasServidExec, serverId);

      if (updCompany) {
        if (updCompany.success && updCompany.success === false) {
          addNotification(
            'danger',
            'Atualizar Servidor',
            `Erro ao atualizar o Servidor ${aliasServidExec}!`,
            'top-right',
          );
        } else {
          addNotification(
            'success',
            'Atualizar Servidor',
            `Atualização do Servidor ${aliasServidExec} efetuada com sucesso!`,
            'top-right',
          );

          this.props.history.push(ROUTES.SERVER_CONFIGS);
        }
      }
    } catch (err) {
      addNotification(
        'danger',
        'Atualizar Servidor',
        `Erro ao atualizar o Servidor ${aliasServidExec}!`,
        'top-right',
      );
    }
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const {
      codServidExec, aliasServidExec,
      companyCode, createdAt, desServidExec,
      serverId, indTipFilaExec, updatedAt,
    } = this.state;

    const {
      mode, disableCodServidExec, disableAliasServidExec,
      disableCompanyCode, disableCreatedAt,
      disableDesServidExec, disableId, disableIndTipFilaExec,
      showUpdatedAt, showUpdate, showCancel,
    } = this.state;

    let title = '';

    if (mode === 'create' || mode === 'copy') {
      title = 'Novo Servidor RPW';
    } else if (mode === 'edit') {
      title = 'Editar Servidor RPW';
    } else if (mode === 'detail') {
      title = 'Detalhes Servidor RPW';
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              Início
            </li>
            <li className="breadcrumb-item">Cadastros</li>
            <li className="breadcrumb-item">Gerais</li>
            <li className="breadcrumb-item">Servidores RPW</li>
            <li className="breadcrumb-item active">Editar Servidores</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <Panel>
              <PanelHeader noButton />
              <PanelBody>
                <Nav tabs justified fill style={{ backgroundColor: 'lightgray' }}>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => {
                        this.toggleTab('1');
                      }}
                    >
                      <span className="d-sm-block d-none">Servidores</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    {this.createInput(
                      serverId,
                      'id',
                      'Id do Servidor:',
                      '',
                      'text',
                      true,
                      disableId,
                    )}
                    {this.createInput(
                      companyCode,
                      'companyCode',
                      'Empresa:',
                      '',
                      'text',
                      true,
                      disableCompanyCode,
                    )}
                    {this.createInput(
                      codServidExec,
                      'codServidExec',
                      'Código do Servidor:',
                      '',
                      'text',
                      true,
                      disableCodServidExec,
                    )}
                    {this.createInput(
                      aliasServidExec,
                      'aliasServidExec',
                      'Nome do Servidor:',
                      'Informe o nome do servidor',
                      'text',
                      true,
                      disableAliasServidExec,
                      '20',
                    )}
                    {this.createInput(
                      desServidExec,
                      'desServidExec',
                      'Descrição do Servidor:',
                      '',
                      'text',
                      true,
                      disableDesServidExec,
                    )}
                    {this.createInput(
                      indTipFilaExec,
                      'indTipFilaExec',
                      'Sistema Operacional:',
                      '',
                      'text',
                      true,
                      disableIndTipFilaExec,
                    )}
                    {this.createInput(
                      moment(createdAt).format('DD/MM/yyyy, HH:mm:ss'),
                      'createdAt',
                      'Data de Criação:',
                      '',
                      'text',
                      true,
                      disableCreatedAt,
                    )}
                    {this.createInput(
                      moment(updatedAt).format('DD/MM/yyyy, HH:mm:ss'),
                      'updatedAt',
                      'Data de Atualização:',
                      '',
                      'text',
                      true,
                      showUpdatedAt,
                    )}
                  </TabPane>
                </TabContent>
              </PanelBody>
              <PanelFooter>
                <Link to={{ pathname: ROUTES.SERVER_CONFIGS, state: { dice: this.props.location.dice } }}>
                  {(showCancel
                    ? (
                      <OneClickButton
                        type="submit"
                        className="btn btn-120 btn-white p-5 m-5"
                      >
                        Cancelar
                      </OneClickButton>
                    )
                    : (
                      <OneClickButton
                        type="submit"
                        className="btn btn-120 btn-white p-5 m-5"
                      >
                        Voltar
                      </OneClickButton>
                    ))}
                </Link>
                {(showUpdate
                  && (
                  <OneClickButton
                    type="button"
                    className="btn btn-120 btn-primary p-5 m-5"
                    onClick={() => this.handleUpdateServerSubmit()}
                  >
                    Atualizar
                  </OneClickButton>
                  )
                )}
              </PanelFooter>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  setServerAlias: (alias, serverId) => dispatch(setServerAlias(alias, serverId)),
  createCompany: (company) => dispatch(createCompany(company)),
  updateCompany: (company) => dispatch(updateCompany(company)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ServerForm));
