import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { scrollToTopAnimated } from '../utils';

import './AnimatedTransitions.css';

class AnimatedTransition extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      anim: false,
    };
  }

  componentDidMount() {
    const { route, routeProps } = this.props;
    if (routeProps.match.path === route.path) this.setState({ anim: true });
    scrollToTopAnimated();
  }

  render() {
    const { children, route } = this.props;
    return (
      <CSSTransition
        in={this.state.anim}
        classNames={(route && route.animEffect) ? route.animEffect.type : 'page'}
        mountOnEnter
        unmountOnExit
        timeout={(route && route.animEffect) ? route.animEffect.timeout : 800}
      >
        {children}
      </CSSTransition>
    );
  }
}

export default AnimatedTransition;
