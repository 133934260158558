function userType(type) {
  switch (type) {
    case 1:
      return 'Master';
    case 2:
      return 'Administrador';
    case 3:
      return 'Monitor';
    case 4:
      return 'Integração';
    default:
      return type;
  }
}

function listUserType() {
  return [
    { value: 1, label: 'Master' },
    { value: 2, label: 'Administrador' },
    { value: 3, label: 'Monitor' },
    { value: 4, label: 'Integração' },
  ];
}

function userStatus(status) {
  switch (status) {
    case false:
      return 'Bloqueado';
    case true:
      return 'Ativo';
    default:
      return status;
  }
}

function listUserStatus() {
  return [
    { value: false, label: 'Bloqueado' },
    { value: true, label: 'Ativo' },
  ];
}

export default {
  userType,
  listUserType,
  userStatus,
  listUserStatus,
};
