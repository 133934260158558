import RestService from '../services/rest';

async function getMessagesList() {
  return RestService.getAuthenticated(
    'occurences/list/getCompaniesMessagesOccurences',
  );
}

async function setMessageType(editRowInfo, selectedMessageTypeCode) {
  return RestService.postAuthenticated(
    'occurences/setMessageType',
    { editRowInfo, selectedMessageTypeCode },
  );
}

export default {
  getMessagesList,
  setMessageType,
};
