const INITIAL_STATE = {
  serversInformation: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_RPW_SERVERS': {
      return { ...state, serversInformation: action.serversInformationList };
    }
    case 'SET_SELECTED_SERVER_PROGRAMS': {
      return { ...state, selectedServerPrograms: action.programsInfo };
    }
    default:
      return state;
  }
};
