/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { PageSettings } from '../../config/page-settings';

class SidebarProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileActive: 0,
    };
    this.handleProfileExpand = this.handleProfileExpand.bind(this);
  }

  handleProfileExpand(e) {
    e.preventDefault();
    this.setState((state) => ({
      profileActive: !state.profileActive,
    }));
  }

  render() {
    const { userLogged } = this.props;

    return (
      <PageSettings.Consumer>
        {({ pageSidebarMinify }) => (
          <ul className="nav">
            <li className="nav-profile">
              <div className="cover with-shadow" />
              <div className="image image-icon bg-black text-grey-darker">
                <i className="fa fa-user" />
              </div>
              <div className="info">
                {(userLogged && userLogged.name) || ''}
                <small>
                  {(userLogged && userLogged.mainBranch && `${userLogged.mainBranch} - ${userLogged.mainBranchName}`) || ''}
                </small>
                <small className="mt-1">
                  {(userLogged && userLogged.accessProfile && `${userLogged.accessProfile}`) || ''}
                </small>
              </div>
            </li>
          </ul>
        )}
      </PageSettings.Consumer>
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app.userLogged,
});

export default connect(mapStateToProps, null)(SidebarProfile);
