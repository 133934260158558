import {
  createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import thunk from 'redux-thunk';

import appReducer from './reducers/app';
import loadingReducer from './reducers/loading';
import usersReducer from './reducers/users';
import companiesReducer from './reducers/companies';
import accessProfileReducer from './reducers/accessProfile';
import jobsReducer from './reducers/jobs';
import serversExecReducer from './reducers/serversExec';
import serversInformationReducer from './reducers/servers';
import monitorFilterReducer from './reducers/monitorFilters';
import downloadReducer from './reducers/downloads';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  const store = createStore(
    combineReducers({
      app: appReducer,
      loading: loadingReducer,
      users: usersReducer,
      companies: companiesReducer,
      accessProfile: accessProfileReducer,
      jobs: jobsReducer,
      serversExec: serversExecReducer,
      servers: serversInformationReducer,
      monitorFilter: monitorFilterReducer,
      download: downloadReducer,
    }),
    composeEnhancers(applyMiddleware(thunk)),
  );

  return store;
};
