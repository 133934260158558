import RestService from '../services/rest';

// GET
async function getFilterServersExecQueue() {
  return RestService.getAuthenticated('monitorFilter/filter/listWithServers');
}

export default {
  getFilterServersExecQueue,
};
