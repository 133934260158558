/* eslint-disable no-empty */
import serversExecRequests from '../../server/serversExec';

export const setServersExec = (serversExec) => ({
  type: 'SET_SERVERSEXEC',
  serversExec,
});

export const setUsingServer = (serverUse) => ({
  type: 'validServerUse',
  serverUse,
});

export const getListServersExecQueue = (companyCode) => async (dispatch) => {
  dispatch(setUsingServer(true));
  const serversExec = await serversExecRequests.getListServersExecQueue(companyCode);
  dispatch(setServersExec(serversExec));
  dispatch(setUsingServer(false));
};
