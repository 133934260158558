/* eslint-disable no-empty */
import AuthRequests from '../../server/auth';
import PermissionsServer from '../../server/permissions';
import AuthService from '../../services/auth';

export const setAuth = (auth) => ({
  type: 'SET_APP_AUTH',
  auth,
});

export const setUser = (user) => ({
  type: 'SET_USER_lOGGED',
  user,
});

export const setUserMenus = (menus) => ({
  type: 'SET_USER_MENUS',
  menus,
});

export const setUserRoutes = (routes) => ({
  type: 'SET_USER_ROUTES',
  routes,
});

export const setUserProgramList = (programList) => ({
  type: 'SET_USER_PROGRAM_LIST',
  programList,
});

export const setUserFavoriteList = (favoriteList) => ({
  type: 'SET_USER_FAVORITE_LIST',
  favoriteList,
});

export const setUserPermissions = (permissions) => ({
  type: 'SET_USER_PERMISSIONS',
  permissions,
});

export const setUserPermissionsCompany = (permissionsCompany) => ({
  type: 'SET_USER_PERMISSIONS_COMPANY',
  permissionsCompany,
});

export const setCronLocation = (cronLocation) => ({
  type: 'SET_USER_CRON_LOCATION',
  cronLocation,
});

export const setAppLocale = (locale) => ({
  type: 'SET_USER_LOCALE',
  locale,
});

export const setAppTimezone = (timezone) => ({
  type: 'SET_USER_TIMEZONE',
  timezone,
});

export const getAllPermissions = () => async (dispatch) => {
  try {
    const permissions = await PermissionsServer.getAll();
    if (permissions.menus) await dispatch(setUserMenus(permissions.menus));
    if (permissions.permissions) await dispatch(setUserPermissions(permissions.permissions));
    if (permissions.permissionsCompany) await dispatch(setUserPermissionsCompany(permissions.permissionsCompany));
    if (permissions.routes) await dispatch(setUserRoutes(permissions.routes));
    if (permissions.programList) await dispatch(setUserProgramList(permissions.programList));
    if (permissions.favoriteList) await dispatch(setUserFavoriteList(permissions.favoriteList));
  } catch (e) {}
};

export const authenticate = (username, password) => async (dispatch) => {
  try {
    const auth = await AuthRequests.authenticate(username, password);
    AuthService.create(auth);
    await dispatch(setAuth(auth));
    await dispatch(getAllPermissions());
  } finally {}
};

export const refreshToken = (token) => async (dispatch) => {
  try {
    const auth = await AuthRequests.refreshToken(token);
    AuthService.create(auth);
    dispatch(setAuth(auth));
  } finally {}
};

export const getUserLogged = () => async (dispatch) => {
  try {
    const user = await AuthRequests.getUserLogged();
    if (user) {
      dispatch(setUser(user));
    }
  } finally {}
};

export const logout = () => async (dispatch) => {
  try {
    await AuthRequests.logout();
    AuthService.reset();
    dispatch(setAuth({}));
  } finally {}
};
