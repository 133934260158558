/* eslint-disable max-len */
import React from 'react';
import Home from '../pages/home/form';

import CompanyForm from '../pages/admin/company/form';
import CompanyList from '../pages/admin/company/list';
import JobsForm from '../pages/admin/jobs/form';
import JobsList from '../pages/admin/jobs/list';
import UserForm from '../pages/admin/user/form';
import UserList from '../pages/admin/user/list';
import DevelopAccessProfileForm from '../pages/admin/accessProfile/form';
import DevelopAccessProfileList from '../pages/admin/accessProfile/list';
import DevelopAccessProfileManager from '../pages/admin/accessProfile/manager';
import ServerConfiguration from '../pages/registration/servers/serverConfiguration';
import ServerForm from '../pages/registration/servers/form';
import ProgramForm from '../pages/registration/servers/programsForm';
import CompanyMessages from '../pages/registration/company/companyMessages';
import IntegrationMonitorList from '../pages/integration/integrationMonitor/list';
import Login from '../pages/login/login';
import PageNotFound from '../pages/PageNotFound';
import Monitor from '../pages/monitor';
import Tasks from '../pages/tasks';
import MonitorFilterList from '../pages/registration/monitorFilter/list';
import MonitorFilterForm from '../pages/registration/monitorFilter/form';
import DownloadPage from '../pages/download/form';

import ROUTES from './routes';

const routes = [
  {
    path: ROUTES.LOGIN,
    title: 'Entrar',
    component: (props) => <Login {...props} />,
    privated: false,
  },
  {
    path: ROUTES.HOME,
    title: 'Início',
    component: (props) => <Home {...props} />,
  },
  {
    path: ROUTES.USER_COPY,
    title: 'Usuário',
    component: (props) => <UserForm isCopy {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  },
  {
    path: ROUTES.USER_DETAIL,
    title: 'Usuário',
    component: (props) => <UserForm isDetail {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  },
  {
    path: ROUTES.USER_EDIT,
    title: 'Usuário',
    component: (props) => <UserForm isEdit {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  },
  {
    path: ROUTES.USER_CREATE,
    title: 'Usuário',
    component: (props) => <UserForm isCreate {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  },
  {
    path: ROUTES.USER_LIST,
    title: 'Usuário',
    component: (props) => <UserList {...props} />,
  },
  {
    path: ROUTES.COMPANY_COPY,
    title: 'Empresa',
    component: (props) => <CompanyForm isCopy {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  },
  {
    path: ROUTES.COMPANY_DETAIL,
    title: 'Empresa',
    component: (props) => <CompanyForm isDetail {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  },
  {
    path: ROUTES.COMPANY_EDIT,
    title: 'Empresa',
    component: (props) => <CompanyForm isEdit {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  },
  {
    path: ROUTES.COMPANY_CREATE,
    title: 'Empresa',
    component: (props) => <CompanyForm isCreate {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  },
  {
    path: ROUTES.COMPANY_LIST,
    title: 'Empresa',
    component: (props) => <CompanyList {...props} />,
  },
  {
    path: ROUTES.JOBS_LIST,
    title: 'Jobs',
    component: (props) => <JobsList {...props} />,
  },
  {
    path: ROUTES.JOBS_EDIT,
    title: 'Jobs',
    component: (props) => <JobsForm isEdit {...props} />,
  },
  {
    path: ROUTES.DEVELOP_ACCESS_PROFILE_LIST,
    title: 'Perfil de Acesso',
    component: (props) => <DevelopAccessProfileList {...props} />,
  },
  {
    path: ROUTES.DEVELOP_ACCESS_PROFILE_CREATE,
    title: 'Perfil de Acesso',
    component: (props) => <DevelopAccessProfileForm isCreate {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  },
  {
    path: ROUTES.DEVELOP_ACCESS_PROFILE_EDIT,
    title: 'Perfil de Acesso',
    component: (props) => <DevelopAccessProfileForm isEdit {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  },
  {
    path: ROUTES.DEVELOP_ACCESS_PROFILE_MANAGER,
    title: 'Perfil de Acesso',
    component: (props) => <DevelopAccessProfileManager {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  },
  {
    path: ROUTES.SERVER_CONFIGS,
    title: 'Servidores',
    component: (props) => <ServerConfiguration {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  },
  {
    path: ROUTES.SERVER_EDIT,
    title: 'Servidores RPW',
    component: (props) => <ServerForm isEdit {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  },
  {
    path: ROUTES.SERVER_PROGRAM_EDIT,
    title: 'Programas RPW',
    component: (props) => <ProgramForm isEdit {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.OCCURRENCES,
    title: 'Empresas',
    component: (props) => <CompanyMessages {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.INTEGRATION_MONITOR,
    title: 'Monitor de Integração',
    component: (props) => <IntegrationMonitorList {...props} />,
  }, {
    path: ROUTES.MONITOR_FILTER_LIST,
    title: 'Grupos de RPW',
    component: (props) => <MonitorFilterList {...props} />,
  }, {
    path: ROUTES.MONITOR_FILTER_CREATE,
    component: (props) => <MonitorFilterForm isCreate {...props} />,
    title: 'Grupos de RPW',
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.MONITOR_FILTER_EDIT,
    component: (props) => <MonitorFilterForm isEdit {...props} />,
    title: 'Grupos de RPW',
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.MONITOR,
    title: 'Monitor RPW',
    component: (props) => <Monitor {...props} />,
  }, {
    path: '/monitor/robot/:id',
    title: 'Tarefas',
    component: (props) => <Tasks {...props} />,
  }, {
    path: ROUTES.DOWNLOAD,
    title: 'Downloads',
    component: (props) => <DownloadPage {...props} />,
  }, {
    path: '*',
    title: 'Página não encontrada...',
    component: (props) => <PageNotFound {...props} />,
    privated: false,
  },
];

export default routes;
