import RestService from '../services/rest';

async function getServersInformation() {
  return RestService.getAuthenticated('serversInformation/all');
}

async function getProgramsOfSelectedServer(selectedServerId) {
  return RestService.getAuthenticated('serversInformation/selectedServerPrograms', { selectedServerId });
}

async function setServerAlias(alias, id) {
  return RestService.postAuthenticated(
    'serversInformation/setServerAlias',
    { alias, id },
  );
}

async function setProgramExecTime(time, id) {
  return RestService.postAuthenticated(
    'serversInformation/setProgramExecTime',
    { time, id },
  );
}

async function deleteProgram(programId) {
  return RestService.postAuthenticated('serversInformation/deleteProgram', { programId });
}

export default {
  getServersInformation,
  getProgramsOfSelectedServer,
  setServerAlias,
  setProgramExecTime,
  deleteProgram,
};
