/* eslint-disable no-empty */
import { store } from '../../index';

const encodeUniqKey = (key = '') => (key ? Buffer.from(key).toString('base64') : '');

const checkPermission = (uniqKey) => {
  try {
    const { app } = store.getState();
    const { permissions } = app;

    return !permissions.includes(`${encodeUniqKey(uniqKey)}`);
  } catch (e) {}

  return false;
};

const checkRoutePermission = (path) => {
  try {
    const { app } = store.getState();
    const { routes } = app;

    return routes.includes(path);
  } catch (e) {}

  return false;
};

export default {
  checkPermission,
  checkRoutePermission,
};
