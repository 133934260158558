import classnames from 'classnames';
import React from 'react';
import JSONPretty from 'react-json-pretty';
import {
  Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';

export default class modalIntegrationInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
    };
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { activeTab } = this.state;
    const {
      openInformationModal, dataSend, responseData, closeModal,
    } = this.props;

    return (
      <Modal size="lg" isOpen={openInformationModal} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>Dados da Integração</ModalHeader>
        <ModalBody>
          <Nav tabs justified fill style={{ backgroundColor: 'lightgray' }}>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => this.toggleTab('1')}
              >
                <span className="d-sm-block d-none">
                  Enviado
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => this.toggleTab('2')}
              >
                <span className="d-sm-block d-none">
                  Retorno
                </span>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <h5>
                <JSONPretty id="json-pretty" data={dataSend} />
              </h5>
            </TabPane>
            <TabPane tabId="2">
              <h5>
                <JSONPretty id="json-pretty" data={responseData} />
              </h5>
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-120 btn-secondary p-5 m-5"
            onClick={closeModal}
          >
            Fechar
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}
