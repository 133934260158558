import React from 'react';
import { connect } from 'react-redux';

/* export const Loading = ({ isLoading }) => (
  isLoading && (
    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
      <div
        className="ajax-overlay justify-content-center"
      >
        <i style={{ fontSize: '40px', color: 'white' }} className="fas fa-spinner fa-pulse" />
      </div>
    </div>
  )
); */

export function Loading({ isLoading }) {
  return (
    <div className={`overflow-main${isLoading ? '' : ' hide-overlay'}`}>
      <div className="boxes">
        <div className="box">
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className="box">
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className="box">
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className="box">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: (state.loading > 0),
});

export default connect(mapStateToProps)(Loading);
