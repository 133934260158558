import RestService from '../services/rest';

// GET
async function getListServersExecQueue(companyCode) {
  const query = companyCode ? `?companyCode=${companyCode}` : '';
  return RestService.getAuthenticated(`pedExec/list/monitor${query}`);
}

export default {
  getListServersExecQueue,
};
