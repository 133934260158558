/* eslint-disable no-unused-vars */
import React from 'react';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import {
  createCompany, getCompany, updateCompany,
} from '../../../app/store/actions/companies';
import OneClickButton from '../../../components/form/button';
import addNotification from '../../../components/notification';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../components/panel/panel';
import ROUTES from '../../../config/routes';

class CompanyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'detail',
      code: '',
      corporateName: '',
      companyName: '',
      email: '',
      phone: '',
      cnpj: '',
      stateRegistration: '',
      note: '',
      postalCode: '',
      ibge: '',
      street: '',
      number: '',
      complement: '',
      district: '',
      city: '',
      state: '',
      country: '',
      userERP: '',
      passwordERP: '',
      urlERP: '',
      disableCode: true,
      disableCorporateName: true,
      disableCompanyName: true,
      disableEmail: true,
      disablePhone: true,
      disableCNPJ: true,
      disableStateRegistration: true,
      disableNote: true,
      disablePostalCode: true,
      disableStreet: true,
      disableNumber: true,
      disableComplement: true,
      disableDistrict: true,
      disableCity: true,
      disableState: true,
      disableCountry: true,
      disableUserERP: true,
      disablePasswordERP: true,
      disableUrlERP: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getCompanySelected();
    }
  }

  getCompanySelected = async () => {
    if (this.props.location.state.company) {
      const { company } = this.props.location.state;

      const companySelected = await this.props.getCompany(company.id);

      if (companySelected) {
        this.setState({
          companyId: companySelected.id,
          code: companySelected.code,
          corporateName: companySelected.corporateName,
          companyName: companySelected.companyName,
          email: companySelected.email,
          phone: companySelected.phone,
          cnpj: companySelected.cnpj,
          stateRegistration: companySelected.stateRegistration,
          note: companySelected.note,
          postalCode: companySelected.postalCode,
          ibge: companySelected.ibge,
          street: companySelected.street,
          number: companySelected.number,
          complement: companySelected.complement,
          district: companySelected.district,
          city: companySelected.city,
          state: companySelected.state,
          country: companySelected.country,
          userERP: companySelected.userERP,
          passwordERP: companySelected.passwordERP,
          urlERP: companySelected.urlERP,
        });
      }
    }
  };

  setMode = () => {
    let mode = '';

    if (this.props.isCreate) {
      mode = 'create';
    } else if (this.props.isCopy) {
      mode = 'copy';
    } else if (this.props.isEdit) {
      mode = 'edit';
    } else if (this.props.isDetail) {
      mode = 'detail';
    }

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableCorporateName: false,
        disableCompanyName: false,
        disableEmail: false,
        disablePhone: false,
        disableCNPJ: false,
        disableStateRegistration: false,
        disableNote: false,
        disablePostalCode: false,
        disableStreet: false,
        disableNumber: false,
        disableComplement: false,
        disableDistrict: false,
        disableCity: false,
        disableState: false,
        disableCountry: false,
        disableUserERP: false,
        disablePasswordERP: false,
        disableUrlERP: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
      });
    } else if (mode === 'edit') {
      const { userLogged } = this.props;
      const master = !!(userLogged && userLogged.type === 1);

      this.setState({
        mode,
        disableCode: true,
        disableCorporateName: !master,
        disableCompanyName: !master,
        disableCNPJ: !master,
        disableStateRegistration: !master,
        disableNote: false,
        disableEmail: false,
        disablePhone: false,
        disablePostalCode: false,
        disableStreet: false,
        disableNumber: false,
        disableComplement: false,
        disableDistrict: false,
        disableCity: false,
        disableState: false,
        disableCountry: false,
        disableUserERP: false,
        disablePasswordERP: false,
        disableUrlERP: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  };

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={`id${attr}`} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          id={`id${attr}`}
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createInputMask = (value, attr, label, placeholder, mask, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={`id-${attr}`} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <InputMask
          id={`id-${attr}`}
          className="form-control"
          placeholder={placeholder}
          type={type}
          mask={mask}
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  createCompanySubmit = async () => {
    const {
      code, corporateName, companyName, email, phone, cnpj,
      stateRegistration, note, postalCode, ibge, street,
      number, complement, district, city, state, country, userERP,
      passwordERP,
      urlERP,
    } = this.state;

    try {
      const newCompany = await this.props.createCompany({
        code,
        corporateName,
        companyName,
        email,
        phone: phone ? phone.replace(/\D/g, '') : '',
        cnpj: cnpj ? cnpj.replace(/\D/g, '') : '',
        stateRegistration,
        note,
        postalCode: postalCode ? postalCode.replace(/\D/g, '') : '',
        ibge,
        street,
        number,
        complement,
        district,
        city,
        state,
        country,
        userERP,
        passwordERP,
        urlERP,
      });

      if (newCompany) {
        if (newCompany.success && newCompany.success === false) {
          addNotification(
            'danger',
            'Nova Empresa',
            `Erro ao incluir a Empresa ${code}!`,
            'top-right',
          );
        } else {
          addNotification(
            'success',
            'Nova Empresa',
            `Nova Empresa ${code} incluída com sucesso!`,
            'top-right',
          );

          this.props.history.push(ROUTES.COMPANY_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            'Nova Empresa',
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            'Nova Empresa',
            `Erro ao incluir a Empresa ${code}!`,
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          'Nova Empresa',
          `Erro ao incluir a Empresa ${code}!`,
          'top-right',
        );
      }
    }
  };

  updateCompanySubmit = async () => {
    const {
      corporateName, companyName, email, phone, cnpj,
      stateRegistration, note, postalCode, ibge, street,
      number, complement, district, city, state, country, userERP, passwordERP, urlERP,
      companyId,
    } = this.state;

    try {
      const updCompany = await this.props.updateCompany({
        companyId,
        corporateName,
        companyName,
        email,
        phone: phone ? phone.replace(/\D/g, '') : '',
        cnpj: cnpj ? cnpj.replace(/\D/g, '') : '',
        stateRegistration,
        note,
        postalCode: postalCode ? postalCode.replace(/\D/g, '') : '',
        ibge,
        street,
        number,
        complement,
        district,
        city,
        state,
        country,
        userERP,
        passwordERP,
        urlERP,
      });

      if (updCompany) {
        if (updCompany.success && updCompany.success === false) {
          addNotification(
            'danger',
            'Atualizar Empresa',
            `Erro ao atualizar a Empresa ${companyName}!`,
            'top-right',
          );
        } else {
          addNotification(
            'success',
            'Atualizar Empresa',
            `Atualização do Empresa ${companyName} efetuada com sucesso!`,
            'top-right',
          );

          this.props.history.push(ROUTES.COMPANY_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            'Atualizar Empresa',
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            'Atualizar Empresa',
            `Erro ao atualizar a Empresa ${companyName}!`,
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          'Atualizar Empresa',
          `Erro ao atualizar a Empresa ${companyName}!`,
          'top-right',
        );
      }
      addNotification(
        'danger',
        'Atualizar Empresa',
        `Erro ao atualizar a Empresa ${companyName}!`,
        'top-right',
      );
    }
  };

  render() {
    const {
      code, corporateName, companyName, email, phone, cnpj,
      stateRegistration, note, postalCode, street,
      number, complement, district, city, state, country, userERP, passwordERP, urlERP,
    } = this.state;

    const {
      mode, disableCode, disableCorporateName, disableCompanyName,
      disableEmail, disablePhone, disableCNPJ, disableStateRegistration,
      disableNote, disablePostalCode, disableStreet, disableNumber,
      disableComplement, disableDistrict, disableCity, disableState,
      disableCountry, disableUserERP, disablePasswordERP,
      disableUrlERP, showCreate, showUpdate, showCancel,
    } = this.state;

    let title = '';
    let breadActive = '';

    if (mode === 'create' || mode === 'copy') {
      title = 'Nova Empresa';
    } else if (mode === 'edit') {
      title = 'Editar Empresa';
      breadActive = `${code} - ${companyName}`;
    } else if (mode === 'detail') {
      title = 'Detalhes Empresa';
      breadActive = `${code} - ${companyName}`;
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left" style={{ color: '#f1f1f1' }}>
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              Início
            </li>
            <li className="breadcrumb-item">Administração</li>
            <li className="breadcrumb-item">Empresas</li>
            <li className="breadcrumb-item active">{breadActive}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <Panel>
              <PanelHeader noButton />
              <PanelBody>
                {this.createInput(
                  code,
                  'code',
                  'Código:',
                  'Informe o código da empresa (obrigatório)',
                  'text',
                  true,
                  disableCode,
                )}
                {this.createInput(
                  corporateName,
                  'corporateName',
                  'Razão Social:',
                  'Informe a razão social',
                  'text',
                  false,
                  disableCorporateName,
                )}
                {this.createInput(
                  companyName,
                  'companyName',
                  'Nome:',
                  'Informe o nome da empresa (obrigatório)',
                  'text',
                  true,
                  disableCompanyName,
                )}
                {this.createInputMask(
                  cnpj,
                  'cnpj',
                  'CNPJ:',
                  'Informe o CNPJ (obrigatório)',
                  '99.999.999/9999-99',
                  'text',
                  true,
                  disableCNPJ,
                )}
                {this.createInput(
                  stateRegistration,
                  'stateRegistration',
                  'Inscrição Estadual:',
                  'Informe a inscrição estadual (obrigatório)',
                  'text',
                  true,
                  disableStateRegistration,
                )}
                {this.createInputMask(
                  postalCode,
                  'postalCode',
                  'CEP:',
                  'Informe o CEP (obrigatório)',
                  '99999-999',
                  'text',
                  true,
                  disablePostalCode,
                )}
                {this.createInput(
                  street,
                  'street',
                  'Rua:',
                  'Informe a rua',
                  'text',
                  false,
                  disableStreet,
                )}
                {this.createInput(
                  number,
                  'number',
                  'Número:',
                  'Informe o número',
                  'text',
                  false,
                  disableNumber,
                )}
                {this.createInput(
                  complement,
                  'complement',
                  'Complemento:',
                  'Informe o complemento',
                  'text',
                  false,
                  disableComplement,
                )}
                {this.createInput(
                  district,
                  'district',
                  'Bairro:',
                  'Informe o bairro',
                  'text',
                  false,
                  disableDistrict,
                )}
                {this.createInput(
                  city,
                  'city',
                  'Cidade:',
                  'Informe a cidade',
                  'text',
                  false,
                  disableCity,
                )}
                {this.createInput(
                  state,
                  'state',
                  'Estado:',
                  'Informe o estado',
                  'text',
                  false,
                  disableState,
                )}
                {this.createInput(
                  country,
                  'country',
                  'País:',
                  'Informe o país',
                  'text',
                  false,
                  disableCountry,
                )}
                {this.createInput(
                  email,
                  'email',
                  'E-mail:',
                  'Informe o e-mail',
                  'text',
                  false,
                  disableEmail,
                )}
                {this.createInputMask(
                  phone,
                  'phone',
                  'Telefone:',
                  'Informe o telefone',
                  phone && phone.length < 11 ? '(99) 9999-9999' : '(99) 99999-9999',
                  'tel',
                  false,
                  disablePhone,
                )}
                {this.createInput(
                  userERP,
                  'userERP',
                  'Usuário ERP:',
                  'Informe o Usuário ERP',
                  'text',
                  false,
                  disableUserERP,
                )}
                {this.createInput(
                  passwordERP,
                  'passwordERP',
                  'Senha ERP:',
                  'Informe a Senha ERP',
                  'password',
                  false,
                  disablePasswordERP,
                )}
                {this.createInput(
                  urlERP,
                  'urlERP',
                  'URL ERP:',
                  'Informe o URL ERP',
                  'text',
                  false,
                  disableUrlERP,
                )}
                {this.createInput(
                  note,
                  'note',
                  'Observação:',
                  'Informe a observação',
                  'text',
                  false,
                  disableNote,
                )}
              </PanelBody>
              <PanelFooter>
                <Link to={{ pathname: ROUTES.COMPANY_LIST, state: { dice: this.props.location.dice } }}>
                  {(showCancel
                    ? (
                      <OneClickButton
                        type="submit"
                        className="btn btn-120 btn-white p-5 m-5"
                      >
                        Cancelar
                      </OneClickButton>
                    )
                    : (
                      <OneClickButton
                        type="submit"
                        className="btn btn-120 btn-white p-5 m-5"
                      >
                        Voltar
                      </OneClickButton>
                    ))}
                </Link>
                {(showCreate
                  && (
                  <OneClickButton
                    type="button"
                    className="btn btn-120 btn-primary p-5 m-5"
                    onClick={() => this.createCompanySubmit()}
                  >
                    Criar
                  </OneClickButton>
                  )
                )}
                {(showUpdate
                  && (
                  <OneClickButton
                    type="button"
                    className="btn btn-120 btn-primary p-5 m-5"
                    onClick={() => this.updateCompanySubmit()}
                  >
                    Atualizar
                  </OneClickButton>
                  )
                )}
              </PanelFooter>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app.userLogged,
});

const mapDispatchToProps = (dispatch) => ({
  getCompany: (companyId) => dispatch(getCompany(companyId)),
  createCompany: (company) => dispatch(createCompany(company)),
  updateCompany: (company) => dispatch(updateCompany(company)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompanyForm));
