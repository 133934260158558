import StorageService from './storage';

function isAuthenticated() {
  const auth = StorageService.get(StorageService.keys.APP_AUTH_OBJECT);
  return (auth && auth.access_token);
}

function isInvalidToken(error) {
  return error && error.error && error.error.code === 102;
}

function get() {
  if (isAuthenticated()) return StorageService.get(StorageService.keys.APP_AUTH_OBJECT);
}

function create(auth) {
  StorageService.save(StorageService.keys.APP_AUTH_OBJECT, auth);
}

function reset() {
  StorageService.remove(StorageService.keys.APP_AUTH_OBJECT);
}

export default {
  get,
  isAuthenticated,
  isInvalidToken,
  create,
  reset,
};
