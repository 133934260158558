import servers from '../../server/servers';
import { addLoading, removeLoading } from './loading';

export const setRpwServers = (serversInformationList) => ({
  type: 'SET_RPW_SERVERS',
  serversInformationList,
});

export const setProgramsOfSelectedServer = (programsInfo) => ({
  type: 'SET_SELECTED_SERVER_PROGRAMS',
  programsInfo,
});

export const getServersInformation = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const getServersInformationList = await servers.getServersInformation();
    dispatch(setRpwServers(getServersInformationList));
  } finally {
    dispatch(removeLoading());
  }
};

export const getProgramsOfSelectedServer = (selectedServerId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const getProgramInfo = await servers.getProgramsOfSelectedServer(selectedServerId);
    dispatch(setProgramsOfSelectedServer(getProgramInfo));
  } finally {
    dispatch(removeLoading());
  }
};

export const setServerAlias = (alias, id) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const serverAlias = await servers.setServerAlias(alias, id);
    return serverAlias;
  } finally {
    dispatch(removeLoading());
  }
};

export const setProgramExecTime = (time, id) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const execTime = await servers.setProgramExecTime(time, id);
    return execTime;
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteSelectedProgram  = (programaId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await servers.deleteProgram(programaId);
  } finally {
    dispatch(removeLoading());
  }
}
