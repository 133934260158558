/* eslint-disable max-len */
import RestService from '../services/rest';

const getAllAccessProfiles = async () => RestService.getAuthenticated('accessProfile/getGrid');
const deleteAccessProfile = async (code) => RestService.postAuthenticated('accessProfile/delete', { code });
const updateAccessProfile = async (accessProfile = {}) => RestService.postAuthenticated('accessProfile/update', { ...accessProfile });
const createAccessProfile = async (accessProfile = {}) => RestService.postAuthenticated('accessProfile/create', { ...accessProfile });
const getAccessProfile = async (code) => RestService.getAuthenticated('accessProfile/get', { code });
const getAccessProfileOptions = async () => RestService.getAuthenticated('accessProfile/list/options');

export default {
  getAllAccessProfiles,
  deleteAccessProfile,
  updateAccessProfile,
  createAccessProfile,
  getAccessProfile,
  getAccessProfileOptions,
};
