import RestService from '../services/rest';

// GET
async function getDownloads() {
  return RestService.getAuthenticated('downloads/list/all');
}

async function getDownload(downloadId) {
  return RestService.getAuthenticated(`downloads/detail?downloadId=${downloadId}`);
}

// POST
async function createDownload(download) {
  return RestService.postAuthenticated('downloads/create', download);
}

async function deleteDownload(downloadId) {
  return RestService.postAuthenticated('downloads/delete', { downloadId });
}

// PUT
async function updateDownload(download) {
  return RestService.putAuthenticated('downloads/update', download);
}

export default {
  getDownloads,
  createDownload,
  updateDownload,
  getDownload,
  deleteDownload,
};
