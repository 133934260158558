/* eslint-disable react/button-has-type */
import React, { useState } from "react";

import styles from "./styles.module.scss";

// eslint-disable-next-line import/prefer-default-export, object-curly-newline
export function Counter({
  critical,
  warning,
  inactive,
  success,
  filterStatusCards,
}) {
  const [buttonStatusCriticalActive, setButtonStatusCriticalActive] =
    useState(false);
  const [buttonStatusWarningActive, setButtonStatusWarningActive] =
    useState(false);
  const [buttonStatusSuccessActive, setButtonStatusSuccessActive] =
    useState(false);
  const [buttonStatusInactiveActive, setButtonStatusInactiveActive] =
    useState(false);

  return (
    <div className={styles.counter}>
      <h3>Contador</h3>
      <ul className={styles.listCounter}>
        <li>
          <button
            className={`${styles.critical} ${
              buttonStatusCriticalActive && styles.active
            }`}
            onClick={() => {
              setButtonStatusCriticalActive(!buttonStatusCriticalActive);
              filterStatusCards("critical");
            }}
          >
            <h4>{critical}</h4>
          </button>
        </li>
        <li>
          <button
            className={`${styles.warning} ${
              buttonStatusWarningActive && styles.active
            }`}
            onClick={() => {
              setButtonStatusWarningActive(!buttonStatusWarningActive);
              filterStatusCards("warning");
            }}
          >
            <h4>{warning}</h4>
          </button>
        </li>
        <li>
          <button
            className={`${styles.success} ${
              buttonStatusSuccessActive && styles.active
            }`}
            onClick={() => {
              setButtonStatusSuccessActive(!buttonStatusSuccessActive);
              filterStatusCards("success");
            }}
          >
            <h4>{success}</h4>
          </button>
        </li>
        <li>
          <button
            className={`${styles.inactive} ${
              buttonStatusInactiveActive && styles.active
            }`}
            onClick={() => {
              setButtonStatusInactiveActive(!buttonStatusInactiveActive);
              filterStatusCards("inactive");
            }}
          >
            <h4>{inactive}</h4>
          </button>
        </li>
      </ul>
    </div>
  );
}
