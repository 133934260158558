import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  getProgramsOfSelectedServer, getServersInformation, deleteSelectedProgram,
} from '../../../app/store/actions/servers';
import Table from '../../../components/table/Table';
import ROUTES from '../../../config/routes';
import WikiHelp from '../../../components/tooltip/wikiHelper';

class ServerConfiguration extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedServer: {},
      showModalDelete: false,
    };

    this.serverColumns = [
      {
        Header: 'Ações',
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">Ações</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showEditServer(rows.row)}>
                  Editar

                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: 'Empresa',
        accessor: 'company.companyName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        show: this.props.userLogged.type === 1,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const value = _.lowerCase(row._original.company
            ? row._original.company.companyName
            : '');
          if (_.includes(value, input)) {
            return true;
          }
        },
      }, {
        Header: 'Código',
        accessor: 'codServidExec',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: 'Alias',
        accessor: 'aliasServidExec',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: 'Descrição',
        accessor: 'desServidExec',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Filter: this.filterColumn,
      }, {
        Header: 'Sistema',
        accessor: 'indTipFilaExec',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 70,
        Filter: this.filterColumn,
      },
    ];

    this.programsColumns = [
      {
        Header: 'Ações',
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">Ações</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showEditServer(rows.row, true)}>
                  Editar

                </DropdownItem>
                <DropdownItem onClick={() => this.showModalDeleteProg(rows.row)}>
                  Eliminar

                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: 'Código',
        accessor: 'codProgDtsul',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: 'Descrição',
        accessor: 'desProgDtsul',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Filter: this.filterColumn,
      }, {
        Header: 'Tempo Médio de Execução',
        accessor: 'tempoMedioExec',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: this.filterColumn,
      },
    ];
  }

  componentDidMount() {
    this.getServersInformation();
    this.selectedProducts();
  }

  getServersInformation = async () => {
    await this.props.getServersInformation();
  };

  deleteSelectedProgram = async (row) => {
    await this.props.deleteSelectedProgram(row.id);
    await this.props.getProgramsOfSelectedServer(row.idServidExec);
    this.setState({ showModalDelete: false });
  };

  selectedProducts = async (selectedServer) => {
    const returningFromEditPage = (this.props.location.state
      && this.props.location.state.returnInformation) && this.props.location.state.returnInformation;
    if (selectedServer) {
      await this.setState({ selectedServer: { ...selectedServer } });
      await this.props.getProgramsOfSelectedServer(selectedServer.id);
      return;
    }
    if (returningFromEditPage) {
      await this.props.getProgramsOfSelectedServer(returningFromEditPage.idServidExec);
    }
  };

  showModalDeleteProg = (prog) => {
    this.setState({ showModalDelete: true, servidProg: prog });
  };

  showEditServer = (server, isProgram) => {
    this.props.history.push({
      ...(isProgram ? { pathname: ROUTES.SERVER_PROGRAM_EDIT } : { pathname: ROUTES.SERVER_EDIT }),
      state: {
        information: server._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  render() {
    const { serversInformation = [], selectedServerPrograms = [] } = this.props;
    const { selectedServer = {}, showModalDelete, servidProg } = this.state;
    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              Início
            </li>
            <li className="breadcrumb-item">Cadastros</li>
            <li className="breadcrumb-item active">Servidores RPW</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            Servidores RPW
          </h1>
          <WikiHelp wikiPath={ROUTES.SERVER_HELP} />
        </div>
        <div>
          <Table
            downloadCSV
            filename={`Servidores RPW - ${moment(new Date()).format('DD_MM_YYYY, hh:mm:ss')}`}
            panelHeaderProps={{
              children: 'Servidores'.toUpperCase(),
              onClickReload: this.getServersInformation,
            }}
            filterable
            data={serversInformation}
            columns={this.serverColumns}
            expander
            defaultPageSize={5}
            // defaultSorted={defaultSorted}
            defaultFilterMethod={(filter, row) => {
              const input = _.lowerCase(filter.value);
              const value = _.lowerCase(row[filter.id]);
              if (_.includes(value, input)) {
                return true;
              }
            }}
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  onClick: () => {
                    this.selectedProducts(rowInfo.original);
                  },
                  style: {
                    background: selectedServer && rowInfo.original.id === selectedServer.id ? 'silver' : 'white',
                    cursor: 'pointer',
                  },
                };
              }
              return {};
            }}
          />
        </div>
        <div>
          <Table
            downloadCSV
            filename={`Programas RPW - ${moment(new Date()).format('DD_MM_YYYY')}`}
            panelHeaderProps={{
              children: 'Programas'.toUpperCase(),
            }}
            filterable
            data={selectedServerPrograms}
            columns={this.programsColumns}
            expander
            defaultPageSize={5}
            pageSizeOptions={[5, 10, 15]}
            // defaultSorted={defaultSortedProducts}
            defaultFilterMethod={(filter, row) => {
              const input = _.lowerCase(filter.value);
              const value = _.lowerCase(row[filter.id]);
              if (_.includes(value, input)) {
                return true;
              }
            }}
          />
        </div>
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText="Cancelar"
            confirmBtnText="Confirmar"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title="Você tem certeza?"
            onConfirm={() => this.deleteSelectedProgram(servidProg && servidProg._original)}
            onCancel={() => { this.setState({ showModalDelete: false }); }}
          >
            { `Confirma a eliminação do programa ${servidProg.codProgDtsul}?`}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  serversInformation: state.servers.serversInformation,
  selectedServerPrograms: state.servers.selectedServerPrograms,
  userLogged: state.app && state.app.userLogged,
});

const mapDispatchToProps = (dispatch) => ({
  getServersInformation: () => dispatch(getServersInformation()),
  deleteSelectedProgram: (id) => dispatch(deleteSelectedProgram(id)),
  getProgramsOfSelectedServer: (selectedServer) => dispatch(getProgramsOfSelectedServer(selectedServer)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ServerConfiguration));
