/* eslint-disable operator-linebreak */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-confusing-arrow */
/* eslint-disable arrow-body-style */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import Carousel from 'better-react-carousel';

import { CardCarousel } from '../cardCarousel';

import styles from './styles.module.scss';

export function AllStatusCarrousel({ listServices, selectedOption }) {
  const selectOption =
    selectedOption &&
    selectedOption.value.map((element) => element.codServidExec);
  const dot = ({ isActive }) => (
    <span
      style={{
        height: '1rem',
        width: '1rem',
        borderRadius: '50%',
        background: isActive && '#eaeaea',
        border: '2px solid #eaeaea',
      }}
    />
  );
  const listServers = [];
  const listServersFilter = async () => {
    await listServices.map((element) => {
      const serverName = element.systemName;
      if (
        selectOption &&
        selectOption.find((elementFind) => elementFind === serverName)
      ) {
        listServers.push(element);
      }
    });
  };
  listServersFilter();
  return (
    <section className={styles.carrousel}>
      <Carousel
        loop
        rows={2}
        gap={24}
        showDots
        cols={10}
        dot={dot}
        hideArrow
        autoplay={5000}
        responsiveLayout={[
          {
            breakpoint: 1800,
            cols: 10,
          },
          {
            breakpoint: 1570,
            cols: 8,
          },
          {
            breakpoint: 1270,
            cols: 6,
          },
          {
            breakpoint: 970,
            cols: 5,
          },
          {
            breakpoint: 820,
            cols: 4,
            rows: 1,
          },
          {
            breakpoint: 768,
            cols: 2,
            rows: 1,
            showArrow: true,
            hideDots: false,
          },
        ]}
      >
        {listServers.length
          ? listServers.map((card) => (
              <Carousel.Item key={card.id}>
                <CardCarousel
                  id={card.id}
                  title={card.title}
                  system={card.system}
                  systemName={card.systemName}
                  status={card.status}
                  message={card.message}
                />
              </Carousel.Item>
            ))
          : listServices.map((card) => (
              <Carousel.Item key={card.id}>
                <CardCarousel
                  id={card.id}
                  title={card.title}
                  system={card.system}
                  systemName={card.systemName}
                  status={card.status}
                  message={card.message}
                />
              </Carousel.Item>
            ))}
      </Carousel>
    </section>
  );
}
