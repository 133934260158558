/* eslint-disable react/no-access-state-in-setstate */
import { ReUnixCron } from '@sbzen/re-cron';
import Construe from 'cronstrue/i18n';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import addNotification from '../../../components/notification';
import OneClickButton from '../../../components/form/button';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../components/panel/panel';

import ROUTES from '../../../config/routes';

import { updateJob } from '../../../app/store/actions/jobs';

class JobsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'detail',
      cron: '* * * * *',
      showUpdate: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
  }

  setMode = () => {
    let mode = '';

    if (this.props.isCreate) {
      mode = 'create';
    } else if (this.props.isCopy) {
      mode = 'copy';
    } else if (this.props.isEdit) {
      mode = 'edit';
    } else if (this.props.isDetail) {
      mode = 'detail';
    }

    const { state = {} } = this.props.location;
    const { job = {} } = state;
    const { cron } = job;

    if (mode === 'edit') {
      this.setState({
        mode,
        cron: cron || this.state.cron,
        job,
        showUpdate: true,
      });
    }
  };

  updateJob = async () => {
    if (this.state.job) {
      const updatedJob = {
        ...this.state.job,
        cron: this.state.cron,
      };
      try {
        await this.props.updateJob(updatedJob);
        addNotification(
          'success',
          'Editar Job',
          'Job atualizado com sucesso!',
          'top-right',
        );
        this.props.history.push(ROUTES.JOBS_LIST);
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
          const { error } = err.response.data;

          if (error.details || error.message) {
            addNotification(
              'danger',
              'Editar Job',
              `${error.code} - ${error.details || error.message}`,
              'top-right',
            );
          } else {
            addNotification(
              'danger',
              'Editar Job',
              'Falha ao atualizar Job!',
              'top-right',
            );
          }
        } else {
          addNotification(
            'danger',
            'Editar Job',
            'Falha ao atualizar Job!',
            'top-right',
          );
        }
      }
    }
  };

  render() {
    const {
      mode,
      showUpdate,
      showCancel,
    } = this.state;

    const { state = {} } = this.props.location;
    const { job = {} } = state;
    const { name } = job;

    let title = '';
    let breadActive = '';

    if (mode === 'edit') {
      title = `Editar Job - ${name}`;
      breadActive = `${name}`;
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              Início
            </li>
            <li className="breadcrumb-item">Administração</li>
            <li className="breadcrumb-item">Gerais</li>
            <li className="breadcrumb-item">Jobs</li>
            <li className="breadcrumb-item active">{breadActive}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel className="panel-with-tabs">
                <PanelHeader
                  noButton
                  pageDescription={
                    Construe.toString(
                      this.state.cron,
                      { locale: 'pt_BR' },
                    )
                  }
                />
                <PanelBody>
                  <div style={{ textAlign: 'initial' }}>
                    <ReUnixCron
                      value={this.state.cron}
                      localization={this.props.localization || undefined}
                      onChange={(cronValue) => this.setState({ cron: cronValue })}
                    />
                  </div>
                </PanelBody>
                <PanelFooter>
                  <Link to={{ pathname: ROUTES.JOBS_LIST, state: { dice: this.props.location.dice } }}>
                    {(showCancel
                      ? (
                        <OneClickButton
                          type="submit"
                          className="btn btn-120 btn-white p-5 m-5"
                        >
                          Cancelar
                        </OneClickButton>
                      )
                      : (
                        <OneClickButton
                          type="submit"
                          className="btn btn-120 btn-white p-5 m-5"
                        >
                          Voltar
                        </OneClickButton>
                      ))}
                  </Link>
                  {(showUpdate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateJob()}
                    >
                      Atualizar

                    </OneClickButton>
                    )
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  localization: state.app.cronLocation,
  language: state.app.locale,
});

const mapDispatchToProps = (dispatch) => ({
  updateJob: (job) => dispatch(updateJob(job)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(JobsForm));
