import React from 'react';
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { connect } from 'react-redux';
import { logout } from '../../../app/store/actions/app';

class DropdownProfile extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  onClickLogout = async () => {
    try {
      await this.props.logout();
      document.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { userLogged } = this.props;

    return (
      <Dropdown
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
        className="dropdown navbar-user cursorPointer dropdown"
        tag="li"
      >
        <DropdownToggle
          tag="a"
          style={{
            display: 'block',
            position: 'relative',
            padding: '0',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              overflow: 'hidden',
              display: 'flex',
              width: '3rem',
              height: '3rem',
              borderRadius: '50%',
              marginTop: 'auto',
              marginBottom: 'auto',
              paddingTop: '0.9rem',
              backgroundColor: '#2B2C30',
              float: 'right',
              marginRight: '2rem',
            }}
          >
            <i style={{ fontSize: '2rem', color: '#f1f1f1' }} className="fa fa-user" />
          </div>
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              display: 'flex',
              marginTop: '1rem',
              marginBottom: 'auto',
              marginRight: '1rem',
              float: 'right',
            }}
          >
            <h5
              style={{
                fontWeight: 600,
                color: '#f1f1f1',
                textDecoration: 'none',
              }}
            >
              {/* {(userLogged && userLogged.login) || ''} */}
              {userLogged ? userLogged.name : ''}
            </h5>
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul" style={{ marginTop: '4rem' }}>
          <DropdownItem onClick={this.onClickLogout}>Sair</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app.userLogged,
});

const mapActionsToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapActionsToProps)(DropdownProfile);
