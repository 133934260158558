function getCronLocation() {
  return {
    common: {
      month: {
        january: 'Janeiro',
        february: 'Fevereiro',
        march: 'Março',
        april: 'Abril',
        may: 'Maio',
        june: 'Junho',
        july: 'Julho',
        august: 'Agosto',
        september: 'Setembro',
        october: 'Outubro',
        november: 'Novembro',
        december: 'Dezembro',
      },
      dayOfWeek: {
        sunday: 'Domingo',
        monday: 'Segunda',
        tuesday: 'Terça',
        wednesday: 'Quarta',
        thursday: 'Quinta',
        friday: 'Sexta',
        saturday: 'Sábado',
      },
      dayOfMonth: {
        '1st': '1st',
        '2nd': '2nd',
        '3rd': '3rd',
        '4th': '4th',
        '5th': '5th',
        '6th': '6th',
        '7th': '7th',
        '8th': '8th',
        '9th': '9th',
        '10th': '10th',
        '11th': '11th',
        '12th': '12th',
        '13th': '13th',
        '14th': '14th',
        '15th': '15th',
        '16th': '16th',
        '17th': '17th',
        '18th': '18th',
        '19th': '19th',
        '20th': '20th',
        '21st': '21st',
        '22nd': '22nd',
        '23rd': '23rd',
        '24th': '24th',
        '25th': '25th',
        '26th': '26th',
        '27th': '27th',
        '28th': '28th',
        '29th': '29th',
        '30th': '30th',
        '31st': '31st',
      },
    },
    tabs: {
      seconds: 'Segundos',
      minutes: 'Minutos',
      hours: 'Horas',
      day: 'Dia',
      month: 'Mês',
      year: 'Ano',
    },
    unix: {
      day: {
        every: {
          label: 'Todos os dias',
        },
        dayOfWeekIncrement: {
          label1: 'Cada',
          label2: 'dia(s) da semana',
        },
        dayOfMonthIncrement: {
          label1: 'Cada',
          label2: 'dia(s) do mês',
        },
        dayOfWeekAnd: {
          label: 'Dia específico da semana (escolha um ou vários)',
        },
        dayOfMonthAnd: {
          label: 'Dia específico do mês (escolha um ou vários)',
        },
      },
      month: {
        every: {
          label: 'Todo mês',
        },
        increment: {
          label1: 'Cada',
          label2: 'mês(es)',
        },
        and: {
          label: 'Mês específico (escolha um ou vários)',
        },
        range: {
          label1: 'Todos os meses entre',
          label2: 'e',
        },
      },
      minute: {
        every: {
          label: 'Cada minuto',
        },
        increment: {
          label1: 'Cada',
          label2: 'minuto(s)',
        },
        and: {
          label: 'Minuto específico (escolha um ou vários)',
        },
        range: {
          label1: 'Cada minuto entre',
          label2: 'e',
        },
      },
      hour: {
        every: {
          label: 'Toda hora',
        },
        increment: {
          label1: 'Cada',
          label2: 'hora(s)',
        },
        and: {
          label: 'Hora específica (escolha uma ou várias)',
        },
        range: {
          label1: 'A cada hora entre',
          label2: 'e',
        },
      },
    },
  };
}

export default { getCronLocation };
