import RestService from '../services/rest';

// GET
async function getCompanies() {
  return RestService.getAuthenticated('companies/list/all');
}

async function getCompany(companyId) {
  return RestService.getAuthenticated(`companies/detail?companyId=${companyId}`);
}

async function getCompaniesOptions() {
  return RestService.getAuthenticated('companies/list/options');
}

// POST
async function createCompany(company) {
  return RestService.postAuthenticated('companies/create', company);
}

async function deleteCompany(companyId) {
  return RestService.postAuthenticated('companies/delete', { companyId });
}

// PUT
async function updateCompany(company) {
  return RestService.putAuthenticated('companies/update', company);
}

export default {
  getCompanies,
  createCompany,
  updateCompany,
  getCompany,
  getCompaniesOptions,
  deleteCompany,
};
