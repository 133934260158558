import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import OneClickButton from '../../../components/form/button';
import addNotification from '../../../components/notification';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../components/panel/panel';

import ROUTES from '../../../config/routes';

import {
  createMonitorFilter, createServerFilter, getMonitorFilter, getServersList,
} from '../../../app/store/actions/monitorFilters';

import ProgramsGroup from './grouper';

class MonitorFilterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'detail',
      filterName: '',
      monitorFilterSelected: null,
      monitorFilterId: null,
      disableFilterName: true,
      listServers: [],
      listServersFilter: [],
      showCreate: false,
      showCancel: false,
      checkedAdd: {},
      checkedRemove: {},
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getFilterSelected();
    }
  }

  getFilterSelected = async (monitorFilterId = null) => {
    this.setState({
      checkedAdd: {},
      checkedRemove: {},
    });
    let filterSelected = null;
    if (monitorFilterId) {
      filterSelected = await this.props.getMonitorFilter(monitorFilterId);
    } else if (this.props.location.state.monitorFilter) {
      const { monitorFilter } = this.props.location.state;

      filterSelected = await this.props.getMonitorFilter(monitorFilter.id);
    }

    if (filterSelected) {
      const listServers = await this.props.getServersList();
      this.setState({
        monitorFilterSelected: filterSelected,
        monitorFilterId: filterSelected.id,
        filterName: filterSelected.filterName,
        listServers: listServers.filter((server) => {
          if (filterSelected.servers) {
            const servers = filterSelected.servers.map((srv) => srv.codServidExec);

            if (servers.includes(server.codServidExec)) return false;
          }
          return true;
        }),
        listServersFilter: filterSelected.servers || [],
      });
    }
  };

  setMode = () => {
    let mode = '';

    if (this.props.isCreate) {
      mode = 'create';
    } else if (this.props.isCopy) {
      mode = 'copy';
    } else if (this.props.isEdit) {
      mode = 'edit';
    } else if (this.props.isDetail) {
      mode = 'detail';
    }

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        showCreate: true,
        showCancel: true,
        disableFilterName: false,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        showCancel: true,
        disableFilterName: true,
      });
    }
  };

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={`id-${attr}`} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          id={`id-${attr}`}
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  setValue = (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  createMonitorFilterSubmit = async () => {
    const { filterName } = this.state;

    try {
      const newFilter = await this.props.createMonitorFilter({
        filterName,
      });

      if (newFilter) {
        if (newFilter.success === false) {
          addNotification(
            'danger',
            'Novo Grupo RPW',
            `Erro ao incluir o grupo ${filterName} !`,
            'top-right',
          );
        } else {
          addNotification(
            'success',
            'Novo Grupo RPW',
            `Novo Grupo RPW ${filterName} incluído com sucesso!`,
            'top-right',
          );

          this.props.history.push(ROUTES.MONITOR_FILTER_LIST);
          this.getFilterSelected(newFilter.id);
        }
      } else {
        addNotification(
          'danger',
          'Novo Grupo RPW',
          `Erro ao incluir o grupo ${filterName} !`,
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            'Novo Grupo RPW',
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            'Novo Grupo RPW',
            `Erro ao incluir o grupo ${filterName} !`,
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          'Novo Grupo RPW',
          `Erro ao incluir o grupo ${filterName} !`,
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      filterName, monitorFilterSelected, listServers = [], listServersFilter = [],
    } = this.state;

    const {
      mode, showCreate, showCancel, disableFilterName,
    } = this.state;

    let title = '';

    if (mode === 'create' || mode === 'copy') {
      title = 'Novo Grupo RPW';
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              Início
            </li>
            <li className="breadcrumb-item">Cadastro</li>
            <li className="breadcrumb-item active">Grupos de RPW</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form onSubmit={(e) => e.preventDefault()}>
              <Panel className="panel-with-tabs">
                <PanelHeader noButton />
                <PanelBody>
                  <div className="row">
                    <div className="col-xl-12">
                      {this.createInput(
                        filterName,
                        'filterName',
                        'Descrição:',
                        'Informe a descrição do Grupo (obrigatório)',
                        'text',
                        true,
                        disableFilterName,
                      )}
                    </div>
                  </div>
                  {monitorFilterSelected && (
                  <ProgramsGroup
                    listServers={listServers}
                    listServersFilter={listServersFilter}
                    checkedAdd={this.state.checkedAdd}
                    checkedRemove={this.state.checkedRemove}
                    monitorFilterId={this.state.monitorFilterId}
                    setState={(data) => this.setState(data)}
                    getFilterSelected={this.getFilterSelected}
                  />
                  )}
                </PanelBody>
                <PanelFooter>
                  <Link to={{ pathname: ROUTES.MONITOR_FILTER_LIST, state: { dice: this.props.location.dice } }}>
                    {(showCancel
                      ? (
                        <OneClickButton
                          type="submit"
                          className="btn btn-120 btn-white p-5 m-5"
                        >
                          Cancelar
                        </OneClickButton>
                      )
                      : (
                        <OneClickButton
                          type="submit"
                          className="btn btn-120 btn-white p-5 m-5"
                        >
                          Voltar
                        </OneClickButton>
                      )
                    )}
                  </Link>
                  {(showCreate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createMonitorFilterSubmit()}
                    >
                      Criar
                    </OneClickButton>
                    )
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app && state.app.userLogged,
});

const mapDispatchToProps = (dispatch) => ({
  getMonitorFilter: (filterId) => dispatch(getMonitorFilter(filterId)),
  createMonitorFilter: (monitorFilter) => dispatch(createMonitorFilter(monitorFilter)),
  createServerFilter: (serverFilter) => dispatch(createServerFilter(serverFilter)),
  getServersList: () => dispatch(getServersList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MonitorFilterForm));
