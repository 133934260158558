function integrationCode(code) {
  if (code || code === '') {
    switch (code) {
      case 'stockAdjustmentInv':
        return 'Ajuste Inventário';
      case 'stockAdjustment':
        return 'Ajuste Estoque';
      default:
        return code;
    }
  } else if (!code) {
    return [
      { value: 'stockAdjustment', label: 'Ajuste Estoque' },
      { value: 'stockAdjustmentInv', label: 'Ajuste Inventário' },
    ];
  }
}

function integrationType(type) {
  if (type || type === 0) {
    switch (type) {
      case 1:
        return 'BeeStock';
      case 2:
        return 'ERP';
      default:
        return type;
    }
  } else if (!type) {
    return [
      { value: 1, label: 'BeeStock' },
      { value: 2, label: 'ERP' },
    ];
  }
}

export default {
  integrationCode,
  integrationType,
};
