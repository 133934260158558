const INITIAL_STATE = {
  serversExecList: [],
  serverUse: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_SERVERSEXEC': {
      return { ...state, serversExecList: action.serversExec };
    }
    case 'validServerUse': {
      return { ...state, serverUse: action.serverUse };
    }
    default:
      return state;
  }
};
