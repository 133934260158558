import JobsRequests from '../../server/jobs';
import { addLoading, removeLoading } from './loading';

export const setJobs = (jobs) => ({
  type: 'SET_JOBS',
  jobs,
});

export const getJobsList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const jobs = await JobsRequests.getJobs();
    dispatch(setJobs(jobs));
  } finally {
    dispatch(removeLoading());
  }
};

export const getJob = (name) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await JobsRequests.getJob(name);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateJob = (job) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await JobsRequests.updateJob(job);
  } finally {
    dispatch(removeLoading());
  }
};

export const executeJob = (name) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await JobsRequests.executeJob(name);
  } finally {
    dispatch(removeLoading());
  }
};
