import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { addLoading, removeLoading } from '../../app/store/actions/loading';
import { getListServersExecQueue } from '../../app/store/actions/serversExec';
import { AllStatusCarrousel } from '../../components/allStatusCarousel';
import { FooterPanel } from '../../components/footerPanel';
import Header from '../../components/headerPanel';
import addNotification from '../../components/notification';
import { PageSettings } from '../../config/page-settings';
import { time } from '../../config/timeSetTimeOut';
import '../../scss/monitor/monitor.module.scss';

import StorageService from '../../app/services/storage';

class PanelContent extends React.Component {
  static contextType = PageSettings;

  constructor(props) {
    super(props);
    this.state = {
      selectedOption: '',
      selectedCompanyOption: '',
      selectedOptions: [],
      filterData: [],
    };
    this.setInterval = null;
  }

  async componentDidMount() {
    this.context.handleSetPageSidebar(false);
    this.context.handleSetPageHeader(false);
    this.context.handleSetPageContentFullWidth(true);
    await this.getListServersExecQueue();
    this.setInterval = setInterval(async () => {
      await this.getListServersExecQueue();
    }, time);
  }

  componentWillUnmount() {
    this.context.handleSetPageSidebar(true);
    this.context.handleSetPageHeader(true);
    this.context.handleSetPageContentFullWidth(false);
    clearInterval(this.setInterval);
  }

  getListServersExecQueue = async () => {
    if (!this.props.validServerExec) {
      const user = this.props.userLogged;
      const codeCompany = user.type === 1 ? await StorageService.get('CompanySelect') : '';
      if (user.type !== 1 || (user.type === 1 && codeCompany?.value)) {
        try {
          await this.props.getListServersExecQueue(codeCompany.value);
          await this.filterListData();
        } catch (e) {
          addNotification(
            'danger',
            'Erro no Servidor',
            'Não foi possível buscar os dados no servidor',
            'top-right',
          );
        }
      }
    }
  };

  handleCompanySelect = async (selectedCompanyOption) => {
    const previousCompanySelect = await StorageService.get('CompanySelect');
    if (selectedCompanyOption !== previousCompanySelect) {
      await StorageService.save('CompanySelect', selectedCompanyOption);
      await this.setState({ filterData: [...[]] });
      this.props.addLoading();
      await this.getListServersExecQueue();
      this.props.removeLoading();
    }
  };

  handleChange = async (selectedOption) => {
    await this.setState({ selectedOption });
    this.filterListData();
  };

  filterStatusCards = async (setButtonStatus) => {
    let { filterData } = this.state;
    const { selectedOptions } = this.state;
    const { listServices } = this.props;

    const arrayIndexToRemove = selectedOptions.indexOf(setButtonStatus);
    if (arrayIndexToRemove !== -1) {
      selectedOptions.splice(arrayIndexToRemove, 1);
    } else selectedOptions.push(setButtonStatus);

    if (!selectedOptions.length) filterData = listServices;
    else {
      filterData = listServices.filter(({ status }) => selectedOptions.includes(status));
    }

    await this.setState({ selectedOptions, filterData });
  };

  filterListData = async () => {
    const { selectedOption } = this.state;
    const { listServices } = this.props;

    let allServers = false;
    if (selectedOption && selectedOption.label === 'Todos') allServers = true;

    const selectOption = selectedOption
      && selectedOption.value.map((element) => element.codServidExec);

    let listServers = [];

    if (!allServers && selectOption) {
      for (let index = 0; index < listServices.length; index += 1) {
        const element = listServices[index];
        const serverName = element.systemName;
        if (
          selectOption
          && selectOption.find((elementFind) => elementFind === serverName)
        ) {
          listServers.push(element);
        }
      }
    } else {
      listServers = listServices;
    }

    await this.setState({ filterData: listServers });
  };

  render() {
    const { listServices, getNumberStatus } = this.props;
    const { filterData } = this.state;

    return (
      <>
        <Header
          listServices={filterData}
          getListServersExecQueue={this.getListServersExecQueue}
          handleChange={this.handleChange}
          handleCompanySelect={this.handleCompanySelect}
        />
        {!!listServices.length && (
          <>
            <AllStatusCarrousel listServices={filterData} />
            <FooterPanel
              listServices={filterData}
              getNumberStatus={getNumberStatus}
              filterStatusCards={this.filterStatusCards}
            />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  listServices: state.serversExec.serversExecList,
  getNumberStatus: state.serversExec.serversExecList,
  userLogged: state.app && state.app.userLogged,
  validServerExec: state.serversExec.serverUse,
});

const mapDispatchToProps = (dispatch) => ({
  getListServersExecQueue: (companyCode) => dispatch(getListServersExecQueue(companyCode)),
  addLoading: () => dispatch(addLoading()),
  removeLoading: () => dispatch(removeLoading()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PanelContent));
