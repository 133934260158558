import PermissionsServer from '../../server/permissions';
import { addLoading, removeLoading } from './loading';

export const getPermissionTree = (code) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await PermissionsServer.getPermissionTree(code);
  } finally {
    dispatch(removeLoading());
  }
};

export const updatePermissionTree = (code, permissions) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await PermissionsServer.updatePermissionTree(code, permissions);
  } finally {
    dispatch(removeLoading());
  }
};
