import RestService from '../services/rest';

// GET
async function getJobs() {
  return RestService.getAuthenticated('crons/getCrons');
}

async function getJob(name) {
  return RestService.getAuthenticated(`crons/getCron?name=${name}`);
}

// POST
async function updateJob(job) {
  return RestService.postAuthenticated('crons/updateCron', job);
}

async function executeJob(name) {
  return RestService.postAuthenticated('crons/executeJob', { name });
}

export default {
  getJobs,
  getJob,
  updateJob,
  executeJob,
};
