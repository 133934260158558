/* eslint-disable no-extend-native */
import axios from 'axios';

import enUs from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import ptBr from 'date-fns/locale/pt-BR';
import jstz from 'jstimezonedetect';
import moment from 'moment-timezone';
import { registerLocale, setDefaultLocale } from 'react-datepicker';

import PreferencesData from '../data/preferences';
import AuthService from './services/auth';
import {
  getAllPermissions, refreshToken, setAppLocale,
  setAppTimezone, setAuth, setCronLocation,
} from './store/actions/app';
import CronHelper from '../helpers/cron';

export const axiosInstance = axios.create();

const dateLocales = {
  ptBr,
  enUs,
  es,
};

function addAxiosinterceptor(store) {
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const responseError = error.response;

      if (responseError && responseError.data) {
        const errorData = responseError.data;
        const isRefreshToken = responseError.config.method === 'post'
          && responseError.config.data.substring(0, 13) === 'refresh_token';

        // Em caso de token invalido obtem um novo token
        if (AuthService.isAuthenticated() && AuthService.isInvalidToken(errorData) && !isRefreshToken) {
          const token = AuthService.get().refresh_token;
          try {
            await store.dispatch(refreshToken(token));
            const accessToken = AuthService.get().access_token;
            responseError.config.headers.Authorization = `Bearer ${accessToken}`;

            return await axiosInstance(responseError.config);
          } catch (refreshError) {
            store.dispatch(setAuth({}));
            AuthService.reset();
            document.location.reload();
            return Promise.reject(refreshError);
          }
        }
      }
      return Promise.reject(error);
    },
  );
}

async function authenticated(store) {
  if (AuthService.isAuthenticated()) {
    await store.dispatch(setAuth(AuthService.get()));
    await store.dispatch(getAllPermissions());
  }
}

export default async (store, MainAppRef) => {
  addAxiosinterceptor(store);

  try {
    const tz = jstz.determine();
    const timezone = tz.name() || PreferencesData.timezone;
    const language = navigator.language || navigator.userLanguage || PreferencesData.language;
    const dateLocale = 'ptBr';

    store.dispatch(setCronLocation(CronHelper.getCronLocation()));
    store.dispatch(setAppLocale(language));
    store.dispatch(setAppTimezone(timezone));

    moment.tz.setDefault(timezone);
    moment.locale('pt-br');

    setDefaultLocale(dateLocale);
    registerLocale(dateLocale, dateLocales[dateLocale]);

    await authenticated(store);
  } catch (err) {
    // Redirect to error page
  } finally {
    if (MainAppRef && MainAppRef.current) {
      MainAppRef.current.mountApp();
    }
    const launchLoader = document.getElementById('launch');
    launchLoader.classList.add('hide');
  }
};
