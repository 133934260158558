export default {
  // INICIO
  HOME: '/',
  // LOGIN
  LOGIN: '/login',

  // MONITOR RPW
  MONITOR: '/monitor',
  TASKS: '/monitor/robot/:id',

  // DOWNLOAD
  DOWNLOAD: '/download',

  // ADMINISTRACAO
  ADMIN: '/admin',

  // GERAIS
  ADMIN_GENERAL: '/admin/general',
  // EMPRESAS
  COMPANY_HELP: '/admin/general/company',
  COMPANY_LIST: '/admin/general/company/list',
  COMPANY_COPY: '/admin/general/company/copy',
  COMPANY_DETAIL: '/admin/general/company/detail',
  COMPANY_EDIT: '/admin/general/company/edit',
  COMPANY_CREATE: '/admin/general/company/new',
  // JOBS
  JOBS_LIST: '/admin/general/jobs/list',
  JOBS_EDIT: '/admin/general/jobs/edit',

  // USUARIOS
  ADMIN_USER: '/admin/user',
  // USUARIOS
  USER_HELP: '/admin/users/user',
  USER_LIST: '/admin/user/list',
  USER_COPY: '/admin/user/copy',
  USER_DETAIL: '/admin/user/detail',
  USER_EDIT: '/admin/user/edit',
  USER_CREATE: '/admin/user/new',
  // PERFIL DE ACESSO
  DEVELOP_ACCESS_PROFILE_LIST: '/admin/user/accessProfile/list',
  DEVELOP_ACCESS_PROFILE_CREATE: '/admin/user/accessProfile/new',
  DEVELOP_ACCESS_PROFILE_EDIT: '/admin/user/accessProfile/edit',
  DEVELOP_ACCESS_PROFILE_MANAGER: '/admin/user/accessProfile/manager',

  // CADASTROS
  REGISTRATION: '/registration',
  // EMPRESAS
  OCCURRENCES: '/registration/company/occurrences',

  // SERVIDORES RPW
  SERVER_HELP: '/registration/servers',
  SERVER_GROUP_HELP: '/registration/servers/group',
  SERVER_CONFIGS: '/registration/serverconfigs',
  SERVER_EDIT: '/registration/serverconfigs/edit',
  SERVER_PROGRAM_EDIT: '/registration/serverconfigs/programEdit',

  // FILTROS DO MONITOR RPW
  MONITOR_FILTER_LIST: '/registration/monitorFilter',
  MONITOR_FILTER_CREATE: '/registration/monitorFilter/new',
  MONITOR_FILTER_EDIT: '/registration/monitorFilter/edit',

  // INTEGRAÇÃO
  INTEGRATION: '/integration',

  // MONITOR DE INTEGRAÇÃO
  INTEGRATION_MONITOR: '/integration/integrationMonitor',
};
