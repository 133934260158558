/* eslint-disable no-unused-expressions */
import React from 'react';

import { CriticalCarousel } from '../criticalCarousel';
import { Counter } from '../counter';

import styles from './styles.module.scss';

// eslint-disable-next-line import/prefer-default-export, no-unused-vars
export function FooterPanel({
  listServices,
  refreshTasks,
  filterStatusCards,
  getNumberStatus,
}) {
  function numberStatus(setStatus) {
    return (
      getNumberStatus &&
      getNumberStatus.filter(({ status }) => status === setStatus).length
    );
  }
  return (
    <section className={styles.footer}>
      <CriticalCarousel
        refreshTasks={refreshTasks}
        listServices={listServices}
      />
      <Counter
        critical={numberStatus('critical')}
        warning={numberStatus('warning')}
        success={numberStatus('success')}
        inactive={numberStatus('inactive')}
        getNumberStatus={getNumberStatus}
        listServices={listServices}
        filterStatusCards={filterStatusCards}
      />
    </section>
  );
}
