/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import moment from 'moment';
import React from 'react';
import { CSVLink } from 'react-csv';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import DateRangePicker from '../date/DateRangePicker';
import { Panel, PanelHeader } from '../panel/panel';
import WikiHelp from '../tooltip/wikiHelper';

export default class Table extends React.Component {
  constructor(props) {
    super(props);
    this.download = this.download.bind(this);

    this.state = {
      csvData: [],
      csvHeader: [],
    };
  }

  async download(event) {
    const { columns } = this.props;
    const currentRecords = this.reactTable.getResolvedState().sortedData;
    const csvData = [];
    const csvHeader = [];

    if (currentRecords.length !== 0) {
      for (let index = 0; index < columns.length; index += 1) {
        const cols = columns[index];

        if (typeof (cols.accessor) === 'undefined') {
          for (let index3 = 0; index3 < cols.columns.length; index3 += 1) {
            const elemento = cols.columns[index3];

            csvHeader.push({
              label: elemento.Header,
              key: elemento.accessor,
            });
          }
        }
        if (cols.accessor !== 'action' && typeof (cols.accessor) !== 'undefined') {
          csvHeader.push({
            label: cols.Header,
            key: cols.accessor,
          });
        }
      }
      for (let index = 0; index < currentRecords.length; index += 1) {
        const recordToDownload = {};
        let statusName;
        currentRecords[index]._original.statusName
          ? statusName = currentRecords[index]._original.statusName
          : '';
        let statusCountName;
        currentRecords[index]._original.statusCountName
          ? statusCountName = currentRecords[index]._original.statusCountName
          : '';

        for (let colIndex = 0; colIndex < columns.length; colIndex += 1) {
          if (columns[colIndex].accessor !== 'action') {
            const value = currentRecords[index][columns[colIndex].accessor];

            if (typeof value === 'object') {
              recordToDownload[columns[colIndex].accessor] = value ? value.length : '';
            } else if (typeof value === 'boolean') {
              recordToDownload[columns[colIndex].accessor] = value ? 'Sim' : 'Não';
            } else if (typeof value === 'number') {
              recordToDownload[columns[colIndex].accessor] = parseFloat(value);
            } else if (typeof value === 'undefined') {
              if (columns[colIndex].columns && columns[colIndex].columns.length > 0) {
                for (let index2 = 0; index2 < columns[colIndex].columns.length; index2 += 1) {
                  const element = columns[colIndex].columns[index2];

                  recordToDownload[element.accessor] = currentRecords[index][element.accessor];
                }
              }
            } else if (!!value && moment(value).isValid() && value.length === 24) {
              recordToDownload[columns[colIndex].accessor] = value.includes('T00:00:00.000Z')
                ? moment(value).format('L')
                : moment(value).format('L LTS');
            } else {
              recordToDownload[columns[colIndex].accessor] = value;
            }

            if (statusName) {
              recordToDownload.status = statusName;
            }
            if (statusCountName) {
              recordToDownload.statusCount = statusCountName;
            }
          }
        }

        csvData.push(recordToDownload);
      }

      await this.setState({
        csvData,
        csvHeader,
      });

      this.csvLink.link.click();
    }
  }

  render() {
    const {
      datePicker, headerTitle, downloadCSV = false, filename = false, downButtonPosition = false,
      buttonFilter = false, actionButtons = false, wikiHelp,
    } = this.props;

    return (
      <div>
        {!!this.props.breadcrumb && this.props.breadcrumb.length && (
          <div className="d-flex align-items-center">
            <ol className="breadcrumb float-xl-left">
              {this.props.breadcrumb.map((it, index) => (
                <li key={`${it.value}${index}`} className={`breadcrumb-item${it.active ? ' active' : ''}`}>
                  {it.value}
                </li>
              ))}
            </ol>
          </div>
        )}
        {headerTitle && (
          <div className="d-flex align-items-center mb-md-3 mb-2">
            <h1 className="page-header mb-0">
              {headerTitle}
            </h1>
            {wikiHelp && <WikiHelp wikiPath={wikiHelp} />}
            {!downButtonPosition && this.props.actionButtons}
          </div>
        )}
        {datePicker && (
          <div className="d-sm-flex align-items-center mb-3">
            <DateRangePicker
              startDate={datePicker.startDate}
              endDate={datePicker.endDate}
              onApply={datePicker.handleDateApplyEvent}
            >
              <button type="button" className="btn btn-inverse mr-2 text-truncate">
                <i className="fa fa-calendar fa-fw text-white-transparent-5 ml-n1" />
                <span>{datePicker.currentRange}</span>
                <b className="caret" />
              </button>
            </DateRangePicker>
            {(!headerTitle || downButtonPosition) && this.props.actionButtons}
          </div>
        )}
        {buttonFilter && (
          <div className="d-sm-flex align-items-center mb-3">
            <button
              type="button"
              className="btn btn-inverse mr-2 text-truncate"
              onClick={buttonFilter.actionButton}
            >
              <i className="fa fa-filter fa-fw text-white-transparent-5 ml-n1 mr-1" />
              {buttonFilter.nameButton || 'Filtros'}
            </button>
            {(!headerTitle || downButtonPosition) && this.props.actionButtons}
          </div>
        )}
        {!headerTitle && !datePicker && !buttonFilter && actionButtons && (
          <div className="d-flex align-items-center mb-md-0 mb-2">
            {this.props.actionButtons}
          </div>
        )}
        {downloadCSV
          && (
          <div>
            <CSVLink
              data={this.state.csvData}
              headers={this.state.csvHeader}
              filename={filename || 'data.csv'}//
              className="hidden"
              ref={(r) => this.csvLink = r}
              target="_blank"
              separator=";"
            />
          </div>
        )}
        <Panel {...(this.props.panelProps ? this.props.panelProps : {})}>
          <PanelHeader
            downloadCSV={downloadCSV}
            onClickDownload={this.download}
            {...(this.props.panelHeaderProps ? this.props.panelHeaderProps : {})}
          />
          <ReactTable
            ref={(r) => {
              this.reactTable = r;
            }}
            className="-highlight"
            pageText="Página"
            rowsText="Linhas"
            ofText="de"
            previousText="Anterior"
            nextText="Próximo"
            loadingText="Carregando..."
            noDataText="Nenhuma linha encontrada"
            {...this.props}
          />
        </Panel>
      </div>
    );
  }
}
