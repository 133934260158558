/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  getListIntegrationQueue,
  resyncQueue,
} from '../../../app/store/actions/integration';
import OneClickButton from '../../../components/form/button';
import addNotification from '../../../components/notification';
import Table from '../../../components/table/Table';
import ModalIntegrationInformation from './modalIntegrationInformation';

import IntegrationHelpers from '../../../helpers/integration';

class IntegrationMonitorList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      dataSend: '',
      responseData: '',
      openInformationModal: false,
      showModalInformationQuantity: false,
      selectedIntegrations: {},
      selectedIds: [],
      defaultPageSize: 10,
      listIntegrationQueue: [],
      startDate: moment().subtract(5, 'd'),
      endDate: moment().add(5, 'd'),
      currentRange: `${moment()
        .subtract(5, 'd')
        .format('DD MMM YYYY')} - ${moment()
        .add(5, 'd')
        .format('DD MMM YYYY')}`,
    };

    this.defaultSorted = [
      {
        id: 'creationDate',
        desc: true,
      },
    ];

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            Início
          </>
        ),
      },
      { value: 'Integrações' },
      { value: 'Monitor de Integração', active: true },
    ];

    this.tableColumns = [
      {
        Header: 'Selecionar',
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            {row.original.status !== 200 && (
              <input
                type="checkbox"
                checked={
                  this.state.selectedIntegrations[
                    row.original.idIntegrationQueue
                  ] || false
                }
                onChange={() => this.singleSelection(row.original)}
              />
            )}
          </div>
        ),
      },
      {
        Header: 'Detalhes',
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        minWidth: 90,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <button
              type="button"
              className="btn btn-primary btn-xs"
              onClick={() => this.selectRegistryInformation(row.original)}
            >
              <span className="d-flex align-items-center text-start">
                Detalhes
              </span>
            </button>
          </div>
        ),
      },
      {
        Header: 'Integração',
        accessor: 'integrationTable',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{IntegrationHelpers.integrationCode(row.value)}</span>
          </div>
        ),
        filterMethod: (filter, row) => {
          if (filter.value === row.integrationTable) {
            return true;
          }
        },
        Filter: ({ filter, onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => {
              onChange(event.target.value);
            }}
          >
            <option value="" key="all">
              Todos
            </option>
            {IntegrationHelpers.integrationCode().map((elem, index) => (
              <option value={elem.value} key={index}>
                {elem.label}
              </option>
            ))}
          </select>
        ),
      },
      {
        Header: 'Tipo',
        accessor: 'type',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{IntegrationHelpers.integrationType(row.value)}</span>
          </div>
        ),
        filterMethod: (filter, row) => {
          if (filter.value === row.type) {
            return true;
          }
        },
        Filter: ({ filter, onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => {
              onChange(event.target.value);
            }}
          >
            <option value="" key="all">
              Todos
            </option>
            {IntegrationHelpers.integrationType().map((elem, index) => (
              <option value={elem.value} key={index}>
                {elem.label}
              </option>
            ))}
          </select>
        ),
      },
      {
        Header: 'Filial',
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      },
      {
        Header: 'Chave',
        accessor: 'tableKey',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      },
      {
        Header: 'Data Criação',
        accessor: 'creationDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (
            _.includes(dateFirstOption, input)
            || _.includes(dateSecondOption, input)
          ) {
            return true;
          }
        },
        Cell: (row) => (
          <div>
            <span>
              {' '}
              {row.value ? moment(row.value).format('L LTS') : ''}
            </span>
          </div>
        ),
      },
      {
        Header: 'Data Integração',
        accessor: 'integrationDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (
            _.includes(dateFirstOption, input)
            || _.includes(dateSecondOption, input)
          ) {
            return true;
          }
        },
        Cell: (row) => (
          <div>
            <span>
              {' '}
              {row.value ? moment(row.value).format('L LTS') : ''}
            </span>
          </div>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      },
      {
        Header: 'Mensagem',
        accessor: 'message',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      },
    ];
  }

  componentDidMount() {
    this.getListIntegrationQueue();
  }

  singleSelection = (selection) => {
    const { selectedIntegrations } = this.state;
    selectedIntegrations[selection.idIntegrationQueue] = !selectedIntegrations[selection.idIntegrationQueue];
    this.setState({ selectedIntegrations: { ...selectedIntegrations } });
  };

  multipleSelection = (selectionOption) => {
    const { selectedIntegrations } = this.state;
    const data = this.reactTable.getResolvedState().sortedData;
    if (data && data.length > 0) {
      for (let index = 0; index < data.length; index += 1) {
        const element = data[index];
        if (
          element.status !== 200
          && selectedIntegrations[element._original.idIntegrationQueue]
            !== selectionOption
        ) {
          selectedIntegrations[element._original.idIntegrationQueue] = selectionOption;
        }
      }
      this.setState({ selectedIntegrations: { ...selectedIntegrations } });
    }
  };

  validChecked = () => {
    const { selectedIntegrations } = this.state;
    if (!Object.keys(selectedIntegrations).length) {
      addNotification(
        'danger',
        'Monitor de Integração',
        'Nenhuma integração selecionada',
        'top-right',
      );
    } else {
      const selectedIds = [];
      for (const index in selectedIntegrations) {
        if (selectedIntegrations[index]) selectedIds.push(index);
      }
      if (selectedIds.length) {
        this.setState({
          selectedIds: [...selectedIds],
          showModalInformationQuantity: true,
        });
      } else {
        addNotification(
          'danger',
          'Monitor de Integração',
          'Nenhuma integração selecionada',
          'top-right',
        );
      }
    }
  };

  getListIntegrationQueue = async () => {
    const { startDate, endDate } = this.state;

    this.setState({
      selectedIds: [],
      selectedIntegrations: {},
    });

    try {
      const listIntegrationQueue = await this.props.getListIntegrationQueue(
        moment(startDate).format('DD/MM/YYYY'),
        moment(endDate).format('DD/MM/YYYY'),
      );
      if (listIntegrationQueue.length) { this.setState({ listIntegrationQueue: [...listIntegrationQueue] }); } else this.setState({ listIntegrationQueue: [] });
    } catch (err) {
      this.setState({ listIntegrationQueue: [] });
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = error.details
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification(
          'danger',
          'Monitor de Integração',
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          'Monitor de Integração',
          'Erro ao buscar dados',
          'top-right',
        );
      }
    }
  };

  handleDateApplyEvent = async (event, picker) => {
    this.setState({
      startDate: picker.startDate,
      endDate: picker.endDate,
      currentRange: `${picker.startDate.format(
        'DD MMM YYYY',
      )} - ${picker.endDate.format('DD MMM YYYY')}`,
    });
    this.getListIntegrationQueue();
  };

  selectRegistryInformation = (selectedLine) => {
    this.setState({
      dataSend: selectedLine.body,
      responseData: selectedLine.response,
      openInformationModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      openInformationModal: false,
      responseData: '',
      dataSend: '',
    });
  };

  resyncSubmit = async () => {
    const { selectedIds } = this.state;

    try {
      this.setState({
        showModalInformationQuantity: false,
      });

      if (selectedIds.length > 0) {
        const result = await this.props.resyncQueue(selectedIds);

        if (result && result.success === false) {
          addNotification(
            'danger',
            'Monitor de Integração',
            'Erro ao reenviar fila de integração!',
            'top-right',
          );
        } else {
          addNotification(
            'success',
            'Monitor de Integração',
            'Integração reenviada com sucesso!',
            'top-right',
          );

          this.setState({
            selectedIds: [],
            selectedIntegrations: {},
          });

          this.getListIntegrationQueue();
        }
      } else {
        addNotification(
          'danger',
          'Monitor de Integração',
          'Nenhuma Integração selecionada!',
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            'Monitor de Integração',
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            'Monitor de Integração',
            'Erro ao reenviar fila de integração!',
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          'Monitor de Integração',
          'Erro ao reenviar fila de integração!',
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      selectedIds,
      defaultPageSize,
      startDate,
      endDate,
      currentRange,
      listIntegrationQueue,
      showModalInformationQuantity,
    } = this.state;

    return (
      <>
        <Table
          expander
          filterable
          downloadCSV
          downButtonPosition
          data={listIntegrationQueue}
          columns={this.tableColumns}
          defaultSorted={this.defaultSorted}
          defaultPageSize={defaultPageSize}
          breadcrumb={this.breadcrumb}
          headerTitle="Monitor de Integração"
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          ref={(r) => {
            this.reactTable = r && r.reactTable;
          }}
          datePicker={{
            startDate: moment(startDate).format('DD/MM/YYYY'),
            endDate: moment(endDate).format('DD/MM/YYYY'),
            currentRange,
            handleDateApplyEvent: this.handleDateApplyEvent,
          }}
          panelHeaderProps={{
            onClickReload: this.getListIntegrationQueue,
          }}
          actionButtons={(
            <div className="ml-auto">
              <button
                type="button"
                className="btn btn-120 btn-secondary p-5 m-5"
                onClick={() => this.multipleSelection(true)}
              >
                Marcar Todos
              </button>
              <button
                type="button"
                className="btn btn-120 btn-secondary p-5 m-5"
                onClick={() => this.multipleSelection(false)}
              >
                Desmarcar Todos
              </button>
              <OneClickButton
                type="button"
                className="btn btn-140 btn-primary p-5 m-5"
                onClick={() => this.validChecked()}
              >
                Reenviar Integração
              </OneClickButton>
            </div>
          )}
        />
        <ModalIntegrationInformation
          closeModal={this.closeModal}
          dataSend={this.state.dataSend}
          responseData={this.state.responseData}
          openInformationModal={this.state.openInformationModal}
        />
        {showModalInformationQuantity && (
          <SweetAlert
            info
            showCancel
            cancelBtnText="Cancelar"
            confirmBtnText="Continuar"
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            title="Você tem certeza?"
            onConfirm={() => this.resyncSubmit()}
            onCancel={() => this.setState({ showModalInformationQuantity: false })}
          >
            {`Foram selecionada ${selectedIds.length} integrações`}
          </SweetAlert>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getListIntegrationQueue: (startDate, endDate) => dispatch(getListIntegrationQueue(startDate, endDate)),
  resyncQueue: (queueIds) => dispatch(resyncQueue(queueIds)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(IntegrationMonitorList));
