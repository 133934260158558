/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as IconCritical } from '../../assets/images/icons/critical.svg';
import { ReactComponent as IconSuccess } from '../../assets/images/icons/success.svg';
import { ReactComponent as IconWarning } from '../../assets/images/icons/warning.svg';
import { ReactComponent as IconInactive } from '../../assets/images/icons/inactive.svg';

import { ReactComponent as IconLinux } from '../../assets/images/linux.svg';
import { ReactComponent as IconWindows } from '../../assets/images/windows.svg';

import styles from './styles.module.scss';

// eslint-disable-next-line object-curly-newline
export function CardCarousel({
  status,
  id,
  system,
  systemName,
  title,
  message,
  refreshTasks,
}) {
  const task = () => {
    if (refreshTasks) refreshTasks();
    // return `/monitor/robot/${id}`;
  };
  return (
    <Link to={`/monitor/robot/${id}`} onClick={task}>
      <div
        className={`${styles.card} 
          ${
            status === 'critical'
              ? styles.critical
              : status === 'success'
              ? styles.success
              : status === 'warning'
              ? styles.warning
              : status === 'inactive' && styles.inactive
          }`}
      >
        <h4>{title}</h4>
        <div className={styles.system}>
          <span>{system === 'UNIX' ? <IconLinux /> : <IconWindows />}</span>
          <h5>{systemName}</h5>
        </div>
        <footer>
          <p>{message}</p>
        </footer>
        {status === 'success' ? (
          <IconSuccess className={styles.icon} />
        ) : status === 'critical' ? (
          <IconCritical className={styles.icon} />
        ) : status === 'warning' ? (
          <IconWarning className={styles.icon} />
        ) : (
          status === 'inactive' && <IconInactive className={styles.icon} />
        )}
      </div>
    </Link>
  );
}
