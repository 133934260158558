import RestService from '../services/rest';

// GET
async function getListIntegrationQueue(fs, fe) {
  return RestService.getAuthenticated(`integration/integrationQueue/list?filterStartDate=${fs}&filterEndDate=${fe}`);
}

// PUT
async function resyncQueue(queueIds = []) {
  return RestService.putAuthenticated('integration/resync', queueIds);
}

export default {
  getListIntegrationQueue,
  resyncQueue,
};
