import PreferencesData from '../../../data/preferences';

const INITIAL_STATE = {
  auth: {},
  userLogged: null,
  menus: [],
  routes: [],
  permissions: {},
  permissionsCompany: {},
  cronLocation: {},
  programList: [],
  favoriteList: [],
  locale: PreferencesData.language,
  timezone: PreferencesData.timezone,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_APP_AUTH':
      return { ...state, auth: action.auth };
    case 'SET_USER_lOGGED':
      return { ...state, userLogged: action.user };
    case 'SET_USER_MENUS':
      return { ...state, menus: action.menus };
    case 'SET_USER_ROUTES':
      return { ...state, routes: action.routes };
    case 'SET_USER_PROGRAM_LIST':
      return { ...state, programList: action.programList };
    case 'SET_USER_FAVORITE_LIST':
      return { ...state, favoriteList: action.favoriteList };
    case 'SET_USER_PERMISSIONS':
      return { ...state, permissions: action.permissions };
    case 'SET_USER_PERMISSIONS_COMPANY':
      return { ...state, permissionsCompany: action.permissionsCompany };
    case 'SET_USER_CRON_LOCATION':
      return { ...state, cronLocation: action.cronLocation };
    case 'SET_USER_LOCALE':
      return { ...state, locale: action.locale };
    case 'SET_USER_TIMEZONE':
      return { ...state, timezone: action.timezone };
    default:
      return state;
  }
};
