/* eslint-disable class-methods-use-this */
import { saveAs } from 'file-saver';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Button, Card, CardBody, CardText, CardTitle,
} from 'reactstrap';

import { getDownloadsList } from '../../app/store/actions/downloads';
import addNotification from '../../components/notification';
import WikiHelp from '../../components/tooltip/wikiHelper';

import ROUTES from '../../config/routes';

class PageDownload extends React.PureComponent {
  componentDidMount() {
    this.props.getDownloadsList();
  }

  handleClick(dl) {
    const fileUrl = dl.link;
    const partes = fileUrl.split('/');
    const fileName = partes[partes.length - 1];
    saveAs(fileUrl, fileName);
  }

  render() {
    const { downloadsList = [] } = this.props;

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center m-b-10">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-download fa-fw m-t-10 m-r-5" />
              {' '}
              Início
            </li>
            <li className="breadcrumb-item active">Downloads</li>
          </ol>
        </div>
        {downloadsList.map((list) => (
          <div key={`tp_list${list.type}`}>
            <div className="d-flex align-items-center mb-md-3 mb-2">
              <h1 className="page-header mb-0">
                {list.type}
              </h1>
              {list.type === "Manuais" ? (<WikiHelp wikiPath={ROUTES.DOWNLOAD} />) : null}
            </div>
            <div className="row m-b-30">
              {list.downloads.map((dl) => (
                <div key={dl.code} className="col-xl-3 col-sm-6">
                  <Card inverse className="border-0 bg-dark card-inverse" style={{ height: '100%' }}>
                    <CardBody>
                      <CardTitle tag="h4" className="m-t-5 m-b-10" style={{ height: '44px' }}>
                        {dl.name}
                      </CardTitle>
                      <CardText className="m-b-15" style={{ height: '54px' }}>
                        {dl.description}
                      </CardText>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                          if (dl.link && dl.link !== '') {
                            this.handleClick(dl);
                          } else {
                            addNotification(
                              'danger',
                              'Downloads',
                              'Link inválido para download!',
                              'top-right',
                            );
                          }
                        }}
                      >
                        Download

                      </Button>
                    </CardBody>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  downloadsList: state.download && state.download.downloadsList,
});

const mapDispatchToProps = (dispatch) => ({
  getDownloadsList: () => dispatch(getDownloadsList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageDownload));
