/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { connect } from 'react-redux';
import { authenticate, getUserLogged } from '../../app/store/actions/app';
import { PageSettings } from '../../config/page-settings';
import ROUTES from '../../config/routes';
import AuthService from '../../app/services/auth';

import addNotification from '../../components/notification';

class Login extends React.PureComponent {
  static contextType = PageSettings;

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
    };
  }

  componentDidMount() {
    this.context.handleSetPageSidebar(false);
    this.context.handleSetPageHeader(false);
  }

  componentWillUnmount() {
    this.context.handleSetPageSidebar(true);
    this.context.handleSetPageHeader(true);
  }

  onChangeUsername = (e) => this.setState({ username: e.target.value });

  onChangePassword = (e) => this.setState({ password: e.target.value });

  redirectLogin = async () => {
    const isAuthenticated = await AuthService.isAuthenticated();

    if (isAuthenticated) await this.props.getUserLogged();

    const { userLogged } = this.props;

    if (userLogged && userLogged.type === 3) {
      this.props.history.push(ROUTES.MONITOR);
    } else {
      this.props.history.push(ROUTES.HOME);
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();

    if (this.state.username && this.state.password) {
      try {
        await this.props.authenticate(this.state.username, this.state.password);
        await this.redirectLogin();
      } catch (err) {
        const error = err.response && err.response.data && err.response.data.error;

        if (error && error.message) {
          const messageError = (error.details)
            ? `${error.code} - ${error.details} | ${error.message}`
            : error.message;
          addNotification(
            'danger',
            'Login',
            messageError,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            'Login',
            'Usuário ou senha incorretos !',
            'top-right',
          );
        }
      }
    }
  };

  render() {
    return (
      <div className="login-cover">
        <div className="login-cover-bg">
          <div className="header-login">
            <h1 className="brand">
              <b className="brand-font">Monitor RPW</b>
            </h1>
          </div>

          <div className="login login-v2">
            <div className="login-content">
              <form className="margin-bottom-0" onSubmit={this.onSubmit}>
                <div className="form-group m-b-20">
                  <label htmlFor="id-user-input" style={{ color: '#f1f1f1', fontWeight: 'bold' }}>Usuário</label>
                  <input
                    id="id-user-input"
                    type="text"
                    spellCheck="false" // remove a correção ortográfica
                    className="form-control form-control-lg"
                    // placeholder="Usuário"
                    required
                    value={this.state.username}
                    onChange={this.onChangeUsername}
                  />
                </div>
                <div className="form-group m-b-20">
                  <label htmlFor="id-password-input" style={{ color: '#f1f1f1', fontWeight: 'bold' }}>Senha</label>
                  <input
                    id="id-password-input"
                    type="password"
                    className="form-control form-control-lg"
                    // placeholder="Senha"
                    required
                    value={this.state.password}
                    onChange={this.onChangePassword}
                  />
                </div>
                <div className="login-buttons" style={{ marginTop: '2.5rem' }}>
                  <button
                    type="submit"
                    style={{
                      backgroundColor: '#ff5e00',
                      color: '#f1f1f1',
                    }}
                    className="btn btn-block btn-lg"
                  >
                    Entrar no Monitor RPW
                  </button>
                </div>
                <div className="m-t-20">
                  <p
                    className="text-center text-grey-darker"
                    style={{
                      marginTop: '2.5rem',
                    }}
                  >
                    &copy;
                    {' '}
                    SZ Soluções Todos os direitos reservados 2022
                  </p>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app.userLogged,
});

const mapActionsToProps = (dispatch) => ({
  authenticate: (username, password) => dispatch(authenticate(username, password)),
  getUserLogged: () => dispatch(getUserLogged()),
});

export default connect(mapStateToProps, mapActionsToProps)(Login);
