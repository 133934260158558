import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';
import { getMessagesList, setMessageType } from '../../../app/store/actions/companiesOccurences';
import addNotification from '../../../components/notification';
import Table from '../../../components/table/Table';
import messageType from '../../../helpers/messageType';

import WikiHelp from '../../../components/tooltip/wikiHelper';
import ROUTES from '../../../config/routes';

class CompanyMessages extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      messagesList: [],
      showModalEdit: false,
      editRowInfo: {},
      selectedMessageTypeCode: 0,
      selectedMessage: {},
    };

    this.defaultSorted = [
      {
        id: 'codigo',
        desc: false,
      },
    ];

    this.columns = [
      {
        Header: 'Ações',
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">Ações</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem
                  onClick={() => this.setState({
                    selectedMessageTypeCode: 0,
                    editRowInfo: rows.row,
                    showModalEdit: true,
                  })}
                >
                  Editar
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: 'Empresa',
        accessor: 'mensagensCompany.company',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        show: this.props.userLogged.type === 1,
        Cell: (row) => (
          <span>
            {row.value ? row.value.companyName : ''}
          </span>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const value = _.lowerCase(row._original.company
            ? row._original.company.companyName
            : '');
          if (_.includes(value, input)) {
            return true;
          }
        },
      }, {
        Header: 'Código',
        accessor: 'codigo',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: 'Código no Datasul',
        accessor: 'codigoDtsul',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: 'Descrição da Mensagem',
        accessor: 'descricaoMensagem',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Filter: this.filterColumn,
      }, {
        Header: 'Tipo da Mensagem',
        accessor: 'indTipMensagem',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      },
    ];
  }

  componentDidMount() {
    this.getMessagesInformation();
  }

  getMessagesInformation = async () => {
    const messagesList = await this.props.getMessagesList();

    await this.setState({
      messagesList,
    });
  };

  updateMessageType = async () => {
    const { editRowInfo, selectedMessageTypeCode } = this.state;
    if (selectedMessageTypeCode === 0) {
      addNotification(
        'danger',
        'Nenhuma classificação selecionada',
        'Selecione uma classificação',
        'top-right',
      );
      return;
    }

    await this.props.setMessageType(editRowInfo, selectedMessageTypeCode);

    addNotification(
      'success',
      'Classificação de ocorrência atualizada',
      `A ocorrência ${editRowInfo.codigo} teve sua classificação atualizada`,
      'top-right',
    );
    await this.setState({
      showModalEdit: false,
    });

    this.getMessagesInformation();
  };

  selectedMessage = async (selectedMessage) => {
    await this.setState({ selectedMessage: { ...selectedMessage } });
  };

  render() {
    const { messagesList, selectedMessage = {} } = this.state;
    return (
      <div>
        {(this.state.showModalEdit
          && (
          <SweetAlert
            showCancel
            confirmBtnText="Salvar"
            cancelBtnText="Cancelar"
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="danger"
            title="Editar tipo da mensagem"
            onConfirm={() => this.updateMessageType()}
            onCancel={() => this.setState({ showModalEdit: false })}
            btnSize="sm"
          >
            <div>
              <select
                className="text-align-center"
                style={{ width: '16rem', height: '2rem' }}
                onChange={(e) => { this.setState({ selectedMessageTypeCode: e.target.value }); }}
                defaultValue={0}
              >
                <option value={0} disabled>Defina um tipo de mensagem</option>
                {messageType().map((element) => {
                  if (element.value > 1) {
                    return (
                    // eslint-disable-next-line react/jsx-indent
                    <option key={element.value} value={element.value}>{`${element.value} - ${element.label}`}</option>);
                  }
                  return null;
                })}
              </select>
            </div>
          </SweetAlert>
          ))}
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              Início
            </li>
            <li className="breadcrumb-item">Cadastros</li>
            <li className="breadcrumb-item">Gerais</li>
            <li className="breadcrumb-item active">Classificação de Ocorrência</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            Classificação de Ocorrência
          </h1>
          <WikiHelp wikiPath={ROUTES.OCCURRENCES} />
        </div>
        <div>
          <Table
            downloadCSV
            filename={`Classificação de Ocorrência - ${moment(new Date()).format('DD_MM_YYYY, hh:mm:ss')}`}
            panelHeaderProps={{
              children: 'Servidores'.toUpperCase(),
              onClickReload: this.getMessagesInformation,
            }}
            filterable
            data={messagesList}
            columns={this.columns}
            expander
            defaultPageSize={5}
            defaultSorted={this.defaultSorted}
            defaultFilterMethod={(filter, row) => {
              const input = _.lowerCase(filter.value);
              const value = _.lowerCase(row[filter.id]);
              if (_.includes(value, input)) {
                return true;
              }
            }}
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  onClick: () => {
                    this.selectedMessage(rowInfo.original);
                  },
                  style: {
                    background: selectedMessage
                    && rowInfo.original.codigo === selectedMessage.codigo ? 'silver' : 'white',
                    cursor: 'pointer',
                  },
                };
              }
              return {};
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  serversInformation: state.servers.serversInformation,
  selectedServerPrograms: state.servers.selectedServerPrograms,
  userLogged: state.app && state.app.userLogged,
});

const mapDispatchToProps = (dispatch) => ({
  getMessagesList: () => dispatch(getMessagesList()),
  setMessageType: (
    editRowInfo,
    selectedMessageTypeCode,
  ) => dispatch(setMessageType(editRowInfo, selectedMessageTypeCode)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompanyMessages));
