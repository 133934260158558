/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable max-len */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-classes-per-file */
import React from 'react';
import { Link } from 'react-router-dom';
import { PopoverHeader, UncontrolledPopover } from 'reactstrap';

const PanelStat = React.createContext();

class Panel extends React.Component {
  constructor(props) {
    super(props);

    this.toggleExpand = () => {
      this.setState((state) => ({
        expand: !this.state.expand,
      }));

      return !this.state.expand;
    };

    this.toggleRemove = () => {
      this.setState((state) => ({
        remove: !this.state.remove,
      }));
    };

    this.toggleCollapse = () => {
      this.setState((state) => ({
        collapse: !this.state.collapse,
      }));
    };

    this.toggleReload = () => {
      if (this.state.reload !== true) {
        this.setState((state) => ({
          reload: true,
        }));

        setTimeout(() => {
          this.setState((state) => ({
            reload: false,
          }));
        }, 2000);
      }
    };

    this.state = {
      expand: false,
      collapse: false,
      reload: false,
      remove: false,
      toggleExpand: this.toggleExpand,
      toggleReload: this.toggleReload,
      toggleRemove: this.toggleRemove,
      toggleCollapse: this.toggleCollapse,
    };
  }

  render() {
    return (
      <PanelStat.Provider value={this.state}>
        {(!this.state.remove
          && (
          <div className={`panel panel-${this.props.theme ? this.props.theme : 'inverse'} ${this.state.expand ? 'panel-expand ' : ' '}${this.state.reload ? 'panel-loading ' : ' '}${this.props.className ? this.props.className : ''}`}>
            {this.props.children}
          </div>
          )
        )}
      </PanelStat.Provider>
    );
  }
}

class PanelHeader extends React.PureComponent {
  render() {
    return (
      <div className="panel-heading" style={{ backgroundColor: 'rgb(27, 27, 27)' }}>
        {this.props.downloadCSV
          && (
          <div className="panel-heading-btn">
            <div>
              <button
                type="button"
                id="popoverDownloadCSV"
                style={{ width: '20px', height: '20px', fontSize: '12px' }}
                className="btn btn-xs btn-icon btn-circle btn-green"
                onClick={this.props.onClickDownload}
              >
                <i className="fa fa-file-excel" />
                <UncontrolledPopover trigger="hover" placement="top" target="popoverDownloadCSV">
                  <PopoverHeader>
                    Exportar para Excel
                  </PopoverHeader>
                </UncontrolledPopover>
              </button>
              &nbsp;&nbsp;
            </div>
          </div>
          )}
        <h4 className="panel-title text-center f-s-12">{this.props.children || this.props.pageDescription}</h4>
        {this.props.noButton
          ? (
            <button
              type="button"
              style={{ visibility: this.props.noButton ? 'hidden' : 'visible' }}
              className="btn btn-xs btn-icon btn-circle btn-default"
            />
          )
          : (
            <PanelStat.Consumer>
              {({
                toggleExpand, toggleRemove, toggleCollapse, toggleReload,
              }) => (
                <div className="panel-heading-btn">
                  <div>
                    <button
                      type="button"
                      id="popoverClickExpand"
                      style={{ width: '20px', height: '20px', fontSize: '12px' }}
                      className="btn btn-xs btn-icon btn-circle btn-default"
                      onClick={this.props.onClickExpand ? () => this.props.onClickExpand(toggleExpand()) : toggleExpand}
                    >
                      <i className="fa fa-expand" />
                      <UncontrolledPopover trigger="hover" placement="top" target="popoverClickExpand">
                        <PopoverHeader>
                          Expandir Tabela
                        </PopoverHeader>
                      </UncontrolledPopover>
                    </button>
                    &nbsp;&nbsp;
                  </div>
                  {(this.props.onClickReload
                    && (
                    <div>
                      <button
                        type="button"
                        id="popoverClickReload"
                        className="btn btn-xs btn-icon btn-circle btn-success"
                        style={{ width: '20px', height: '20px', fontSize: '12px' }}
                        onClick={this.props.onClickReload}
                      >
                        <i className="fa fa-redo" />
                        <UncontrolledPopover trigger="hover" placement="top" target="popoverClickReload">
                          <PopoverHeader>
                            Atualizar Dados
                          </PopoverHeader>
                        </UncontrolledPopover>
                      </button>
                      &nbsp;&nbsp;
                    </div>
                    )
                  )}
                  {(this.props.onClickAdd
                    && (
                    <div>
                      <button
                        type="button"
                        className="btn btn-xs btn-icon btn-circle btn-success"
                        onClick={this.props.onClickAdd}
                      >
                        <i className="fa fa-plus" />
                      </button>
                      &nbsp;&nbsp;
                    </div>
                    )
                  )}
                </div>
              )}
            </PanelStat.Consumer>
          )}
      </div>
    );
  }
}

class PanelBody extends React.PureComponent {
  render() {
    return (
      <PanelStat.Consumer>
        {({ collapse, reload }) => (
          <div className={`panel-body ${collapse ? 'd-none ' : ' '}${this.props.className}`}>
            {this.props.children}

            {(reload
              && (
              <div className="panel-loader">
                <span className="spinner-small" />
              </div>
              )
            )}
          </div>
        )}
      </PanelStat.Consumer>
    );
  }
}

class PanelFooter extends React.PureComponent {
  render() {
    return (
      <div className={`panel-footer ${this.props.className}`}>
        {this.props.children}
        {this.props.footerContent}
        {this.props.linkBack && (
          <Link to={this.props.linkBack}>
            {(this.props.showCancel
              ? (
                <button
                  type="submit"
                  style={{ width: 'auto', padding: 10 }}
                  className="btn btn-white p-5 m-5"
                >
                  Cancelar
                </button>
              )
              : (
                <button
                  type="submit"
                  style={{ width: 'auto', padding: 10 }}
                  className="btn btn-white p-5 m-5"
                >
                  Voltar
                </button>
              ))}
          </Link>
        )}
        {(this.props.showCreate
          && (
          <button
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.props.onSubmitCreate}
          >
            Criar

          </button>
          )
        )}
        {(this.props.showUpdate
          && (
          <button
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.props.onSubmitUpdate}
          >
            Atualizar

          </button>
          )
        )}
      </div>
    );
  }
}

class SimpleForm extends React.PureComponent {
  render() {
    return (
      <div style={{ color: '#f1f1f1' }} className={this.props.animationType || 'slideUpTransition'}>
        {!!this.props.breadcrumb && this.props.breadcrumb.length && (
          <div className="d-flex align-items-center">
            <ol className="breadcrumb float-xl-left">
              {this.props.breadcrumb.map((it) => (
                <li className={`breadcrumb-item${it.active ? ' active' : ''}`}>
                  {it.value}
                </li>
              ))}
            </ol>
          </div>
        )}
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {this.props.title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader {...this.props} />
                <PanelBody>
                  {this.props.inputs}
                </PanelBody>
                <PanelFooter {...this.props} />
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
  SimpleForm,
};
