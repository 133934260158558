import React from 'react';
import { Link } from 'react-router-dom';
import DropdownLanguage from './dropdown/language';
import DropdownProfile from './dropdown/profile';

import { PageSettings } from '../../config/page-settings';
import SearchForm from './search/form';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.toggleMegaMenu = this.toggleMegaMenu.bind(this);
    this.state = { collapseMegaMenu: false };
  }

  toggleMegaMenu() {
    const { collapseMegaMenu } = this.state;
    this.setState({ collapseMegaMenu: !collapseMegaMenu });
  }

  render() {
    return (
      <PageSettings.Consumer>
        {({
          toggleRightSidebar,
          pageHeaderLanguageBar,
          pageTwoSidebar,
        }) => (
          <>
            <div
              id="header"
              className="header navbar-default"
              style={{
                backgroundColor: '#1B1B1B',
                height: '6rem',
                alignItems: 'center',
                justifyContent: 'center',
                borderBottom: '2px solid #2B2C30',
              }}
            >
              <div
                className="navbar-brand"
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                }}
              >
                <Link to="/" className="brand-font">
                  <h1 className="brand">
                    <b className="brand-font">Monitor RPW</b>
                  </h1>
                </Link>
              </div>
              <ul className="navbar-nav navbar-right">

                {pageHeaderLanguageBar && (
                  <DropdownLanguage />
                )}
                <SearchForm />
                <DropdownProfile />

                {pageTwoSidebar && (
                  <li className="divider d-none d-md-block" />
                )}

                {pageTwoSidebar && (
                  <li className="d-none d-md-block">
                    <Link to="/" onClick={toggleRightSidebar} className="f-s-14">
                      <i className="fa fa-th" />
                    </Link>
                  </li>
                )}
              </ul>
            </div>

            {/* <div id="header" className="header navbar-default" style={{ backgroundColor: '#1B1B1B' }}>
              <div className="navbar-brand">
                <Link to="/" className="brand-font">
                  <h1 className="brand">
                    <b className="brand-font">Monitor RPW</b>
                  </h1>
                </Link>
              </div>
              <div className="navbar-header">
                {pageTwoSidebar && (
                  <button type="button" className="navbar-toggle pull-left" onClick={toggleMobileRightSidebar}>
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                  </button>
                )}
                {pageHeaderMegaMenu && (
                  <button type="button" className="navbar-toggle pt-0 pb-0 mr-0" onClick={this.toggleMegaMenu}>
                    <span className="fa-stack fa-lg text-inverse">
                      <i className="far fa-square fa-stack-2x" />
                      <i className="fa fa-cog fa-stack-1x" />
                    </span>
                  </button>
                )}
                {pageTopMenu && pageSidebar && (
                  <button type="button" className="navbar-toggle pt-0 pb-0 mr-0 collapsed" onClick={toggleMobileTopMenu}>
                    <span className="fa-stack fa-lg text-inverse">
                      <i className="far fa-square fa-stack-2x" />
                      <i className="fa fa-cog fa-stack-1x" />
                    </span>
                  </button>
                )}
                {!pageSidebar && pageTopMenu && (
                  <button type="button" className="navbar-toggle" onClick={toggleMobileTopMenu}>
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                  </button>
                )}
                {pageSidebar && (
                  <button type="button" className="navbar-toggle" onClick={toggleMobileSidebar}>
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                  </button>
                )}
              </div>

              {pageHeaderMegaMenu && (
                <DropdownMegaMenu collapse={this.state.collapseMegaMenu} />
              )}

              <ul className="navbar-nav navbar-right">

                {pageHeaderLanguageBar && (
                  <DropdownLanguage />
                )}

                <DropdownProfile />

                {pageTwoSidebar && (
                  <li className="divider d-none d-md-block" />
                )}

                {pageTwoSidebar && (
                  <li className="d-none d-md-block">
                    <Link to="/" onClick={toggleRightSidebar} className="f-s-14">
                      <i className="fa fa-th" />
                    </Link>
                  </li>
                )}
              </ul>
            </div> */}
          </>
        )}
      </PageSettings.Consumer>
    );
  }
}

export default Header;
