const serverUrls = {
  localhost: 'http://localhost:3333/',
  'localhost:3000': 'http://localhost:3333/',
  '192.168.50.127:8580': 'http://192.168.50.126:8580/', // NORTEL PRODUÇÃO
  '52.73.157.205:8180': 'http://52.73.157.205:8280/', // AWS APP-1
  '3.218.204.22:8180': 'http://3.218.204.22:8280/', // AWS APP-2
  '52.73.157.205': 'http://52.73.157.205:8280/', // AWS APP-1
  '3.218.204.22': 'http://3.218.204.22:8280/', // AWS APP-2
  'monitor.szsolucoes.com.br': 'https://api-monitor.szsolucoes.com.br/',
};

const serverNames = {
  'localhost:3000': 'Localhost',
};

const getBaseUrl = () => {
  const { host } = window.location;

  const url = serverUrls[host];
  if (url) {
    return url;
  }
  return serverUrls.localhost;
};

const getDisplayName = () => {
  const { host } = window.location;

  const displayName = serverNames[host];
  if (displayName) return displayName;
};

export default {
  getBaseUrl,
  getDisplayName,
};
