/* eslint-disable func-names */
import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';

export default function (props) {
  return (
    <DateRangePicker
      locale={{
        applyLabel: 'Confirmar',
        cancelLabel: 'Cancelar',
      }}
      {...props}
    />
  );
}
