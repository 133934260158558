import IntegrationRequests from '../../server/integration';
import { addLoading, removeLoading } from './loading';

export const getListIntegrationQueue = (filterStartDate, filterEndDate) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await IntegrationRequests.getListIntegrationQueue(filterStartDate, filterEndDate);
  } finally {
    dispatch(removeLoading());
  }
};

export const resyncQueue = (queueIds) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await IntegrationRequests.resyncQueue(queueIds);
  } finally {
    dispatch(removeLoading());
  }
};
