import { store } from 'react-notifications-component';

const addNotification = (
  notificationType,
  notificationTitle,
  notificationMessage = '',
  notificationPosition,
  notificationContent = undefined,
) => (
  store.addNotification({
    title: notificationTitle,
    message: notificationMessage,
    content: notificationContent,
    type: notificationType,
    insert: 'top',
    container: notificationPosition,
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: { duration: 5000 },
    dismissable: { click: true },
  })
);

export default addNotification;
