/* eslint-disable class-methods-use-this */
import _ from 'lodash';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';

import moment from 'moment';
import addNotification from '../../../components/notification';
import Table from '../../../components/table/Table';

import ROUTES from '../../../config/routes';

import { deleteMonitorFilter, getMonitorFilterList } from '../../../app/store/actions/monitorFilters';

class MonitorFilterList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      filterUpdate: null,
    };

    this.tableColumns = [
      {
        Header: 'Ações',
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        minWidth: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button
              type="button"
              className="btn btn-default btn-xs"
              style={{
                marginRight: '0.5rem',
                fontSize: '0.9rem',
                fontWeight: '600',
              }}
            >
              Ações
            </button>
            <UncontrolledButtonDropdown>
              <DropdownToggle
                color="default"
                caret
                className="btn-xs"
                style={{ fontSize: '0.9rem' }}
              />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showEditFilter(rows.row)}>Editar</DropdownItem>
                <DropdownItem onClick={() => this.showDeleteFilter(rows.row)}>Eliminar</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: 'Empresa',
        accessor: 'company',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        show: this.props.userLogged.type === 1,
        Cell: (row) => (
          <span>
            {row.value ? row.value.companyName : ''}
          </span>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const value = _.lowerCase(row._original.company
            ? row._original.company.companyName
            : '');
          if (_.includes(value, input)) {
            return true;
          }
        },
      }, {
        Header: 'Descrição',
        accessor: 'filterName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
      await this.getMonitorFilters();
    } else {
      await this.getMonitorFilters();
    }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      filterUpdate: null,
    });
  };

  filterColumn = ({ filter, onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      value={(filter && filter.value) || ''}
      onChange={(e) => onChange(e.target.value)}
      autoComplete="nope"
    />
  );

  getMonitorFilters = async () => {
    await this.props.getMonitorFilterList();
  };

  deleteMonitorFilter = async (filter) => {
    const result = await this.props.deleteMonitorFilter(filter._original.id);

    if (result && result.success === false) {
      addNotification(
        'danger',
        'Erro ao eliminar Grupo RPW',
        `Grupo RPW ${filter.filterName} não foi eliminado!`,
        'top-right',
      );
      return;
    }

    this.setInitState();

    addNotification(
      'success',
      'Eliminar Grupo RPW',
      `Grupo RPW ${filter.filterName} eliminado com sucesso!`,
      'top-right',
    );
    await this.getMonitorFilters();
  };

  showEditFilter = (filter) => {
    this.props.history.push({
      pathname: ROUTES.MONITOR_FILTER_EDIT,
      state: {
        monitorFilter: filter._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showDeleteFilter = (filter) => {
    this.setState({ showModalDelete: true, filterUpdate: filter });
  };

  render() {
    const { monitorFilterList = [] } = this.props;

    const {
      filterUpdate, showModalDelete, page,
    } = this.state;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              Início
            </li>
            <li className="breadcrumb-item">Cadastro</li>
            <li className="breadcrumb-item active">Grupos de RPW</li>
          </ol>
        </div>
        <Table
          downloadCSV
          wikiHelp={ROUTES.SERVER_GROUP_HELP}
          filename={`Grupos RPW - ${moment(new Date()).format('DD_MM_YYYY, hh:mm:ss')}`}
          headerTitle="Grupos RPW"
          actionButtons={(
            (this.props.userLogged && this.props.userLogged.type <= 2) && (
              <div className="ml-auto">
                <Link
                  to={ROUTES.MONITOR_FILTER_CREATE}
                  className="btn btn-success btn-sm btn-rounded pl-2 pr-3 text-white"
                >
                  <i className="fa fa-plus mr-1" />
                  {' '}
                  Incluir Grupo RPW
                </Link>
              </div>
            )
          )}
          panelHeaderProps={{
            onClickReload: this.getMonitorFilters,
          }}
          filterable
          data={monitorFilterList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          page={(page) || ((!this.state.firstRun
            && this.props.location.state && this.props.location.state.dice
            && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(pg) => ((pg >= 0) ? this.setState({ page: pg }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText="Cancelar"
            confirmBtnText="Confirmar"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title="Você tem certeza?"
            onConfirm={() => this.deleteMonitorFilter(filterUpdate)}
            onCancel={() => this.setInitState()}
          >
            {`O Grupo RPW ${filterUpdate.filterName} será eliminado !`}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getMonitorFilterList: () => dispatch(getMonitorFilterList()),
  deleteMonitorFilter: (monitorFilterId) => dispatch(deleteMonitorFilter(monitorFilterId)),
});

const mapStateToProps = (state) => ({
  monitorFilterList: state.monitorFilter && state.monitorFilter.monitorFilterList,
  userLogged: state.app && state.app.userLogged,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MonitorFilterList));
