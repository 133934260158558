/* eslint-disable no-restricted-syntax */
/* eslint-disable no-use-before-define */
/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/button-has-type */
/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment/moment';
import Select, { components } from 'react-select';
import { connect } from 'react-redux';

import { ReactComponent as IconRefresh } from '../../assets/images/icons/refresh.svg';
import { ReactComponent as IconBack } from '../../assets/images/icons/back.svg';
import WhiteLogo from '../../assets/images/white-logo.svg';

import { getFilterServersExecQueue } from '../../app/store/actions/filterServersExec';
import { getAllCompanies } from '../../app/store/actions/companies';
import StorageService from '../../app/services/storage';

import ROUTES from '../../config/routes';

import WikiHelp from '../tooltip/wikiHelper';

function Header({
  title,
  systemName,
  getListServersExecQueue,
  handleChange,
  handleCompanySelect,
  userLogged,
}) {
  const [data, setData] = useState([]);
  const [dataCompany, setDataCompany] = useState([]);
  const userMaster = StorageService.get('CompanySelect');
  useEffect(() => {
    getFilter();
    getFilterCompany();
  }, []);
  const getFilter = async () => {
    const dadosFilter = await getFilterServersExecQueue()();
    const dados = [];
    for (const iterator of dadosFilter) {
      dados.push({
        value: iterator.servers,
        label: iterator.filterName,
      });
    }
    const todos = [{ label: 'Todos', value: [] }];
    setData([...todos, ...dados]);
  };
  const getFilterCompany = async () => {
    const dataFilter = await getAllCompanies()();
    const dataCompanies = [];
    for (const iterator of dataFilter) {
      dataCompanies.push({
        value: iterator.code,
        label: iterator.companyName,
      });
    }
    setDataCompany([...dataCompanies]);
  };
  function Input(props) {
    return <components.Input {...props} maxLength={10} />;
  }
  return (

    <header className="text-light" style={{ fontFamily: 'Inter, sans-serif' }}>
      <nav className="row w-100 align-items-center" style={{ marginTop: '-5em' }}>
        {title ? (
          <>
            <Link to="/monitor" title="Voltar ao monitor RPW">
              <IconBack />
            </Link>
            <h1>
              {title} | {systemName}
            </h1>
          </>
        ) : (
          <>
            <div className="col-3">
              <button
                className="ml-4"
                onClick={getListServersExecQueue}
                title="Recarregar"
              >
                <IconRefresh />
                <time
                  className="text-center align-items-center"
                  style={{ fontSize: '1rem', fontWeight: 'bold', marginLeft: '1rem' }}
                >
                  {moment(new Date()).format(
                    'DD[/]MM[/]YYYY | HH[h] mm[m] ss[s]',
                  )}
                </time>
              </button>
            </div>
            <div className="col-2" style={{ fontWeight: 'bold', marginLeft: '-1rem' }}>
              <Select
                className="text-dark w-50"
                style={{ fontWeight: 'bold' }}
                defaultValue={{ label: 'Todos', value: [] }}
                options={data}
                components={{ Input }}
                onChange={handleChange}
              />
            </div>
            <div className="col-3" style={{ marginLeft: '-3rem', display: '-webkit-inline-box' }}>
              <h1
                className="fs-1 text-center"
                style={{ fontSize: '3rem' }}
              >
                Monitor RPW
              </h1>
              <WikiHelp wikiPath={ROUTES.MONITOR} className="mb-5 ml-5" />
            </div>
            <div
              className="col-2 ms-5 d-flex align-items-center justify-content-center"
              style={{ fontWeight: 'bold', marginLeft: '1rem' }}
            >
              {userLogged.type === 1 ? (
              <Select
                className="text-dark w-75 p-3"
                style={{ fontWeight: 'bold' }}
                options={dataCompany}
                components={{ Input }}
                onChange={handleCompanySelect}
                value={userMaster}
                placeholder="Selecione uma empresa"
              />
              ) : null}
            </div>
            <div className="col-2">
              <div className="d-flex align-items-center justify-content-end">
                <Link to="/" title="Voltar ao admin">
                  <img src={WhiteLogo} alt="Logo branca SZ Soluções" />
                </Link>
              </div>
            </div>
          </>
        )}
      </nav>
    </header>
  );
}

const mapStateToProps = (state) => ({
  userLogged: state.app && state.app.userLogged,
});

export default connect(mapStateToProps, undefined)(withRouter(Header));
