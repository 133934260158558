/* eslint-disable import/prefer-default-export */
import React from 'react';
import Carousel from 'better-react-carousel';

import { CardCarousel } from '../cardCarousel';
import { ReactComponent as IconCritical } from '../../assets/images/icons/critical.svg';

import styles from './styles.module.scss';

export function CriticalCarousel({ listServices, refreshTasks }) {
  const dot = ({ isActive }) => (
    <span
      style={{
        height: '16px',
        width: '16px',
        borderRadius: '50%',
        background: isActive && '#eaeaea',
        border: '2px solid #eaeaea',
      }}
    />
  );
  return (
    <div className={styles.critical}>
      <div className={styles.subtitle}>
        <IconCritical />
        <h2>Críticos</h2>
      </div>
      <Carousel
        loop
        showDots
        cols={8}
        gap={24}
        dot={dot}
        hideArrow
        autoplay={5000}
        responsiveLayout={[
          {
            breakpoint: 1900,
            cols: 8,
            gap: 24,
          },
          {
            breakpoint: 1570,
            cols: 6,
          },
          {
            breakpoint: 1270,
            cols: 4,
          },
          {
            breakpoint: 1070,
            cols: 3,
          },
          {
            breakpoint: 970,
            cols: 2,
          },
          {
            breakpoint: 820,
            cols: 1,
            rows: 1,
          },
        ]}
      >
        {listServices
          .filter(({ status }) => status === 'critical' || status === 'warning')
          .map((card) => (
            <Carousel.Item key={card.id}>
              <CardCarousel
                id={card.id}
                title={card.title}
                system={card.system}
                systemName={card.systemName}
                status={card.status}
                message={card.message}
                refreshTasks={refreshTasks}
              />
            </Carousel.Item>
          ))}
      </Carousel>
    </div>
  );
}
