/* eslint-disable no-empty */
import filterServersExecRequests from '../../server/filterServersExec';

export const setFilterServersExec = (filterServersExec) => ({
  type: 'SET_FILTERSERVERSEXEC',
  filterServersExec,
});

export const getFilterServersExecQueue = () => async () => {
  const filterServersExec = await filterServersExecRequests.getFilterServersExecQueue();
  return filterServersExec;
};
