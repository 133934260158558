/* eslint-disable max-len */
export default {
  header: {
    basic: 'NGE0ODkwOTRjMTEzOGE3MjU5M2ZiMGNjMGQwYzNmNmI6MTFiNzUyYTMzZTM0NWExZmE5ODc4NzZkYTYzZTkwMmIxYmY3ZjI2NTdkM2ZhMGQyOTc0NjI2ZTZhMTVmNTExMA==',
    platform: 'web',
    version: '1.0.0',
  },
  timezone: 'America/Sao_Paulo',
  language: 'pt-BR',
};
